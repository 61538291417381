import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, LabelButton } from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { getBlockExplorerNameByChainId, TxStatus } from 'utils';

import './NftClaimCardButton.scss';

function NftClaimCardButton({
  disabled,
  status,
  entry,
  updateClaimSuccess,
}: {
  disabled: boolean;
  status: string;
  entry: FormattedNftClaims;
  updateClaimSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { sdk, currentNetwork } = useGlobalContext();

  const { t } = useTranslation();

  const [buttonStatus, setButtonStatus] = useState<string>(status);
  const [transactionHash, setTransactionHash] = useState<string>('');

  const badgeLabelName: string = getBlockExplorerNameByChainId(currentNetwork.chainId);

  useEffect(() => {
    const parseNFTsAndCheckStatus = async () => {
      let nftClaimsInProcess = sessionStorage.getItem('nft-claims-in-process');
      if (typeof nftClaimsInProcess === 'string') {
        nftClaimsInProcess = await JSON.parse(nftClaimsInProcess);
      }
      if (nftClaimsInProcess?.includes(entry.items[0].transmissionId)) {
        setButtonStatus(t('nftClaimPage:buttonStatus.claiming'));
      } else {
        setButtonStatus(t('nftClaimPage:buttonStatus.notClaimed'));
      }
    };
    parseNFTsAndCheckStatus();
  }, [entry.items, t]);

  const handleCollapse = async () => {
    setButtonStatus(t('nftClaimPage:buttonStatus.claiming'));

    let nftClaimsInProcess: string[] = [];
    const nftClaimsInProcessData = sessionStorage.getItem('nft-claims-in-process');

    if (nftClaimsInProcessData) {
      const parsedClaims = JSON.parse(nftClaimsInProcessData);
      nftClaimsInProcess = parsedClaims?.filter(
        (transmissionId: string) => transmissionId !== entry.items[0].transmissionId,
      );
    }
    sessionStorage.setItem('nft-claims-in-process', JSON.stringify(nftClaimsInProcess));
  };

  function clearNftClaimsFromSession(nftClaimsInProcess: string[]) {
    const index = nftClaimsInProcess.indexOf(entry.items[0].transmissionId, 0);
    if (index > -1) {
      nftClaimsInProcess.splice(index, 1);
      sessionStorage.setItem('nft-claims-in-process', JSON.stringify(nftClaimsInProcess));
    }
  }

  const onClaimClick = async () => {
    await handleCollapse();

    if (entry.items[0].transmissionType === 'ccip') {
      window.open(`https://ccip.chain.link/msg/${entry.items[0]}`, '_blank');
      // TODO: add possibility to claim ccip with our sdk (not supported yet)
      return;
    }

    const nftsFromStorage = sessionStorage.getItem('nft-claims-in-process');
    if (sdk && typeof nftsFromStorage === 'string') {
      const nftsInProcess = JSON.parse(nftsFromStorage) || [];
      nftsInProcess.push(entry.items[0].transmissionId);

      sessionStorage.setItem('nft-claims-in-process', JSON.stringify(nftsInProcess));
      try {
        const payableCall = await sdk.deliver(entry.items[0]);

        const contractTransactionResult = await payableCall.sendTransaction();
        setTransactionHash(contractTransactionResult.hash);

        const sendTxReceipt = await contractTransactionResult.wait();

        if (sendTxReceipt.status === TxStatus.READY) {
          setButtonStatus(t('nftClaimPage:buttonStatus.claimed'));
          clearNftClaimsFromSession(nftsInProcess);
          updateClaimSuccess(true);
          sessionStorage.setItem(
            'nft-claimed-claims',
            JSON.stringify(entry.items[0].transmissionId),
          );
          return;
        }
        clearNftClaimsFromSession(nftsInProcess);
        setButtonStatus(t('nftClaimPage:buttonStatus.notClaimed'));
      } catch (err) {
        console.error('onClaimClick Error', err);
        clearNftClaimsFromSession(nftsInProcess);
        setButtonStatus(t('nftClaimPage:buttonStatus.notClaimed'));
      }
    }
  };

  const claimButton = (
    <Button
      disabled={disabled || buttonStatus === t('nftClaimPage:buttonStatus.claimed')}
      type="primary"
      size="md"
      onClick={() => onClaimClick()}
      className="edit-button"
    >
      <div className="d-flex mr-2">
        <Icon
          name={
            buttonStatus === t('nftClaimPage:buttonStatus.claimed') ? 'edit-finalize' : 'edit-claim'
          }
          color={'brand-primary'}
          size={20}
        />
      </div>

      <div className="claim-text">
        {buttonStatus === t('nftClaimPage:buttonStatus.claimed')
          ? buttonStatus
          : t('nftClaimPage:buttonStatus.claim')}
      </div>
    </Button>
  );

  const labelClaimingButton = (
    <LabelButton
      type="primary"
      size="md"
      className="label-button"
      label={
        transactionHash ? (
          <BlockExplorerBadge
            title={getBlockExplorerNameByChainId(currentNetwork.chainId)}
            hash={transactionHash}
            blockExplorer={currentNetwork.blockExplorerUrl}
            queryParams={currentNetwork.blockExplorerQueryParams}
            type="tx"
          />
        ) : (
          <p className="text-secondary">{badgeLabelName}</p>
        )
      }
      loading={true}
      loadingText={<span className="processing-span">{t('labelButton:processing')}</span>}
    >
      {t('nftClaimPage:buttonStatus.claim')}
    </LabelButton>
  );

  return buttonStatus === t('nftClaimPage:buttonStatus.claiming')
    ? labelClaimingButton
    : buttonStatus === t('nftClaimPage:buttonStatus.notClaimed')
    ? claimButton
    : buttonStatus === t('nftClaimPage:buttonStatus.claimed')
    ? claimButton
    : null;
}

export default NftClaimCardButton;
