import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as NftClaimSuccessDesktop } from 'assets/nft-claim-success-desktop.svg';
import { ReactComponent as NftClaimSuccessMobile } from 'assets/nft-claim-success-mobile.svg';
import { useBreakpoint } from 'hooks/useBreakpoint';

import './NftClaimSucess.scss';

// This modal is used to claim ERC721
// TODO unify it with ModalClaimSuccess.tsx
function NftClaimSuccess() {
  const history = useHistory();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  return (
    <div className="my-7 mt-md-0 mb-md-7">
      <div className="d-flex justify-content-center mt-8 mt-md-7 mb-5">
        {/* TODO Image is too large claim get's lost at the end */}
        {greaterThan('md') ? (
          <NftClaimSuccessDesktop className="max-width-100 height-100" />
        ) : (
          <NftClaimSuccessMobile className="max-width-100 height-100" />
        )}
      </div>

      <div className="mt-4 mb-5">
        <div className="text-medium text-bold text-main text-center mb-3">
          {t('nftClaimSuccess:title')}
        </div>
        <div className="text-main text-center">
          <Trans i18nKey="nftClaimSuccess:description" components={{ span: <span /> }} />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-6">
        <Button
          type="primary"
          size="md"
          className="edit-button"
          onClick={() => history.push('/bridge-nft/claim')}
        >
          <div className="d-flex justify-content-center align-items-center">
            <Icon name="cross" color="white" size={18} />
          </div>
          <div className="history-text">{t('transferPage:transferSuccess.closeButton')}</div>
        </Button>
      </div>
      {/* <div className="d-flex justify-content-center mt-6">
        <Button
          type="primary"
          size="md"
          className="edit-button"
          onClick={() => {
            // history.push("/bridge-nft/history");
          }}
        >
          <div className="history-text">{t("nftClaimSuccess:goToHistoryBtn")}</div>
          <div className="d-flex justify-content-center align-items-center ml-2">
            <Icon name={"arrow-right-long"} color={"white"} size={18} />
          </div>
        </Button>
      </div> */}
    </div>
  );
}

export default NftClaimSuccess;
