import React, { useMemo } from 'react';

import { WALLET_TYPE } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { toSolanaAddress } from 'utils/solana';
import { shortenHash } from 'utils/utils';

interface ResponsiveAddressProps {
  address: string;
  walletType: string;
}

const ResponsiveAddress = ({ address, walletType }: ResponsiveAddressProps) => {
  const { lessThan } = useBreakpoint();

  const formattedAddress = useMemo(() => {
    const correctAddress = walletType === WALLET_TYPE.PHANTOM ? toSolanaAddress(address) : address;
    return lessThan('sm') ? shortenHash(correctAddress, 10) : correctAddress;
  }, [address, lessThan, walletType]);

  return <span className="text-main">{formattedAddress}</span>;
};

export default ResponsiveAddress;
