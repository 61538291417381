// 'data' is 'unknown' to handle varied types, but must be JSON-serializable.
export const setLocalStorage = (key: string, data: unknown) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  const storageData = data !== null ? JSON.parse(data) : [];
  return storageData;
};

export const cleanLocalStorage = (key: string) => {
  localStorage.setItem(key, '[]');
};
