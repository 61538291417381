import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { addAsset, getProvider } from 'utils/metamask';

import ClaimButton from '../../ClaimButton/ClaimButton';

import './ClaimingListingMobile.scss';

const ClaimingListingMobile = ({ entry }: { entry: FormattedTransfer }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editButtonStatus, setEditButtonStatus] = useState<string | undefined>(entry.status);
  const { t } = useTranslation();
  const { sdk, currentNetwork } = useGlobalContext();

  const claimContainerRowNode = useRef<HTMLHeadingElement>(null);
  const claimContainerButtonNode = useRef<HTMLHeadingElement>(null);

  const collapseIfAlreadyExpanded = (e: MouseEvent) => {
    // handle click inside mobile claim container
    const isInMobileRowContainer =
      claimContainerRowNode.current && claimContainerRowNode.current.contains(e.target as Node);
    // container for the claim button will not react with mousedown event (stopPropagation may break durring rerenders)
    const isInMobileButtonContainer =
      claimContainerButtonNode.current &&
      claimContainerButtonNode.current.contains(e.target as Node);

    if (isInMobileRowContainer) {
      if (!isInMobileButtonContainer) {
        setOpen(prevState => !prevState);
      }
      return;
    }
    // click outside mobile claim
    if (!isInMobileRowContainer) {
      setOpen(false);
    }
  };

  // add and remove the mousedown listener
  useEffect(() => {
    document.addEventListener('mousedown', collapseIfAlreadyExpanded);

    return () => {
      document.removeEventListener('mousedown', collapseIfAlreadyExpanded);
    };
  }, []);

  async function addBridgedTokenToWallet(
    tokenType: MetamaskAssetParamsType,
    tokenOptions: MetamaskAssetParamsOptions,
  ) {
    try {
      if (sdk) {
        let options = tokenOptions;
        if (entry.nativeChainId !== currentNetwork.chainTargetId && entry.nativeChainId !== 0) {
          const tokenAddress: string = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getWrappedForNativeToken(entry.nativeChainId, entry.nativeToken);
          options = { ...tokenOptions, address: tokenAddress };
        } else {
          options = { ...tokenOptions, address: entry.nativeToken };
        }
        const provider = getProvider();
        await addAsset(tokenType, options, provider);
      }
    } catch (err) {
      console.error('An error occurred while trying to add the token.');
    }
  }

  // dynamically create the editIcon
  const editIcon = (status: string | boolean) => {
    return status === t('claimPage:buttonStatus.notClaimed') || !status ? (
      <Icon
        name="edit-claim"
        size={20}
        color={entry.majorityReached ? 'brand-primary' : 'ui-secondary'}
      />
    ) : status === t('claimPage:buttonStatus.claiming') ? (
      <Icon name="staking-expiration-time" size={20} color="brand-primary" />
    ) : status === t('claimPage:buttonStatus.claimed') || status === true ? (
      <Icon name="edit-finalize" size={20} color="brand-primary" />
    ) : null;
  };

  const trimmedNumber: string = entry.bridgedToken.amount.substring(0, 8);

  return (
    <div className="mobile-row-container mb-2" ref={claimContainerRowNode}>
      <div
        className="closed-row-wrapper justify-content-between pt-3 pb-4 pr-4 pl-4"
        style={{ display: !open ? 'flex' : 'none' }}
      >
        <div className="d-flex ">
          <div className="d-flex mr-2">
            {entry?.sourceNetwork?.chainIcon && (
              <Icon name={entry?.sourceNetwork?.chainIcon} size={20} />
            )}
          </div>
          <div className="d-flex">
            {entry?.targetNetwork?.chainIcon && (
              <Icon name={entry?.targetNetwork?.chainIcon} size={20} />
            )}
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ position: 'absolute', left: '35%' }}
        >
          <div className="trimmed-amount d-flex flex-row align-items-center  justify-content-evenly">
            <p className="text-main" style={{ minWidth: '60px', textAlign: 'right' }}>
              {trimmedNumber}
            </p>
            <div className="d-flex justify-content-between">
              <Icon name={entry?.bridgedToken?.details?.icon} size={20} className="mx-2" />
              <p className="text-main">{entry.bridgedToken.details.symbol}</p>
            </div>
          </div>
        </div>
        <div className="dropdown-icon-closed d-flex justify-content-end">
          {editIcon(editButtonStatus ?? '')}
          <Icon name="chevron-down" size={20} color="brand-primary" />
        </div>
      </div>
      <div
        className="opened-row-wrapper flex-column mb-4"
        style={{ display: open ? 'flex' : 'none' }}
      >
        <div className="source-data-wrapper pt-3 pb-4 pr-4 pl-4">
          <div className="d-flex">
            <div className="source-target-chain-data d-flex flex-column mt-3">
              <div className="d-flex mb-2">
                <div className="d-flex mr-3">
                  {entry?.sourceNetwork?.chainIcon && (
                    <Icon name={entry?.sourceNetwork?.chainIcon} size={20} />
                  )}
                </div>
                <span> {entry?.sourceNetwork?.chainName} </span>
              </div>
              <div className="d-flex">
                <div className="d-flex mr-3 align-items-center justify-content-center">
                  {entry?.targetNetwork?.chainIcon && (
                    <Icon name={entry?.targetNetwork?.chainIcon} size={20} />
                  )}
                </div>
                <span> {entry?.targetNetwork?.chainName}</span>
              </div>
            </div>
            <div className="dropdown-icon-opened d-flex justify-content-end">
              <Icon name="chevron-up" size={20} color="brand-primary" />
            </div>
          </div>
          <div className="d-flex align-items-center amount-data-container justify-content-between mt-6 mb-3">
            <div className="d-flex">
              <Icon name="staking-swap" size={20} color="brand-primary" className="mr-3" />
              <span>{t('claimPage:header.amount')}</span>
            </div>
            <div className="d-flex align-items-center justify-content-end token-amount-wrapper">
              <p className="text-main">{entry.bridgedToken.amount}</p>
              <div className="d-flex justify-content-between">
                <Icon name={entry?.bridgedToken?.details?.icon} size={20} className="mx-2" />
                <p className="text-main">{entry.bridgedToken.details.symbol}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container pt-4 pb-4 px-4" ref={claimContainerButtonNode}>
          <div className="mb-2 button-box d-flex justify-content-center">
            <Button
              size="sm"
              onClick={async () =>
                await addBridgedTokenToWallet('ERC20' as unknown as MetamaskAssetParamsType, {
                  address: '',
                  symbol: entry.bridgedToken.details.symbol,
                  decimals: entry.bridgedToken.details.decimals,
                })
              }
              type={'secondary'}
            >
              {t('claimPage:successModal.addTokenToWallet')}
              <Icon name={'wallet-add-token'} className="ml-2" color={'brand-primary'} size={16} />
            </Button>
          </div>
          <div className="m-auto button-box d-flex justify-content-center">
            <ClaimButton
              disabled={
                !entry.majorityReached ||
                (entry.transmission.transmissionType === 'ccip' && !entry.failed)
              }
              status={
                entry.status === 'true'
                  ? t('claimPage:buttonStatus.claimed')
                  : t('claimPage:buttonStatus.notClaimed')
              }
              clicked={setEditButtonStatus}
              entry={entry}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimingListingMobile;
