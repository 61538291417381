import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { MaintenanceMode } from 'components/MaintenanceMode';
import { GlobalProvider } from 'context/GlobalContext';
import { env } from 'environments';

function App() {
  const maintenanceMode = env.MAINTENANCE_MODE;

  return (
    <Router>
      <GlobalProvider>
        <MaintenanceMode enabled={maintenanceMode}>
          <Layout />
        </MaintenanceMode>
      </GlobalProvider>
    </Router>
  );
}

export default App;
