import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { Accordion } from 'components/Accordion';
import { env } from 'environments';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';

import './SideMenu.scss';

enum SideMenuSection {
  Bridge,
  BridgeNft,
}

const SideMenu = ({ logo, logoMobile, title }: SideMenuProps) => {
  const { isSideMenuOpen, setIsSideMenuOpen, bridgeStatuses, nftBridgeStatuses } =
    useGlobalContext();
  const location = useLocation();
  const { t } = useTranslation();

  const { greaterThan, lessThan } = useBreakpoint();

  const accordionItemsRef = useRef(null);

  const RouteToSideMenuSection: { [route: string]: SideMenuSection } = {
    '/': SideMenuSection.Bridge,
    '/bridge': SideMenuSection.Bridge,
    '/bridge-nft': SideMenuSection.BridgeNft,
  };

  const [activeSection, setActiveSection] = useState<SideMenuSection>(() => {
    const pathname = location.pathname;
    const base = pathname.split('/')[1] ? `/${pathname.split('/')[1]}` : '/';
    return RouteToSideMenuSection[base];
  });

  const handleToggle = (e: React.MouseEvent, section: SideMenuSection) => {
    setActiveSection(section);

    const list = document.querySelectorAll('.accordion-item');

    for (const item in list) {
      if (typeof list[item] === 'object') {
        if (list[item].classList.contains('active')) {
          list[item].classList.remove('active');
        }
      }
    }
    e.currentTarget.classList.add('active');

    if (lessThan('lg')) {
      setIsSideMenuOpen(false);
    }
  };

  return (
    <div className={`sidemenu-container ${isSideMenuOpen ? 'sidemenu-opened' : ''} pt-8 px-6 pb-3`}>
      <div className="sidemenu-content" ref={accordionItemsRef}>
        {isSideMenuOpen && (
          <div className="d-flex justify-content-end mb-5">
            <Icon
              name="cross-big"
              size={32}
              color="ui-main"
              onClick={() => setIsSideMenuOpen(false)}
            />
          </div>
        )}
        <div className="d-flex flex-column">
          <NavLink
            className="text-decoration-none mb-5"
            to={{
              pathname: '/',
              state: { prevPath: location.pathname },
            }}
          >
            <div className="d-flex align-items-center ml-4">
              {greaterThan('md') ? (
                <img height={44} src={logo} alt={`${title} DesktopLogo`} />
              ) : (
                <img height={44} src={logoMobile} alt={`${title} MobileLogo`} />
              )}
            </div>
          </NavLink>
          <div className="mt-6">
            <div className="mb-4">
              <Accordion
                title={t('sideMenu:accordionBridge.title')}
                expanded={activeSection === SideMenuSection.Bridge}
              >
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={e => handleToggle(e, SideMenuSection.Bridge)}
                    to={{
                      pathname: '/',
                      state: { prevPath: location.pathname },
                    }}
                    exact
                  >
                    <span className="d-flex align-items-center">
                      <Icon name="bridge-transfer" size={20} color="ui-secondary" />
                      <span className="ml-3 text-bold">
                        {t('sideMenu:accordionBridge.transfer')}
                      </span>
                      <span className="quantity-number text-bold"></span>
                      <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={e => handleToggle(e, SideMenuSection.Bridge)}
                    to={{
                      pathname: '/bridge/claim',
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <Icon name="bridge-claim" size={20} color="ui-secondary" />
                      <span className="ml-3 text-bold">{t('sideMenu:accordionBridge.claim')}</span>
                      <span className="quantity-number text-bold">
                        {bridgeStatuses.bridge.activeClaims}
                      </span>
                      <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                    </span>
                  </NavLink>
                </li>
              </Accordion>
            </div>
            <Accordion
              title={t('sideMenu:accordionBridge.nft')}
              expanded={activeSection === SideMenuSection.BridgeNft}
            >
              <li>
                <NavLink
                  className="accordion-item"
                  onClick={e => handleToggle(e, SideMenuSection.BridgeNft)}
                  to={{
                    pathname: '/bridge-nft',
                    state: { prevPath: location.pathname },
                  }}
                  exact
                >
                  <span className="d-flex align-items-center">
                    <Icon name="bridge-transfer" size={20} color="ui-secondary" />
                    <span className="ml-3 text-bold">{t('sideMenu:accordionBridge.transfer')}</span>
                    <span className="quantity-number text-bold"></span>
                    <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="accordion-item"
                  onClick={e => handleToggle(e, SideMenuSection.BridgeNft)}
                  to={{
                    pathname: '/bridge-nft/claim',
                    state: { prevPath: location.pathname },
                  }}
                >
                  <span className="d-flex align-items-center">
                    <Icon name="bridge-claim" size={20} color="ui-secondary" />
                    <span className="ml-3 text-bold">{t('sideMenu:accordionBridge.claim')}</span>
                    <span className="quantity-number text-bold">
                      {nftBridgeStatuses.bridge.inActiveNftClaims}
                    </span>
                    <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                  </span>
                </NavLink>
              </li>
            </Accordion>
          </div>
        </div>
        <div className="sidemenu-buttons">
          <div className="flex-column justify-content-center mt-4">
            <p className="text-border my-3">
              Nexera Bridge is powered by Nexera infrastructure and technology.
            </p>
            <div className="d-flex space-btw align-items-center mt-4">
              <a href="https://nexera.network" target="_blank" rel="noopener noreferrer">
                <img height={25} src={'/assets/img/nexera-grey.svg'} alt={'Nexera Logo'} />
              </a>
              <p className="text-tiny text-border my-3">Build:{env.BUILD_VERSION}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
