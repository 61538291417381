import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { Web3ReactProvider } from '@web3-react/core';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TeleportSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { ThemeProvider } from '@stichting-allianceblock-foundation/components';
import { NETWORK_CONFIG } from 'configs';
import { DEFAULT_FEE_CURRENCY, localStorageKeys } from 'configs/constants';
import { env } from 'environments';
import { useSupportedNetworks } from 'hooks/useSupportedNetworks';
import { useWalletConnection } from 'hooks/useWalletConnection';
import { connectors } from 'utils/web3React';

export const getCurrentNetworkStorage = (): Network => {
  const currentNetworkStorage = localStorage.getItem(localStorageKeys.currentNetwork);
  return currentNetworkStorage
    ? JSON.parse(currentNetworkStorage as string)
    : NETWORK_CONFIG.eth.network;
};

const initialState: AppContext = {
  bridgeOperationDisabled: env.DISABLE_BRIDGE_OPERATION,
  config: {
    brandColor: '',
    theme: 'light',
    companyName: 'Nexera',
    companyLogoUrl: '/assets/img/nexera-bridge.svg',
    companyLogoDarkUrl: '/assets/img/nexera-bridge-dark.svg',
    productName: 'NexeraBridge',
    serviceFeeCurrency: DEFAULT_FEE_CURRENCY,
  },
  isWalletConnected: {
    metamask: false,
    phantom: false,
  },
  isUnsupportedChain: false,
  triedConnecting: false,
  isSideMenuOpen: false,
  supportedNetworks: NETWORK_CONFIG,
  currentNetwork: getCurrentNetworkStorage(),
  networkOptions: [],
  bridgeStatuses: {
    bridge: {
      activeClaims: 0,
      inActiveClaims: 0,
      historyNftTransactions: 0,
    },
  },
  nftBridgeStatuses: {
    bridge: {
      activeNftClaims: 0,
      inActiveNftClaims: 0,
    },
  },
  bridgeTransaction: {
    network: {
      source: {
        symbol: '',
        chainId: 0,
        chainName: '',
        chainIcon: '',
        chainTargetId: 0,
        nativeCurrency: {
          name: '',
          symbol: '',
          decimals: 0,
        },
        refreshClaimTimestamp: 0,
        rpcUrl: '',
        blockExplorerUrl: '',
        blockExplorerQueryParams: undefined,
        walletType: '',
        src: undefined,
      },
      target: {
        symbol: '',
        chainId: 0,
        chainName: '',
        chainIcon: '',
        chainTargetId: 0,
        nativeCurrency: {
          name: '',
          symbol: '',
          decimals: 0,
        },
        refreshClaimTimestamp: 0,
        rpcUrl: '',
        blockExplorerUrl: '',
        blockExplorerQueryParams: undefined,
        walletType: '',
        src: undefined,
      },
    },
    recipient: '',
    token: {
      details: {
        name: '',
        symbol: '',
        decimals: 0,
        icon: '',
      },
      amount: '0',
      address: '',
    },
    feeToken: {
      details: {
        name: '',
        symbol: '',
        decimals: 0,
        icon: '',
      },
      amount: '0',
      address: '',
    },
  },
  // TODO: Perhaps this state can be combined with bridgeTransaction.
  // The user should do one type of bridge at a time
  nftBridgeTransaction: {
    network: {
      source: {
        symbol: '',
        chainId: 0,
        chainName: '',
        chainIcon: '',
        chainTargetId: 0,
        nativeCurrency: {
          name: '',
          symbol: '',
          decimals: 0,
        },
        refreshClaimTimestamp: 0,
        rpcUrl: '',
        blockExplorerUrl: '',
        blockExplorerQueryParams: undefined,
        walletType: '',
        src: undefined,
      },
      target: {
        symbol: '',
        chainId: 0,
        chainName: '',
        chainIcon: '',
        chainTargetId: 0,
        nativeCurrency: {
          name: '',
          symbol: '',
          decimals: 0,
        },
        refreshClaimTimestamp: 0,
        rpcUrl: '',
        blockExplorerUrl: '',
        blockExplorerQueryParams: undefined,
        walletType: '',
        src: undefined,
      },
    },
    recipient: '',
    nftTokens: [],
  },
  isTokenAllowedToBridge: true,
  sdk: null,
  targetSDK: null,
  claims: [],
  nftClaims: [],
  serviceFeeOptions: [],
  filterCriteria: {
    network: 'All Networks',
    status: '',
  },
  claimModal: {
    status: false,
    claimData: {
      token: {
        details: {
          name: '',
          symbol: '',
          decimals: 0,
          icon: '',
        },
        amount: '',
        address: '',
      },
      type: '',
      transactionHash: '',
      walletType: '',
      tokenAmount: '',
      tokenName: '',
      recipient: '',
      transactionLink: '',
      tokenType: null,
      tokenOptions: {
        address: '',
        symbol: '',
        decimals: 0,
      },
    },
  },
  isClaimLoaded: false,
  isNftsLoaded: false,
  // TODO: userCollections, addNftCollectionAddress, addNftTokenId
  // These states could be specific to their components and not global.
  userCollections: [],
  addNftCollectionAddress: '',
  addNftTokenId: '',
  collectionCriteria: null,
  collectionFilterIndex: 0,
  userAssets: [],
  loadedUserAssets: [],
  networkSwitchScreen: {
    network: {
      symbol: '',
      chainId: 0,
      chainName: '',
      chainIcon: '',
      chainTargetId: 0,
      nativeCurrency: {
        name: '',
        symbol: '',
        decimals: 0,
      },
      refreshClaimTimestamp: 0,
      rpcUrl: '',
      blockExplorerUrl: '',
      walletType: '',
    },
    connecting: false,
  },
  nftTransferPage: 1,
  addedForTransfer: [],
};

export const GlobalContext = createContext<AppContext>(initialState as AppContext);

export const UpdateGlobalContext = createContext<UpdateAppContext>({
  setBridgeOperationDisabled: () => {
    throw new Error('setBridgeOperationDisabled function must be overridden');
  },
  setConfig: () => {
    throw new Error('setConfig function must be overridden');
  },
  setIsWalletConnected: () => {
    throw new Error('setIsWalletConnected function must be overridden');
  },
  setTriedConnecting: () => {
    throw new Error('setTriedConnecting function must be overridden');
  },
  setIsSideMenuOpen: () => {
    throw new Error('setIsSideMenuOpen function must be overridden');
  },
  setCurrentNetwork: () => {
    throw new Error('setCurrentNetwork function must be overridden');
  },
  setNetworkOptions: () => {
    throw new Error('setNetworkOptions function must be overridden');
  },
  setBridgeStatuses: () => {
    throw new Error('setBridgeStatuses function must be overridden');
  },
  setNftBridgeStatuses: () => {
    throw new Error('setBridgeStatuses function must be overridden');
  },
  setBridgeTransaction: () => {
    throw new Error('setBridgeTransaction function must be overridden');
  },
  setNftBridgeTransaction: () => {
    throw new Error('setNftBridgeTransaction function must be overridden');
  },
  setIsTokenAllowedToBridge: () => {
    throw new Error('setIsTokenAllowedToBridge function must be overridden');
  },
  setSDK: () => {
    throw new Error('setSDK function must be overridden');
  },
  setTargetSDK: () => {
    throw new Error('setTargetSDK function must be overridden');
  },
  setClaims: () => {
    throw new Error('setClaims function must be overridden');
  },
  setNftClaims: () => {
    throw new Error('setClaims function must be overridden');
  },
  setServiceFeeOptions: () => {
    throw new Error('setServiceFeeOptions function must be overridden');
  },
  setFilterCriteria: () => {
    throw new Error('setFilterCriteria function must be overridden');
  },
  setClaimModal: () => {
    throw new Error('setClaimModal function must be overridden');
  },
  setIsClaimLoaded: () => {
    throw new Error('setIsClaimLoaded function must be overridden');
  },
  setIsNftsLoaded: () => {
    throw new Error('setIsNftsLoaded function must be overridden');
  },
  setUserCollections: () => {
    throw new Error('setUserCollections function must be overridden');
  },
  setAddNftCollectionAddress: () => {
    throw new Error('setAddNftCollectionAddress function must be overridden');
  },
  setAddNftTokenId: () => {
    throw new Error('setAddNftTokenId function must be overridden');
  },
  setCollectionCriteria: () => {
    throw new Error('setCollectionCriteria function must be overridden');
  },
  setCollectionFilterIndex: () => {
    throw new Error('setCollectionFilterIndex function must be overridden');
  },
  setUserAssets: () => {
    throw new Error('setUserAssets function must be overridden');
  },
  setLoadedUserAssets: () => {
    throw new Error('setLoadedUserAssets function must be overridden');
  },
  setNetworkSwitchScreen: () => {
    throw new Error('setNetworkSwitchScreen function must be overridden');
  },
  setNftTransferPage: () => {
    throw new Error('setNftTransferPage function must be overridden');
  },
  setAddedForTransfer: () => {
    throw new Error('setAddedForTransfer function must be overridden');
  },
  setIsUnsupportedChain: () => {
    throw new Error('setIsUnsupportedChain function must be overridden');
  },
});

export function GlobalProvider({ children }: { children?: React.ReactChild | React.ReactChild[] }) {
  const { t } = useTranslation();
  const [config, setConfig] = useState<Config>(initialState.config);

  const [bridgeOperationDisabled, setBridgeOperationDisabled] = useState(
    initialState.bridgeOperationDisabled,
  );

  const { isWalletConnected, setIsWalletConnected } = useWalletConnection(
    initialState.isWalletConnected,
  );
  const [isUnsupportedChain, setIsUnsupportedChain] = useState<boolean>(
    initialState.isUnsupportedChain,
  );

  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(initialState.isSideMenuOpen);
  const [triedConnecting, setTriedConnecting] = useState<boolean>(initialState.triedConnecting);
  const [supportedNetworks, setSupportedNetworks] = useState<NetworkConfig>(
    initialState.supportedNetworks,
  );

  const [currentNetwork, setCurrentNetworkPrivate] = useState<Network>(initialState.currentNetwork);
  const setCurrentNetwork = (network: Network) => {
    localStorage.setItem(localStorageKeys.currentNetwork, JSON.stringify(network));
    setCurrentNetworkPrivate(network);
  };
  const [networkOptions, setNetworkOptions] = useState<Network[]>(
    Object.values(NETWORK_CONFIG)?.map((item: NetworkConfigValues) => item?.network),
  );

  const [bridgeStatuses, setBridgeStatuses] = useState<BridgeStatuses>(initialState.bridgeStatuses);
  const [nftBridgeStatuses, setNftBridgeStatuses] = useState<NftBridgeStatuses>(
    initialState.nftBridgeStatuses,
  );
  const [bridgeTransaction, setBridgeTransaction] = useState<BridgeTransaction>(
    initialState.bridgeTransaction,
  );
  const [nftBridgeTransaction, setNftBridgeTransaction] = useState<NFTBridgeTransaction>(
    initialState.nftBridgeTransaction,
  );
  const [isTokenAllowedToBridge, setIsTokenAllowedToBridge] = useState<boolean>(
    initialState.isTokenAllowedToBridge,
  );
  const [sdk, setSDK] = useState<TeleportSDK | null>(initialState.sdk);
  const [targetSDK, setTargetSDK] = useState<TeleportSDK | null>(initialState.targetSDK);

  const [claims, setClaims] = useState(initialState.claims);
  const [nftClaims, setNftClaims] = useState<FormattedNftClaims[]>(initialState.nftClaims);

  const [serviceFeeOptions, setServiceFeeOptions] = useState<ServiceFeeToken[]>(
    initialState.serviceFeeOptions,
  );

  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    network: t('claimPage:filterOptions.network'),
    status: t('claimPage:filterOptions.constants.showAll'),
  });
  const { brandColor, theme } = config;

  const [claimModal, setClaimModal] = useState<ClaimModal>(initialState.claimModal);

  const [isClaimLoaded, setIsClaimLoaded] = useState(initialState.isClaimLoaded);

  const [isNftsLoaded, setIsNftsLoaded] = useState(initialState.isNftsLoaded);

  const [addNftCollectionAddress, setAddNftCollectionAddress] = useState(
    initialState.addNftCollectionAddress,
  );

  const [addNftTokenId, setAddNftTokenId] = useState(initialState.addNftTokenId);

  const [userCollections, setUserCollections] = useState<Collection[]>(
    initialState.userCollections,
  );
  const [collectionCriteria, setCollectionCriteria] = useState(initialState.collectionCriteria);
  const [collectionFilterIndex, setCollectionFilterIndex] = useState(
    initialState.collectionFilterIndex,
  );

  const [userAssets, setUserAssets] = useState<UserAsset[]>(initialState.userAssets);
  const [loadedUserAssets, setLoadedUserAssets] = useState<UserAsset[]>([]);

  const [nftTransferPage, setNftTransferPage] = useState(initialState.nftTransferPage);

  const [networkSwitchScreen, setNetworkSwitchScreen] = useState(initialState.networkSwitchScreen);
  const [addedForTransfer, setAddedForTransfer] = useState<UserAsset[]>(
    initialState.addedForTransfer,
  );

  // Update current supported networks when the hook is loaded with features flags
  const { supportedNetworks: currentSupportedNetworks, loading } = useSupportedNetworks();
  useEffect(() => {
    if (loading) return;
    setSupportedNetworks(currentSupportedNetworks);
  }, [currentSupportedNetworks, loading]);

  const network = useMemo(
    () =>
      env.SOLANA_NETWORK ??
      (() => {
        throw 'No solana network defined';
      })(),
    [],
  );
  const wallets = useMemo(
    () => [
      /*new PhantomWalletAdapter()*/
    ],
    [],
  );

  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets}>
        <Web3ReactProvider connectors={connectors}>
          <ThemeProvider theme={theme} primaryColor={brandColor}>
            <GlobalContext.Provider
              value={{
                bridgeOperationDisabled,
                config,
                isWalletConnected,
                triedConnecting,
                isSideMenuOpen,
                supportedNetworks,
                currentNetwork,
                networkOptions,
                bridgeStatuses,
                nftBridgeStatuses,
                bridgeTransaction,
                isTokenAllowedToBridge,
                nftBridgeTransaction,
                sdk,
                targetSDK,
                claims,
                nftClaims,
                serviceFeeOptions,
                filterCriteria,
                claimModal,
                isClaimLoaded,
                isNftsLoaded,
                userCollections,
                addNftCollectionAddress,
                addNftTokenId,
                collectionCriteria,
                collectionFilterIndex,
                userAssets,
                loadedUserAssets,
                networkSwitchScreen,
                nftTransferPage,
                addedForTransfer,
                isUnsupportedChain,
              }}
            >
              <UpdateGlobalContext.Provider
                value={{
                  setBridgeOperationDisabled,
                  setConfig,
                  setIsWalletConnected,
                  setTriedConnecting,
                  setIsSideMenuOpen,
                  setCurrentNetwork,
                  setNetworkOptions,
                  setBridgeStatuses,
                  setNftBridgeStatuses,
                  setBridgeTransaction,
                  setNftBridgeTransaction,
                  setIsTokenAllowedToBridge,
                  setSDK,
                  setTargetSDK,
                  setClaims,
                  setNftClaims,
                  setServiceFeeOptions,
                  setFilterCriteria,
                  setClaimModal,
                  setIsClaimLoaded,
                  setIsNftsLoaded,
                  setUserCollections,
                  setAddNftCollectionAddress,
                  setAddNftTokenId,
                  setCollectionCriteria,
                  setCollectionFilterIndex,
                  setUserAssets,
                  setLoadedUserAssets,
                  setNetworkSwitchScreen,
                  setNftTransferPage,
                  setAddedForTransfer,
                  setIsUnsupportedChain,
                }}
              >
                {children}
              </UpdateGlobalContext.Provider>
            </GlobalContext.Provider>
          </ThemeProvider>
        </Web3ReactProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
