export const coingeckoCurrencies: { [currency: string]: string } = {
  usd: 'usd',
  eur: 'eur',
  cny: 'cny',
  jpy: 'jpy',
  rub: 'rub',
  gbp: 'gbp',
};

export const currencySymbols: { [currency: string]: string } = {
  usd: '$',
  eur: '€',
  cny: '¥',
  jpy: '¥',
  rub: '₽',
  gbp: '£',
};

export const coingeckoAPI: string = 'https://api.coingecko.com/api/v3';

// returns coingecko id given the token symbol
export const getCoingeckooIds = (symbol: string): CoinGeckoId => {
  switch (symbol) {
    case 'eth':
    case 'aeth':
    case 'weth':
      return {
        id: 'ethereum',
        symbol: 'eth',
        name: 'Ethereum',
      };
    case 'bnb':
    case 'wbnb':
      return {
        id: 'binancecoin',
        symbol: 'bnb',
        name: 'Binance Coin',
      };
    case 'avax':
    case 'wavax':
      return {
        id: 'avalanche-2',
        symbol: 'AVAX',
        name: 'Avalanche',
      };
    case 'matic':
    case 'wmatic':
      return {
        id: 'matic-network',
        symbol: 'matic',
        name: 'Polygon',
      };
    case 'ewc':
    case 'ewt':
    case 'abewt':
    case 'wewt':
    case 'wabewt':
    case 'vt':
      return {
        id: 'energy-web-token',
        symbol: 'ewt',
        name: 'Energy Web Token',
      };
    case 'sgb':
    case 'wsgb':
      return {
        id: 'songbird',
        symbol: 'sgb',
        name: 'Songbird',
      };
    case 'aurora':
      return {
        id: 'aurora-near',
        symbol: 'aurora',
        name: 'Aurora',
      };
    case 'dev':
      return {
        id: 'moonbeam',
        symbol: 'glmr',
        name: 'Moonbeam',
      };
    case 'ftm':
      return { id: 'fantom', symbol: 'ftm', name: 'Fantom' };
    case 'ejs':
    case 'enjinstarter':
      return { id: 'enjinstarter', symbol: 'ejs', name: 'Enjinstarter' };
    case 'wejs':
      return {
        id: 'enjinstarter',
        symbol: 'wejs',
        name: 'Wrapped Enjinstarter',
      };
    case 'sol':
      return { id: 'solana', symbol: 'sol', name: 'Solana' };
    default:
      return {} as CoinGeckoId;
  }
};

const priceCache: {
  [key: string]: {
    expiration: number;
    value: number;
  };
} = {};

export const getTokenPrice = async (tokenId: string, currency: string): Promise<number> => {
  const cacheKey = `${tokenId}-${currency}`;
  const cached = priceCache[cacheKey];
  if (cached && cached.expiration > Date.now()) {
    return cached.value;
  }
  const response = await fetch(
    `${coingeckoAPI}/simple/price?ids=${tokenId}&vs_currencies=${currency}`,
  );
  const data = await response.json();
  const value: number = data[tokenId][currency];
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + 5 * 1000); // 5 seconds
  priceCache[cacheKey] = {
    expiration: expiration.getTime(),
    value,
  };
  return data[tokenId][currency];
};
