import { initReactI18next } from 'react-i18next';

import { env } from 'environments';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, es } from '../locales';

/*
 * + HOW TO ADD NEW LANGUAGES
 *
 * 1. Create a JSON file [lang-code].json with the translations in the locales folder.
 *    More info about language codes here: https://www.w3.org/International/O-charset-lang.html
 *    Or here: https://www.science.co.il/language/Locale-codes.php
 * 2. Import and export it in the index.js file in the locales folder.
 * 3. Import the JSON file into this file (i18next.js).
 * 4. Add the JSON file in the resource option
 *
 *
 * + HOW TO ADD TRANSLATIONS
 *
 * For this, we use the hook useTranslation built by i18next
 * More info here: https://react.i18next.com/latest/usetranslation-hook
 *
 * We use namespaces for the components/views
 *
 * For example: The WalletButton component, we add it as "walletButton" in the [lang-code].json
 * later we can access to it as "walletButton:someKey"
 *
 * The same it would be for the others components:
 * "header" => "header.someKey"
 * "layout" => "layout.someKey"
 * "select" => "select.someKey"
 *    ...   =>       ...
 *
 *
 * + HOW TO ADD TRANSLATIONS WITH HTML TAGS
 *
 * We can use the Trans component built by i18next.
 * More info here: https://react.i18next.com/latest/trans-component
 *
 * If we have a text like this: "This is some <span>example</span>. It's awesome!".
 *
 * We use the Trans component like this:
 *
 * <Trans i18nKey="common:text" components={{ span: <span /> }} />
 *
 * Then, we add the tag span as a component into the components property
 *
 */

// options for the language detector plugin
// More info: https://github.com/i18next/i18next-browser-languageDetector#detector-options
const detectorOptions = {
  // we only use the browser as language source
  order: ['navigator'],
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !env.IS_PRODUCTION,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
      es,
    },
    detection: detectorOptions,
  });

export default i18n;
