import React from 'react';
import { useTranslation } from 'react-i18next';

import './NftClaimHeader.scss';
function NftClaimHeader() {
  const { t } = useTranslation();

  return (
    <div className="container-nft-header d-flex justify-content-between my-2">
      <div className="nft-token d-flex">
        <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.nftToken')}</p>
      </div>
      <div className="nft-schema d-flex">
        <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.schema')}</p>
      </div>
      <div className="d-flex source-chain d-flex flex-grow-1">
        <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.sourceChain')}</p>
      </div>
      <div className="d-flex target-network">
        <div>
          <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.targetChain')}</p>
        </div>
      </div>
      <div className="d-flex amount-nft">
        <div>
          <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.amount')}</p>
        </div>
      </div>
      <div className="status-header d-flex">
        <p className="text-tiny text-bold text-border">{t('nftClaimPage:header.status')}</p>
      </div>
    </div>
  );
}

export default NftClaimHeader;
