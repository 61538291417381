import React, { memo, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as TransferSuccessDesktopLogo } from 'assets/transfer-success-desktop.svg';
import { ReactComponent as TransferSuccessMobileLogo } from 'assets/transfer-success-mobile.svg';
import { StepperBridge, Title } from 'components/Transfer';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';

const Transfer = () => {
  const history = useHistory();

  const { serviceFeeOptions, bridgeTransaction, setBridgeTransaction } = useGlobalContext();
  const { account } = useWallet();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  const [bridgeSteps, setBridgeSteps] = useState<BridgeSteps>({
    next: true,
    approveServiceFeeToken: false,
    approveBridgeToken: false,
    transfer: false,
    processing: false,
  });
  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const [tokenSelectedBalance, setTokenSelectedBalance] = useState<string>('0');
  const [sliderPercentage, setSliderPercentage] = useState(0);
  const [selectedToken, setSelectedToken] = useState<TokenDetails>({} as TokenDetails);
  const [serviceFeeTokenIndex, setServiceFeeTokenIndex] = useState(0);
  const [wasTransactionCleared, setWasTransactionCleared] = useState<boolean>(false);
  const [wasTransferSuccessful, setWasTransferSuccessful] = useState<boolean>(false);

  const clearBridgeTransaction = useCallback(async () => {
    setTokenSelectedBalance('0');
    setSliderPercentage(0);
    setSelectedToken({} as TokenDetails);
    const serviceFeeOption = serviceFeeOptions[0];
    setBridgeTransaction(prevBridgeTransaction => {
      return {
        ...prevBridgeTransaction,
        token: {
          details: {
            name: '',
            symbol: '',
            decimals: 0,
            icon: '',
          },
          amount: '0',
          address: '',
        },
        feeToken: {
          details: {
            name: serviceFeeOption?.name,
            symbol: serviceFeeOption?.symbol,
            decimals: serviceFeeOption?.decimals,
            icon: serviceFeeOption?.icon,
          },
          amount: serviceFeeOption?.amount,
          address: serviceFeeOption?.address,
        },
        recipient: account as string,
      };
    });
    setWasTransactionCleared(true);
    setServiceFeeTokenIndex(0);
  }, [account, serviceFeeOptions, setBridgeTransaction]);

  useEffect(() => {
    const clearBridgeTransactionWhenRendered = () => {
      const serviceFeeOption = serviceFeeOptions[0];
      setBridgeTransaction({
        network: {
          source: {} as Network,
          target: {} as Network,
        },
        token: {
          details: {
            name: '',
            symbol: '',
            decimals: 0,
            icon: '',
          },
          amount: '0',
          address: '',
        },
        feeToken: {
          details: {
            name: serviceFeeOption?.name,
            symbol: serviceFeeOption?.symbol,
            decimals: serviceFeeOption?.decimals,
            icon: serviceFeeOption?.icon,
          },
          amount: serviceFeeOption?.amount,
          address: serviceFeeOption?.address,
        },
        recipient: account as string,
      });
    };

    clearBridgeTransactionWhenRendered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const goToTheTopOnChangeStep = () => {
      window.scrollTo(0, 0);
    };

    goToTheTopOnChangeStep();
  }, [activeStep]);

  const renderTransferPage = () => {
    return (
      <div className="mt-7 mb-5 my-md-7">
        <Title />
        <StepperBridge
          activeStep={activeStep}
          wasTransactionCleared={wasTransactionCleared}
          tokenSelectedBalance={tokenSelectedBalance}
          selectedToken={selectedToken}
          sliderPercentage={sliderPercentage}
          serviceFeeTokenIndex={serviceFeeTokenIndex}
          bridgeSteps={bridgeSteps}
          updateSliderPercentage={setSliderPercentage}
          updateSelectedToken={setSelectedToken}
          updateTokenSelectedBalance={setTokenSelectedBalance}
          updateActiveStep={setActiveStep}
          updateBridgeSteps={setBridgeSteps}
          updateServiceFeeTokenIndex={setServiceFeeTokenIndex}
          updateWasTransferSuccessful={setWasTransferSuccessful}
          updateWasTransactionClear={setWasTransactionCleared}
          clearBridgeTransaction={clearBridgeTransaction}
        />
      </div>
    );
  };

  const renderTransferSuccessPage = () => {
    return (
      <div className="mt-7 mb-5 my-md-7">
        <div className="d-flex justify-content-center mt-4">
          {greaterThan('md') ? (
            <TransferSuccessDesktopLogo className="max-width-100" />
          ) : (
            <TransferSuccessMobileLogo className="max-width-100" />
          )}
        </div>

        <div className="mt-4 mb-5">
          <div className="text-medium text-bold text-main text-center mb-3 mr-5">
            {t('transferPage:transferSuccess.title')}
          </div>
          <div className="text-center">
            <Trans
              i18nKey="transferPage:transferSuccess.subtitle"
              components={{ span: <span /> }}
              values={{
                chainName: bridgeTransaction?.network?.target?.chainName,
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <Button
            type="secondary"
            className="my-2 my-md-0 mx-md-2 order-last order-md-first"
            onClick={() => {
              clearBridgeTransaction();
              setBridgeSteps({
                ...bridgeSteps,
                next: true,
              });
              setActiveStep(0);
              setWasTransferSuccessful(false);
            }}
          >
            <Icon name="check" size={18} color="ui-border" />
            <span className="ml-2">{t('transferPage:transferSuccess.closeButton')}</span>
          </Button>
          <Button
            type="primary"
            className="my-2 my-md-0 mx-md-2 order-first order-md-last"
            onClick={() => {
              const list = document.querySelectorAll('.accordion-item');

              for (const item in list) {
                if (typeof list[item] === 'object') {
                  if (list[item].classList.contains('active')) {
                    list[item].classList.remove('active');
                  }
                }
              }

              history.push('/bridge/claim');

              clearBridgeTransaction();
            }}
          >
            <span>{t('transferPage:transferSuccess.goToClaimButton')}</span>
            <Icon name="arrow-right-long" size={18} color="ui-border" className="ml-3" />
          </Button>
        </div>
      </div>
    );
  };

  return !wasTransferSuccessful ? renderTransferPage() : renderTransferSuccessPage();
};

export default memo(Transfer);
