import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Tooltips';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';

function NftClaimTitle() {
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag();
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="text-medium text-bold text-main mb-3 mr-3">{t('nftClaimPage:title')}</div>
        <InfoTooltip
          text={t('nftClaimPage:titleTooltip', {
            context: isEnabled('ERC1155') ? 'with_erc1155' : '',
          })}
          size={20}
        />
      </div>
      <div className="text-main">
        <Trans
          i18nKey="nftClaimPage:description"
          components={{ span: <span /> }}
          context={isEnabled('ERC1155') ? 'with_erc1155' : ''}
        />
      </div>
    </>
  );
}

export default NftClaimTitle;
