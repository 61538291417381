import { useEffect } from 'react';

import { constants, TeleportSDKFactory } from '@stichting-allianceblock-foundation/abridge-sdk';
import { NETWORK_CONFIG } from 'configs';
import { CHAINS_TYPES } from 'configs/constants';
import { env } from 'environments';
import { ethers } from 'ethers';

import { useGlobalContext } from './useGlobalContext';
import useWallet from './useWallet';

export const useTargetSDK = () => {
  const { account } = useWallet();
  const { bridgeTransaction, setTargetSDK } = useGlobalContext();

  useEffect(() => {
    const newTargetSDK = async () => {
      const { rpcUrl, walletType } = bridgeTransaction.network.target;

      if (account && rpcUrl) {
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const signer = provider.getSigner(account);
        const network = await signer.provider.getNetwork();

        if (network) {
          const abridgeBackend = env.ABRIDGE_BACKEND_URL;
          const _targetSDK = await TeleportSDKFactory.create({
            chainType: CHAINS_TYPES[walletType] as constants.ChainType,
            signerOrProvider: signer,
            optionalParams: {
              validatorAllowedVersion: env.VALIDATOR_ALLOWED_VERSION,
              validators: env.VALIDATORS,
              isProduction: env.IS_PRODUCTION || env.IS_STAGING,
              rpcs: Object.keys(NETWORK_CONFIG).reduce((acc, key) => {
                const { chainId, rpcUrl } = NETWORK_CONFIG[key].network;
                return { ...acc, [chainId]: rpcUrl };
              }, {}),
            },
            modulesOptionalParams: {
              ERC20: { abridgeBackend },
              ERC721: { abridgeBackend },
              ERC1155: { abridgeBackend },
            },
          });
          if (_targetSDK) {
            setTargetSDK(_targetSDK);
          }
        }
      }
    };

    newTargetSDK();
  }, [account, bridgeTransaction.network.target, setTargetSDK]);
};

export default useTargetSDK;
