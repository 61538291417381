import React, { useEffect } from 'react';

import './Modal.scss';

const ModalClaim = ({
  show,
  onClose,
  children,
}: {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [onClose]);

  return (
    <div className={'modal-claim ' + (show ? ' enter-done' : ' modal.exit')} onClick={onClose}>
      <div className="modal-container" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default ModalClaim;
