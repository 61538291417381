import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getNetworkIndex } from 'utils/network';

import { useGlobalContext } from './useGlobalContext';
import useWallet from './useWallet';

export const useNetworkSelected = () => {
  const { t } = useTranslation();
  const { chainId } = useWallet();
  const { currentNetwork, isUnsupportedChain, networkOptions, supportedNetworks } =
    useGlobalContext();

  const [options, setOptions] = useState<Network[] | UnsupportedNetwork[]>(networkOptions);
  const [networkIndex, setNetworkIndex] = useState<number>(0);

  useEffect(() => {
    const opts = Object.values(supportedNetworks).map(
      (item: Network | UnsupportedNetwork) => item?.network,
    );
    if (isUnsupportedChain) {
      opts.push({
        chainName: t('layout:unsupportedChain.chainName'),
        chainIcon: 'warning',
        chainId: -1,
      });
    }

    setOptions(opts);
    setNetworkIndex(getNetworkIndex(chainId || currentNetwork?.chainId, opts));
  }, [chainId, currentNetwork?.chainId, isUnsupportedChain, supportedNetworks, t]);

  return { options, networkIndex };
};
