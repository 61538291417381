import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, TextField } from '@stichting-allianceblock-foundation/components';
import { InfoTooltip } from 'components/Tooltips';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { isAddress } from 'utils';

import './AddNft.scss';

function AddNft({ toggleAddToken }: AddNft) {
  const {
    addNftCollectionAddress,
    setAddNftCollectionAddress,
    addNftTokenId,
    setAddNftTokenId,
    currentNetwork,
  } = useGlobalContext();

  const [error, setError] = useState<string>('');

  const { t } = useTranslation();

  const handleCollectionAddressInputChange = async (value: string) => {
    // TODO: Logic for nft search
    setError('');
    setAddNftCollectionAddress(value);
  };

  const handleTokenIdInputChange = async (value: string) => {
    setAddNftTokenId(value);
  };

  const addToken = () => {
    if (!isAddress(addNftCollectionAddress, currentNetwork.walletType)) {
      setError(t('nftSelectionPage:searchCollectionField.collection.error'));
    } else {
      try {
        toggleAddToken(addNftCollectionAddress, addNftTokenId);
        setError('');
      } catch (e) {
        console.error('Node is congested', e);
        setError('Node is overloaded at the moment, please try again later');
      }
    }
  };

  return (
    <div className="add-nft-container py-5 px-4">
      <div className="d-flex flex-column flex-md-row align-items-md-center">
        <div className="flex-one mr-0 mr-md-3">
          <div className="d-flex align-items-center">
            <div className="text-small text-bold text-secondary mb-3 mr-3">
              {t('nftSelectionPage:searchCollectionField.collection.title')}
            </div>
            <InfoTooltip
              text={t('nftSelectionPage:searchCollectionField.collection.tooltip')}
              size={18}
            />
          </div>
          <TextField
            className="collection-address"
            placeholder={t('nftSelectionPage:searchCollectionField.collection.placeholder')}
            icon="wallet"
            value={addNftCollectionAddress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleCollectionAddressInputChange(event.target.value)
            }
            error={error}
          />
        </div>
        <div className="mt-3 mt-md-0">
          <div className="d-flex align-items-center">
            <div className="text-small text-bold text-secondary mb-3 mr-3">
              {t('nftSelectionPage:searchCollectionField.tokenId.title')}
            </div>
            <InfoTooltip
              text={t('nftSelectionPage:searchCollectionField.tokenId.tooltip')}
              size={18}
            />
          </div>
          <TextField
            className="token-id"
            placeholder={t('nftSelectionPage:searchCollectionField.tokenId.placeholder')}
            icon="tdv-id-card"
            value={addNftTokenId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleTokenIdInputChange(event.target.value)
            }
          />
        </div>
      </div>
      <Button
        size="md"
        type="primary"
        disabled={!addNftCollectionAddress}
        onClick={() => addToken()}
        className="mt-4"
      >
        <Icon className="mr-2" size={18} name="collapsed" color="ui-main-background" />
        <span>
          {addNftTokenId && addNftCollectionAddress
            ? t('nftSelectionPage:searchCollectionField.addButton.token')
            : t('nftSelectionPage:searchCollectionField.addButton.collection')}
        </span>
      </Button>
    </div>
  );
}
export default AddNft;
