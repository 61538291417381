import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  ERC721RouterSDK,
  ERC1155RouterSDK,
  utils,
} from '@stichting-allianceblock-foundation/abridge-sdk';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import AddNft from 'components/Nft/AddNft/AddNft';
import ClearButton from 'components/Nft/ClearButton/ClearButton';
import CollectionFilter from 'components/Nft/CollectionFilter/CollectionFilter';
import NftContainerHeader from 'components/Nft/NftContainerHeader/NftContainerHeader';
import NftFilter from 'components/Nft/NftFilter/NftFilter';
import NftTableContainer from 'components/Nft/NftTableContainer/NftTableContainer';
import NftTitle from 'components/Nft/NftTitle/NftTitle';
import { SkeletonNftSelectCard } from 'components/Skeletons/SkeletonNftSelectCard';
import { openSeaApiKey } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';

import './NftPage.scss';

function NftPage() {
  const history = useHistory();
  const { greaterThan } = useBreakpoint();
  const {
    sdk,
    setUserCollections,
    userCollections,
    collectionCriteria,
    setUserAssets,
    nftBridgeTransaction,
    setNftBridgeTransaction,
    addedForTransfer,
    setAddedForTransfer,
    loadedUserAssets,
    setLoadedUserAssets,
  } = useGlobalContext();
  const { isEnabled } = useFeatureFlag();
  const { account, chainId } = useWallet();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const isMobile: boolean = !greaterThan('sm');
  const [nftEnumerableMessage, setNftEnumerableMessage] = useState(false);

  const [isMetaDataTooBig, setIsMetaDataTooBig] = useState<boolean>(false);
  const [isNFTsLengthTooBig, setIsNFTsLengthTooBig] = useState(false);

  useEffect(() => {
    setAddedForTransfer([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      setSearchText('');
    }
  }, [loading]);

  useEffect(() => {
    setIsNFTsLengthTooBig(addedForTransfer.length > 20);

    let metaDataLengthCount: number = 0;
    if (addedForTransfer?.length) {
      addedForTransfer.forEach(item => {
        metaDataLengthCount += item.token_metadata.length;
      });
      setIsMetaDataTooBig(metaDataLengthCount > 3000);
    } else {
      setIsMetaDataTooBig(false);
    }
  }, [addedForTransfer]);

  useEffect(() => {
    const loadNFTCollections = async () => {
      setUserCollections([]);
      setLoadedUserAssets([]);

      if (sdk && account) {
        setLoading(true);
        let openseaUserCollections: Collection[] = await sdk.utils.opensea
          .getUserCollections(account, chainId, openSeaApiKey)
          .catch((error: unknown) => {
            console.error('failed to getUserCollections', error);
          });

        if (!isEnabled('ERC1155')) {
          openseaUserCollections = openseaUserCollections.filter(openseaUserCollection => {
            return openseaUserCollection.primary_asset_contracts?.[0].schema_name !== 'ERC1155';
          });
        }

        if (openseaUserCollections?.length > 0) {
          setUserCollections(
            openseaUserCollections.map(useCollection => {
              return {
                ...useCollection,
                schema: useCollection.primary_asset_contracts
                  ? useCollection.primary_asset_contracts[0].schema_name
                  : '',
                collectionAddress: useCollection.primary_asset_contracts
                  ? useCollection.primary_asset_contracts[0].address
                  : '',
              };
            }),
          );
        }
      }
      setLoading(false);
    };

    loadNFTCollections();
  }, [account, chainId, isEnabled, sdk, setLoadedUserAssets, setUserCollections]);

  useEffect(() => {
    const loadUserAssets = async () => {
      setUserAssets([]);

      const included = loadedUserAssets?.some(
        asset => asset.asset_contract.address === collectionCriteria?.collectionAddress,
      );
      if (included) {
        setUserAssets(
          loadedUserAssets?.filter(
            asset => asset.asset_contract.address === collectionCriteria?.collectionAddress,
          ),
        );
      } else if (sdk && account && collectionCriteria?.collectionAddress) {
        try {
          const res = await sdk.utils.opensea.getUserAssetsByCollection(
            account,
            collectionCriteria?.collectionAddress,
            chainId,
          );
          if (res?.length > 0) {
            setLoadedUserAssets(prevLoadedUserAssets => {
              return [...prevLoadedUserAssets, ...res];
            });
            setUserAssets(res);
          }
        } catch (error) {
          console.error('The node is congested', error);
        }
      }
    };
    loadUserAssets();
  }, [
    account,
    chainId,
    collectionCriteria?.collectionAddress,
    loadedUserAssets,
    sdk,
    setLoadedUserAssets,
    setUserAssets,
  ]);

  const clearSelection = () => {
    setAddedForTransfer([]);
  };

  const addForTransfer = (asset: UserAsset) => {
    const included = addedForTransfer.some(asset_ => asset.token_id === asset_.token_id);
    if (included) {
      const result = addedForTransfer.filter(i => i.token_id !== asset.token_id);
      setAddedForTransfer(result);
    } else setAddedForTransfer(prev => [...prev, asset]);
  };

  const handleTokenInputChange = (input: string) => {
    setSearchText(input);
    if (input?.length > 0) {
      const included = loadedUserAssets?.filter(
        asset =>
          asset.token_id.includes(input) &&
          asset.asset_contract.address === collectionCriteria?.collectionAddress,
      );
      setUserAssets(included);
    } else {
      setUserAssets(
        loadedUserAssets?.filter(
          asset => asset.asset_contract.address === collectionCriteria?.collectionAddress,
        ),
      );
    }
  };

  const toggleAddToken = async (collectionAddress: string, tokenId: string) => {
    if (userCollections.some(collection => collection.collectionAddress === collectionAddress)) {
      return;
    }

    setNftEnumerableMessage(false);
    setLoading(true);
    setSearchText('');

    if (account && sdk) {
      try {
        const maxAttempts = 10;
        let flag: boolean = false;
        let result: Result | null = null;
        let attemptsCount: number = 0;

        type RouterSDKType = typeof ERC721RouterSDK | typeof ERC1155RouterSDK;

        const module: RouterSDKType = await sdk.getModuleByCollectionAddress(collectionAddress);

        if (!isEnabled('ERC1155') && module?.DAPP_NAME === 'ERC1155') {
          setLoading(false);
          return;
        }

        while (!flag && module) {
          try {
            const res = tokenId
              ? await sdk
                  .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
                  .loadTokenByAddressAndId(collectionAddress, tokenId, account)
              : await sdk
                  .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
                  .loadTokensByCollectionAddress(collectionAddress);

            if (res) {
              result = res;
              flag = true;
            }
          } catch (error) {
            if (error instanceof Error) {
              if (
                error.message ==
                  'Cannot find tokens by collection address only. Please, enter token ID.' ||
                error.message == 'Cannot find collection at address.'
              ) {
                flag = true;
                break;
              }
            }
            console.error('The node is congested', error);
          }

          attemptsCount++;
          // try a couple of times in case node is congested if it still is break the cycle
          if (attemptsCount === maxAttempts) {
            flag = true;
          }
        }

        if (!result) {
          setLoading(false);
          if (!tokenId) setNftEnumerableMessage(true);
          return;
        }

        setUserCollections(oldItems => {
          return [
            {
              name: result?.collectionName ?? '',
              slug: result?.collectionName ?? '',
              icon: 'check',
              schema: module?.DAPP_NAME,
              manuallyLoaded: true,
              collectionAddress: result?.collectionAddress ?? '',
              chainId: chainId,
            },
            ...oldItems,
          ];
        });

        // This is a hack to make sure that the result is an array
        const items = result.items ?? [result];

        const userAssets: UserAsset[] = items.map(item => {
          return {
            asset_contract: {
              address: collectionAddress,
            },
            image_preview_url: utils.uriToHttp(item.image) ?? '',
            name: item.name,
            token_id: item.id,
            token_metadata: utils.uriToHttp(item.tokenURI) ?? '',
          };
        });
        setLoadedUserAssets(prevLoadedUserAssets => {
          return [...prevLoadedUserAssets, ...userAssets];
        });
        setUserAssets(userAssets);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };

  return (
    <div className="nft-page-container my-5 my-md-7 pr-0 pr-md-4">
      <div className="nft-data-wrapper">
        <NftTitle />
        <div className="add-token mt-4">
          <AddNft toggleAddToken={toggleAddToken}></AddNft>
        </div>
        {nftEnumerableMessage && (
          <p className="text-danger mt-3">
            {t('nftSelectionPage:searchCollectionField.errorMessages.notOnlyByCollectionAddress')}
          </p>
        )}
        {isMobile ? (
          <div className="nft-mobile-search mt-4">
            <NftFilter value={searchText} onTokenInputChange={handleTokenInputChange} />
          </div>
        ) : null}
        <div
          className="claimed-option-wrapper claimed-option-wrapper d-flex mt-5"
          onClick={e => {
            e.stopPropagation();
            clearSelection();
          }}
        >
          <CollectionFilter />
          {!isMobile ? (
            <NftFilter value={searchText} onTokenInputChange={handleTokenInputChange} />
          ) : null}
        </div>
        <NftContainerHeader schema={collectionCriteria?.schema} />

        {loading ? (
          <SkeletonNftSelectCard rows={2} />
        ) : (
          <NftTableContainer addForTransfer={addForTransfer} addedForTransfer={addedForTransfer} />
        )}
      </div>
      <div style={{ textAlign: 'right' }}>
        {isMetaDataTooBig ? (
          <p className="text-danger">{t('nftSelectionPage:txInfo.metadataLength')}</p>
        ) : null}
        {isNFTsLengthTooBig ? (
          <p className="text-danger">{t('nftSelectionPage:txInfo.maxNftPerTx')}</p>
        ) : null}
      </div>
      <div className="step-nft-buttons d-flex flex-column flex-md-row justify-content-md-between mt-8 mb-8 z-index-6">
        <ClearButton addedForTransfer={addedForTransfer} clearSelection={clearSelection} />

        <Button
          disabled={!addedForTransfer.length || isMetaDataTooBig || isNFTsLengthTooBig}
          type="primary"
          className="mb-4 mb-md-0 mt-3 mt-md-0"
          onClick={() => {
            setNftBridgeTransaction({
              ...nftBridgeTransaction,
              nftTokens: addedForTransfer,
            });
            history.push('/bridge-nft/transfer');
          }}
        >
          <Icon name="staking-swap" size={18} color="ui-border" className="mr-2" />
          <span className="mr-2">{t('nftSelectionPage:buttons.goToTransferButton')}</span>
        </Button>
      </div>
    </div>
  );
}

export default NftPage;
