import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ClaimFilterDropdown from 'components/Claim/ClaimFilter/ClaimFilterDropdown';
import { InfoTooltip } from 'components/Tooltips';
import { useGlobalContext } from 'hooks/useGlobalContext';

function CollectionFilter() {
  const {
    userCollections,
    setCollectionCriteria,
    collectionFilterIndex,
    setCollectionFilterIndex,
    setNftTransferPage,
  } = useGlobalContext();

  const { t } = useTranslation();

  useEffect(() => {
    const collection = userCollections[collectionFilterIndex];
    setCollectionCriteria(collection);
  }, [collectionFilterIndex, setCollectionCriteria, userCollections]);

  return (
    <div className="collection-option-wrapper d-flex justify-content-between mb-4 mb-md-0">
      <div className="dropdown-option-wrapper d-flex flex-column width-100">
        <div className="d-flex align-items-center">
          <div className="text-small text-bold text-secondary mb-3 mr-3">
            {t('nftSelectionPage:collectionFilterField.title')}
          </div>
          <InfoTooltip text={t('nftSelectionPage:collectionFilterField.tooltip')} size={18} />
        </div>
        <ClaimFilterDropdown
          className="mr-0 mr-md-3 dropdown d-block"
          optionsList={userCollections.map(
            (item, index: number): Collection & { index: number } => {
              return {
                ...item,
                name: item.name,
                icon: 'check',
                index,
                slug: item.slug,
              };
            },
          )}
          displayFields={{
            primary: 'name',
            secondary: '',
            icon: 'icon',
          }}
          size="sm"
          selected={collectionFilterIndex}
          onSelectOptionChange={option => {
            setCollectionFilterIndex(option.index);
            setNftTransferPage(1);
          }}
          customEmptyText={t('nftSelectionPage:collectionFilterField.emptyText')}
          disabled={!userCollections.length}
        />
      </div>
    </div>
  );
}
export default CollectionFilter;
