import { initializeConnector } from '@web3-react/core';
import { Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';

import { ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers';
import { ethers } from 'ethers';

const POLLING_INTERVAL: number = 12000;

export const getLibrary = (
  provider: ExternalProvider | JsonRpcFetchFunc,
): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, 'any');
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(
  actions => new MetaMask({ actions }),
);

export const connectors: [MetaMask, Web3ReactHooks][] = [[metaMask, metaMaskHooks]];
