import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { utils } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Icon } from '@stichting-allianceblock-foundation/components';

import NftClaimButton from '../../NftClaimButton/NftClaimButton';

import './NftListItemMobile.scss';

const NftListItemMobile = ({ claim }: { claim: FormattedNftClaims }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const buttonStatus: string = claim.delivered[0]
    ? t('nftClaimPage:buttonStatus.claimed')
    : t('nftClaimPage:buttonStatus.notClaimed');

  const defaultNFT = '../images/default-nft.png';

  const claimContainerRowNode = useRef<HTMLHeadingElement>(null);
  const claimContainerButtonNode = useRef<HTMLHeadingElement>(null);

  const nftImagesClosedRow = claim.items?.map((item, index) =>
    index < 2 ? (
      <div className="d-flex nft-mobile-images-wrapper align-items-center" key={index}>
        <img
          className="nft-mobile-listing-image mr-3"
          src={utils.uriToHttp(item?.image) || defaultNFT}
        />
        {claim.items.length < 2 && (
          <div className="nft-dsc-wrapper">
            <p className="text-main text-bold">
              {item.name || `${item.collectionName}#${item.tokenId}`}
            </p>
          </div>
        )}
      </div>
    ) : null,
  );

  const nftClosedRowClaimNumber =
    claim.items.length > 2 ? (
      <div className="text-main text-bold">
        +{claim.items.length - 2} {t('nftClaimPage:otherText')}
      </div>
    ) : null;

  const nftImagesOpenRow = claim.items?.map((item, index: number) =>
    index < 2 ? (
      <div className="d-flex nft-mobile-images-wrapper" key={index}>
        <img
          className="nft-mobile-listing-image ml-2"
          src={utils.uriToHttp(item?.image) || defaultNFT}
        />
      </div>
    ) : null,
  );

  const nftOpenRowClaimNumber =
    claim.items.length > 2 ? (
      <div className="d-flex align-items-center text-main text-bold">+{claim.items.length - 2}</div>
    ) : null;

  useEffect(() => {
    const collapseIfAlreadyExpanded = (e: MouseEvent) => {
      // handle click inside mobile claim container
      const isInMobileRowContainer =
        claimContainerRowNode.current && claimContainerRowNode.current.contains(e.target as Node);
      // container for the claim button will not react with mousedown event
      const isInMobileButtonContainer =
        claimContainerButtonNode &&
        claimContainerButtonNode.current &&
        claimContainerButtonNode.current.contains(e.target as Node);

      if (isInMobileRowContainer) {
        if (!isInMobileButtonContainer) {
          setOpen(prevState => !prevState);
        }
        return;
      }
      if (!isInMobileRowContainer) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', collapseIfAlreadyExpanded);

    return () => {
      document.removeEventListener('mousedown', collapseIfAlreadyExpanded);
    };
  }, []);

  const editIcon = (status: string) => {
    return status === t('nftClaimPage:buttonStatus.notClaimed') ? (
      <Icon name="edit-claim" size={12} color="brand-primary" />
    ) : status === t('nftClaimPage:buttonStatus.claiming') ? (
      <Icon name="staking-expiration-time" size={20} color="brand-primary" />
    ) : (
      <Icon name="nav-ok" size={12} color="brand-primary" />
    );
  };

  return (
    <div className="mobile-nft-row-container" ref={claimContainerRowNode}>
      <div
        className="closed-row-wrapper justify-content-between pt-3 pb-2 pr-4 pl-4"
        style={{ display: !open ? 'flex' : 'none' }}
      >
        <div className="nft-images d-flex align-items-center">
          <>
            {nftImagesClosedRow}
            {nftClosedRowClaimNumber}
          </>
        </div>
        <div className="nft-amount-status-wrap d-flex  justify-content-between">
          <div className="d-flex flex-row align-items-center  justify-content-evenly">
            <p className="text-main">{claim.items.length}</p>
          </div>
          <div className="d-flex align-items-center dropdown-icon-closed d-flex justify-content-end">
            {editIcon(buttonStatus)}
            <Icon name="chevron-down" size={20} color="ui-border" />
          </div>
        </div>
      </div>
      <div
        className="opened-row-wrapper flex-column mt-2 mb-4"
        style={{ display: open ? 'flex' : 'none' }}
      >
        <div className="source-data-wrapper pt-3 pb-4 pr-4 pl-4">
          <div className="d-flex mt-2">
            <div className="source-target-chain-data d-flex flex-column flex-grow-1">
              <div className="d-flex mb-2">
                <div className="d-flex mr-3">
                  <Icon name={claim?.sourceNetwork?.chainIcon} size={20} />
                </div>
                <span> {claim?.sourceNetwork?.chainName} </span>
              </div>
              <div className="d-flex">
                <div className="d-flex mr-3 align-items-center justify-content-center">
                  <Icon name={claim?.targetNetwork?.chainIcon} size={20} />
                </div>
                <span>{claim?.targetNetwork?.chainName}</span>
              </div>
            </div>
            <div className="dropdown-icon-opened d-flex flex-grow-1 justify-content-end">
              <Icon name="chevron-up" size={20} color="ui-border" />
            </div>
          </div>
          <div className="d-flex align-items-center amount-data-container justify-content-between mt-6 mb-3">
            <div className="d-flex justify-content-between"></div>
            <div className="d-flex flex-grow-1">
              <Icon name="staking-assets" size={20} className="mr-3" />
              <span>{t('nftClaimPage:header.nftToken')}</span>
              <p className="text-main ml-3">{claim.schema}</p>
            </div>
            <div className="d-flex align-items-center flex-row-reverse flex-grow-1">
              <>
                {nftImagesOpenRow}
                {nftOpenRowClaimNumber}
              </>
            </div>
          </div>
        </div>
        <div className="button-container pt-4 pb-4 px-4" ref={claimContainerButtonNode}>
          <div className="button-box d-flex justify-content-center">
            <NftClaimButton
              disabled={
                !claim.items[0].majorityReached || claim.failed != undefined ? !claim.failed : false
              }
              status={buttonStatus}
              entry={claim}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftListItemMobile;
