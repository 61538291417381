import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';

function ClearButton({ addedForTransfer, clearSelection }: ClearButton) {
  const { t } = useTranslation();

  return (
    <Button
      disabled={!addedForTransfer.length}
      type="alarm"
      className={`mb-4 mb-md-0 mr-md-2 order-last order-md-first ${'alarm'}`}
      onClick={() => clearSelection()}
    >
      <Icon name="staking-update" size={18} color="ui-border" />
      <span className="ml-2">{t('nftSelectionPage:buttons.clearButton')}</span>
    </Button>
  );
}

export default ClearButton;
