import { useEffect, useMemo, useState } from 'react';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { getDefaultAddress } from 'utils';

import { useGlobalContext } from './useGlobalContext';

enum OPERATION_TYPE {
  GET_NATIVE_TOKEN_INFO = 'GET_NATIVE_TOKEN_INFO',
  GET_NATIVE_COIN_INFO = 'GET_NATIVE_COIN_INFO',
  GET_EXISTENT_WRAPPED_TOKEN_INFO = 'GET_EXISTENT_WRAPPED_TOKEN_INFO',
  GET_NON_NATIVE_TOKEN_INFO = 'GET_NON_NATIVE_TOKEN_INFO',
  GET_NEW_WRAPPED_TOKEN_INFO = 'GET_NEW_WRAPPED_TOKEN_INFO',
}

type TokenDetails = {
  name?: string;
  symbol?: string;
  decimals?: number;
  address?: string;
  deployment?: null | undefined | boolean;
};

export const useTargetToken = () => {
  const { sdk, targetSDK, bridgeTransaction } = useGlobalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<TokenDetails>({
    name: undefined,
    symbol: undefined,
    decimals: undefined,
    address: undefined,
    deployment: null,
  });

  // Operation type is used to determine which function to call to get the symbol of the token to bridge
  const getOperationType = useMemo(() => {
    // Send source token to target chain i.e. BNB or BUSD from BSC
    const getNativeTokenInfo = async ({
      wrappedAddressForSourceToken,
    }: {
      wrappedAddressForSourceToken: string;
    }) => {
      if (targetSDK) {
        try {
          const token = await targetSDK
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetails(wrappedAddressForSourceToken);
          const tokenWithDeployment = {
            ...token,
            deployment: true,
          };
          return tokenWithDeployment;
        } catch (error) {
          console.error(error);
          return { deployment: undefined };
        }
      }
    };
    // Return wrapped native token to its native network i.e. WBNB to BSC
    const getNativeCoinInfo = async () => {
      // Get symbol of native token on target chain from the config file
      const token: TokenDetails = bridgeTransaction.network.target.nativeCurrency;
      token.deployment = true;
      return token;
    };
    // Return wrapped token to its native network i.e. wBUSD to BSC
    const getExistentWrappedTokenInfo = async ({
      contractAddress,
    }: {
      contractAddress: string;
    }) => {
      if (targetSDK) {
        try {
          const token: TokenDetails = await targetSDK
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetails(contractAddress);
          token.deployment = true;
          return token;
        } catch (error) {
          console.error(error);
        }
      }
    };
    // Bridge wrapped token to target chain i.e. wBUSD from ETH to AVALANCHE
    const getNonNativeTokenInfo = async ({
      tokenNativeChainId,
      contractAddress,
      defaultAddress,
    }: {
      tokenNativeChainId: number;
      contractAddress: string;
      defaultAddress: string;
    }) => {
      if (targetSDK) {
        // We asume that the token is not deployed in the destination chain
        // In this case the token details are the same as the token to bridge
        let token: TokenDetails = bridgeTransaction.token.details;

        const wrappedAddress = await targetSDK
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .getWrappedForNativeToken(tokenNativeChainId, contractAddress);
        // If the wrappedAddress is different to default address then the token is deployed in the destination chain
        const isDeployed = wrappedAddress !== defaultAddress;

        // This is to get the token details from the target chain if the token is deployed
        if (isDeployed) {
          token = await targetSDK
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetails(wrappedAddress);
        }

        token.deployment = isDeployed;

        return token;
      }
    };

    // Send new wrapped token to target chain i.e. wBUSD from BSC when wBUSD is not created yet
    const getNewWrappedTokenInfo = () => {
      return {
        symbol: `W${bridgeTransaction.token.details.symbol}`,
        name: `Wrapped ${bridgeTransaction.token.details.name}`,
        decimals: bridgeTransaction.token.details.decimals,
        deployment: false,
      };
    };

    return new Map<OPERATION_TYPE, Function>([
      [OPERATION_TYPE.GET_NATIVE_TOKEN_INFO, getNativeTokenInfo],
      [OPERATION_TYPE.GET_NATIVE_COIN_INFO, getNativeCoinInfo],
      [OPERATION_TYPE.GET_EXISTENT_WRAPPED_TOKEN_INFO, getExistentWrappedTokenInfo],
      [OPERATION_TYPE.GET_NON_NATIVE_TOKEN_INFO, getNonNativeTokenInfo],
      [OPERATION_TYPE.GET_NEW_WRAPPED_TOKEN_INFO, getNewWrappedTokenInfo],
    ]);
  }, [bridgeTransaction.network.target.nativeCurrency, bridgeTransaction.token.details, targetSDK]);

  useEffect(() => {
    const getTargetToken = async () => {
      const selectedTokenAddress = bridgeTransaction.token.address;
      const sourceChainId = bridgeTransaction.network.source.chainTargetId;
      const targetChainId = bridgeTransaction.network.target.chainTargetId;
      const walletType = bridgeTransaction.network.target.walletType;
      const defaultAddress = getDefaultAddress(walletType);

      if (sdk && targetSDK && selectedTokenAddress && sourceChainId) {
        setIsLoading(true);

        const [wrappedAddressForSourceToken, { chainId: tokenNativeChainId, contractAddress }] =
          await Promise.all([
            targetSDK
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .getWrappedForNativeToken(sourceChainId, selectedTokenAddress),
            sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .getNativeTokenByWrappedAddress(selectedTokenAddress),
          ]);

        const conditions = [
          {
            condition: wrappedAddressForSourceToken !== defaultAddress,
            operationType: OPERATION_TYPE.GET_NATIVE_TOKEN_INFO,
          },
          {
            condition: tokenNativeChainId === targetChainId && contractAddress === defaultAddress,
            operationType: OPERATION_TYPE.GET_NATIVE_COIN_INFO,
          },
          {
            condition: tokenNativeChainId === targetChainId,
            operationType: OPERATION_TYPE.GET_EXISTENT_WRAPPED_TOKEN_INFO,
          },
          {
            condition: tokenNativeChainId !== 0,
            operationType: OPERATION_TYPE.GET_NON_NATIVE_TOKEN_INFO,
          },
        ];

        const { operationType } = conditions.find(
          ({ condition, operationType: _operationType }) => condition,
        ) ?? {
          operationType: OPERATION_TYPE.GET_NEW_WRAPPED_TOKEN_INFO,
        };

        const opType = getOperationType.get(operationType);
        if (opType) {
          const token = await opType({
            wrappedAddressForSourceToken,
            contractAddress,
            tokenNativeChainId,
            defaultAddress,
          });
          if (token) setToken(token);
        }

        setIsLoading(false);
      }
    };
    // Reset token details
    setToken({
      name: undefined,
      symbol: undefined,
      decimals: undefined,
      address: undefined,
      deployment: null,
    });
    getTargetToken();
  }, [
    bridgeTransaction.network.source.chainTargetId,
    bridgeTransaction.network.target.chainTargetId,
    bridgeTransaction.network.target.walletType,
    bridgeTransaction.token.address,
    getOperationType,
    sdk,
    targetSDK,
  ]);

  return { isLoading, ...token };
};

export default useTargetToken;
