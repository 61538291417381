import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from 'utils';

import '../Tooltip/Tooltip.scss';

interface ClipboardTooltipProps {
  className?: string;
  children: string | JSX.Element | JSX.Element[];
  content: string;
  durationInMs?: number;
}

const ClipboardTooltip = ({
  children,
  content,
  className,
  durationInMs = 2000,
}: ClipboardTooltipProps) => {
  const [active, setActive] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOnClick = () => {
    setActive(true);
    copyToClipboard(content);
    setTimeout(() => {
      setActive(false);
    }, durationInMs);
  };

  return (
    <span
      data-tooltip={t('clipboardTooltip:text')}
      className={`tooltip clipboard ${active ? 'active' : ''} ${className ? className : ''}`}
      onClick={handleOnClick}
    >
      {children}
    </span>
  );
};

export default ClipboardTooltip;
