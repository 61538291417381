import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Tooltips';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';

function NftTitle() {
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag();

  return (
    <>
      <div className="title-nft-wrapper d-flex align-items-center">
        <div className="text-medium text-bold text-main mb-3 mr-3">
          {t('nftSelectionPage:title')}
        </div>
        <InfoTooltip
          text={t('nftSelectionPage:titleTooltip', {
            context: isEnabled('ERC1155') ? 'with_erc1155' : '',
          })}
          size={20}
        />
      </div>
      <div className="text-main">
        <Trans
          i18nKey="nftSelectionPage:description"
          components={{
            span: <span />,
          }}
          context={isEnabled('ERC1155') ? 'with_erc1155' : ''}
        />
      </div>
      <div className="text-main mt-4">
        <Trans i18nKey="nftSelectionPage:userCollectionHint" components={{ span: <span /> }} />
      </div>
    </>
  );
}

export default NftTitle;
