import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  LabelButton,
  StepButtons,
} from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { Modal, ModalButton } from 'components/Modals/Modal';
import { TransferConfirmation } from 'components/Modals/TransferConfirmation';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { NftTokenStatus } from 'utils';

interface NftStepButtonsProps {
  nftBridgeSteps: NFTBridgeSteps;
  nftTokensStatus: NftTokenStatus;
  updateNftBridgeSteps: (steps: NFTBridgeSteps) => void;
  updateActiveStep: (activeStep: 0 | 1) => void;
  clearBridgeTransaction: () => void;
  approveNFTsTokens: () => void;
  sendNFTToken: () => void;
  schema?: string;
}

const NftStepButtons = ({
  nftBridgeSteps,
  nftTokensStatus,
  updateNftBridgeSteps,
  updateActiveStep,
  clearBridgeTransaction,
  approveNFTsTokens,
  sendNFTToken,
  schema = '',
}: NftStepButtonsProps) => {
  const { nftBridgeTransaction } = useGlobalContext();
  const { t } = useTranslation();

  const renderStepperButton = () => {
    if (nftBridgeSteps.next) {
      return (
        <Button
          type="primary"
          className="ml-2 mb-4 mb-md-0 order-first order-md-last"
          disabled={
            !nftBridgeTransaction?.network?.target?.chainId ||
            !nftBridgeTransaction?.recipient ||
            nftTokensStatus === NftTokenStatus.Checking
          }
          onClick={() => {
            updateNftBridgeSteps({
              ...nftBridgeSteps,
              next: false,
              approve: nftTokensStatus === NftTokenStatus.Approve,
              transfer: nftTokensStatus === NftTokenStatus.Transfer,
            });
            updateActiveStep(1);
          }}
        >
          <span className="mr-2">{t('nftTransferPage:buttons.nextButton')}</span>
          <Icon name="arrow-right-long" size={18} color="ui-border" />
        </Button>
      );
    } else if (nftBridgeSteps.processing) {
      return (
        <LabelButton
          type="primary"
          loading={true}
          loadingText={t('nftTransferPage:stepReview.processingText')}
          label={
            nftBridgeSteps?.blockExplorer?.hash ? (
              <BlockExplorerBadge
                title={nftBridgeSteps?.blockExplorer?.name}
                hash={nftBridgeSteps?.blockExplorer?.hash as string}
                blockExplorer={nftBridgeSteps?.blockExplorer?.blockExplorerUrl as string}
                queryParams={nftBridgeSteps?.blockExplorer?.queryParams as string}
                type="tx"
              />
            ) : (
              <span className="text-center">
                {t('nftTransferPage:stepReview.waitingForConfirmationText')}
              </span>
            )
          }
        />
      );
    } else if (nftBridgeSteps.approve) {
      return (
        <Button
          type="primary"
          className="ml-2 mb-4 mb-md-0 order-first order-md-last"
          onClick={approveNFTsTokens}
        >
          <span className="mr-2">{t('nftTransferPage:buttons.approveButton')}</span>
          <Icon name="arrow-right-long" size={18} color="ui-border" />
        </Button>
      );
    } else if (nftBridgeSteps.transfer) {
      return (
        <Modal
          button={
            <ModalButton type="primary" className="ml-2 mb-4 mb-md-0 order-first order-md-last">
              <span className="mr-2">{t('nftTransferPage:buttons.transferButton')}</span>
              <Icon name="arrow-right-long" size={18} color="ui-border" />
            </ModalButton>
          }
          className="p-0 pb-4"
        >
          <TransferConfirmation
            title={t('nftTransferPage:nftTransferConfirmation.title')}
            subtitle={t('nftTransferPage:nftTransferConfirmation.subtitle', { schema })}
            confirmationTerms={t('nftTransferPage:nftTransferConfirmation.confirmationTerms')}
            onConfirmation={() => {
              sendNFTToken();
            }}
          />
        </Modal>
      );
    }
  };

  const renderResetButton = () => {
    if (nftBridgeSteps.next) {
      return (
        <Button
          type="alarm"
          className="mb-4 mb-md-0 mr-md-2 order-last order-md-first"
          onClick={clearBridgeTransaction}
        >
          <Icon name="renew" size={18} color="ui-border" />
          <span className="ml-2">{t('nftTransferPage:buttons.clearButton')}</span>
        </Button>
      );
    } else {
      return (
        <Button
          type="alarm"
          className={`mb-4 mb-md-0 mr-md-2 order-last order-md-first ${
            nftBridgeSteps.processing ? 'alarm-disabled' : ''
          }`}
          onClick={() => {
            if (!nftBridgeSteps.processing) {
              updateNftBridgeSteps({
                ...nftBridgeSteps,
                next: true,
                approve: false,
                transfer: false,
              });
              updateActiveStep(0);
            }
          }}
        >
          <Icon name="edit-clear" size={18} color="ui-border" />
          <span className="ml-2">{t('nftTransferPage:buttons.goBackButton')}</span>
        </Button>
      );
    }
  };

  return (
    <div className="mt-8">
      <StepButtons>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          {renderResetButton()}
          {renderStepperButton()}
        </div>
      </StepButtons>
    </div>
  );
};

export default NftStepButtons;
