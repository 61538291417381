import React from 'react';

import { useBreakpoint } from 'hooks/useBreakpoint';

import './NftCard.scss';

interface NftCardProps {
  item: OpenSeaMetadata;
  onlyImage?: boolean;
  className?: string;
}

const NftCard = ({ item, onlyImage, className }: NftCardProps) => {
  const { greaterThan } = useBreakpoint();
  const defaultNFT = '../images/default-nft.png';

  const shortenNftDescription = (description: string, offset: number): string => {
    return `${description?.substring(0, offset)} ...`;
  };

  return (
    <div className={`nft-card d-flex flex-column flex-md-row mt-3 mb-5 ${className ?? ''}`}>
      <div className="d-flex justify-content-center justify-content-md-start align-items-md-center mb-4 mb-md-0">
        <img
          className="nft-card-image"
          src={item.image_preview_url || defaultNFT}
          alt={item.image_preview_url}
        />
      </div>
      {!onlyImage && (
        <>
          <div className="d-none d-md-block line-vertical mx-4 my-4"></div>
          <div className="d-flex flex-column justify-content-center mx-4 mx-md-0">
            <p className={`text-main text-bold${greaterThan('md') ? '' : ' text-center'}`}>
              {item.name}
            </p>
            {item?.description && (
              <p className={`text-main${greaterThan('md') ? '' : ' text-center'}`}>
                {greaterThan('md') ? item.description : shortenNftDescription(item.description, 80)}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NftCard;
