import { env } from 'environments';

import { useGlobalContext } from './useGlobalContext';

export const useForceEnableBridgeOperation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const enableBridge = urlParams.get('enableBridge');

  const { bridgeOperationDisabled, setBridgeOperationDisabled } = useGlobalContext();

  const forceEnabled = env.FORCE_ENABLE_BRIDGE_OPERATION;
  const authIPs = forceEnabled?.split(',');

  const tryForceEnableBridgeOperation = async () => {
    const getUserIP = async () => {
      const response = await fetch(env.IP_API_URL);
      const data = await response.json();
      if (data?.ip) return data.ip;
      throw new Error('Unable to get user IP');
    };

    // Check if the user's IP is in the list of authorized IPs
    const checkIP = async () => {
      const userIP = await getUserIP();
      return authIPs?.includes('*') || authIPs?.includes(userIP);
    };

    // If the user's IP is in the list of authorized IPs, enable the bridge operation
    if (await checkIP()) setBridgeOperationDisabled(false);
  };
  if (enableBridge === 'true' && bridgeOperationDisabled) tryForceEnableBridgeOperation();
};

export default useForceEnableBridgeOperation;
