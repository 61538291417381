import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dropdown, Icon } from '@stichting-allianceblock-foundation/components';
import { SUPPORTED_WALLETS } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useDetectOutsideClick } from 'hooks/useDetectClickOutside';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import { getWalletByClientVersion } from 'utils/metamask';

import WalletIcon from './WalletIcon';

import './WalletButton.scss';

const NOTDETECTED = 'NotDetected';

const WalletButton = () => {
  const dropdownRef: MutableRefObject<null> = useRef(null);
  const [clientVersion, setClientVersion] = useState('');
  const { bridgeOperationDisabled } = useGlobalContext();
  const { isActive: expand, setIsActive: setExpand } = useDetectOutsideClick(dropdownRef, false);

  const { account, activate, deactivate, provider, isWalletInstalled } = useWallet();

  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  function handleDisconnect() {
    deactivate();
    setExpand(false);
  }

  async function handleConnect() {
    await activate();
    setExpand(!expand);
  }

  useEffect(() => {
    const v = getWalletByClientVersion();
    setClientVersion(v);
  }, []);

  function dropDownSelection(account: string) {
    if (account) {
      return (
        <div className="d-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan('md') ? account : ''}
            icon="wallet"
            onClick={() => setExpand(!expand)}
          >
            <div />
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
              <span className="text-bold d-flex align-items-center">
                <WalletIcon wallet={clientVersion} />
              </span>
              <Button type="secondary" onClick={() => handleDisconnect()} className="full-md-width">
                {t('walletButton:button.disconnect')}
              </Button>
            </div>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="d-md-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan('md') ? t('walletButton:dropdown.title') : ''}
            alarm
            icon="power-button"
            onClick={() => setExpand(!expand)}
            fullText={true}
          >
            <div className="d-flex align-items-center text-main text-bold">
              <Icon name="info-circle" size={24} color="ui-main" />
              <span className="ml-3">{t('walletButton:dropdown.title')}</span>
            </div>
            <div className="text-secondary py-3">
              <Trans i18nKey="walletButton:dropdown.subtitle" components={{ span: <span /> }} />
            </div>
            <div className="text-danger py-3">
              {!isWalletInstalled && (
                <Trans
                  i18nKey="walletButton:dropdown.invalidWallet"
                  components={{ span: <span /> }}
                />
              )}
            </div>
            <>
              {SUPPORTED_WALLETS.map((wallet, idx) => (
                <div
                  key={idx}
                  className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4"
                >
                  <span className="text-bold d-flex align-items-center">
                    <WalletIcon wallet={wallet} />
                  </span>
                  <Button
                    type="primary"
                    onClick={() => handleConnect()}
                    className="full-md-width"
                    disabled={
                      bridgeOperationDisabled ||
                      !isWalletInstalled ||
                      !clientVersion.toLocaleLowerCase().includes(wallet)
                    }
                  >
                    {
                      // FIXME: check wallet.connected with Solana
                      // @ts-ignore
                      provider?.wallet?.wallet?.readyState === NOTDETECTED
                        ? t('walletButton:button.install')
                        : t('walletButton:button.connect')
                    }
                  </Button>
                </div>
              ))}
            </>
          </Dropdown>
        </div>
      );
    }
  }

  return dropDownSelection(account!);
};

export default WalletButton;
