import React from 'react';

import { SkeletonBlock, SkeletonSquare } from '../Base';

import './SkeletonNftPreview.scss';

interface SkeletonNftPreviewProps {
  className?: string;
}

const SkeletonNftPreview = ({ className }: SkeletonNftPreviewProps) => {
  return (
    <div className={`mb-4 ${className ?? ''}`}>
      <SkeletonSquare className="mb-4" size={350} fullWidth rounded />
      <SkeletonBlock className="mb-4" size={2} type="large" />
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center width-100">
          <SkeletonSquare className="mr-3" size={20} />
          <SkeletonBlock size={1} />
        </div>
        <SkeletonBlock size={3} />
      </div>
    </div>
  );
};

export default SkeletonNftPreview;
