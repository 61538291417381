import { ERC20RouterSDK, TeleportSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { BigNumber, ethers } from 'ethers';

export const tokenSupportsErc20Permit = async (
  sdk: TeleportSDK,
  tokenAddress: string,
  owner: string,
) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    // FIXME: The SDK should take care of this
    // @ts-ignore
    sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).ERC20PermitAbi,
    sdk.signerOrProvider as ethers.providers.Provider,
  );
  try {
    await tokenContract.nonces(owner);
    return true;
  } catch {
    return false;
  }
};

export const signErc20EgressPermit = async (
  sdk: TeleportSDK,
  tokenAddress: string,
  owner: string,
  deadline: number,
  chainId: number,
  value: BigNumber,
) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    // FIXME: The SDK should take care of this
    // @ts-ignore
    sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).ERC20PermitAbi,
    sdk.signerOrProvider as ethers.providers.Provider,
  );

  const nonce = await tokenContract.nonces(owner);

  const Permit = [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ];

  const domain = {
    name: await tokenContract.name(),
    version: '1',
    chainId,
    verifyingContract: tokenContract.address,
  };

  const message = {
    owner: owner,
    // @ts-ignore
    spender: sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).erc20RouterContract.address,
    value,
    nonce,
    deadline,
  };

  // FIXME: signerOrProvider is not typed with _signTypedData
  // @ts-ignore
  const signature = await sdk.signerOrProvider._signTypedData(domain, { Permit }, message);

  return ethers.utils.splitSignature(signature);
};

export enum TransmissionActionOperation {
  BurnUnlock = 0,
  LockMint = 1,
  BurnMint = 1,
}
