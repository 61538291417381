import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Step, StepPanel } from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { NftCard } from 'components/Nft/NftCard';
import { InfoTooltip } from 'components/Tooltips';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { NftTokenStatus } from 'utils';

interface NftStepReviewProps {
  activeStep: number;
  txFeeEstimation: string;
  txFeeEstimationCurrency: string;
  nftTokensStatus: NftTokenStatus;
  bridgeFee: string;
  bridgeFeeCurrency: string;
  schema?: string;
}

const NftStepReview = ({
  activeStep,
  txFeeEstimation,
  txFeeEstimationCurrency,
  nftTokensStatus,
  bridgeFee,
  bridgeFeeCurrency,
  schema = '',
}: NftStepReviewProps) => {
  const { currentNetwork, nftBridgeTransaction, setNftBridgeTransaction } = useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setNftBridgeTransaction(prevNftBridgeTransaction => {
        return {
          ...prevNftBridgeTransaction,
          network: {
            source: {
              symbol: '',
              chainId: 0,
              chainName: '',
              chainIcon: '',
              chainTargetId: 0,
              nativeCurrency: {
                name: '',
                symbol: '',
                decimals: 0,
              },
              refreshClaimTimestamp: 0,
              rpcUrl: '',
              blockExplorerUrl: '',
              walletType: '',
            },
            target: {
              symbol: '',
              chainId: 0,
              chainName: '',
              chainIcon: '',
              chainTargetId: 0,
              nativeCurrency: {
                name: '',
                symbol: '',
                decimals: 0,
              },
              refreshClaimTimestamp: 0,
              rpcUrl: '',
              blockExplorerUrl: '',
              walletType: '',
            },
          },
        };
      });
    };
  }, [setNftBridgeTransaction]);

  return (
    <div>
      <Step
        activeStep={activeStep}
        stepNumber={1}
        title={t('nftTransferPage:stepReview.title')}
        subtitle={t('nftTransferPage:stepReview.subtitle')}
      >
        <StepPanel>
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
            <div className="width-100">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t('nftTransferPage:stepReview.tokensField.title', { schema })}
                </div>
                <InfoTooltip
                  text={t('nftTransferPage:stepReview.tokensField.tooltip', { schema })}
                  size={18}
                />
              </div>
              <div className="d-flex flex-wrap">
                {nftBridgeTransaction.nftTokens.map((nftItem, index) => (
                  <NftCard
                    item={nftItem as OpenSeaMetadata}
                    key={index}
                    onlyImage
                    className="mr-0 mr-md-4"
                  />
                ))}
              </div>
            </div>
            <div className="line mx-3 d-block d-md-none"></div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftTransferPage:stepReview.convertionDetailsField.title')}
              </div>
              <InfoTooltip
                text={t('nftTransferPage:stepReview.convertionDetailsField.tooltip')}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt-3">
              <div className="d-flex align-items-md-center">
                <Icon name="bridge-transfer" size={16} className="mr-2" />
                <div className="text-main">
                  {t('nftTransferPage:stepReview.convertionDetailsField.networksFieldText')}
                </div>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center">
                <Icon className="d-block d-md-none" name="arrow-target-down-long" size={32} />
                <div>
                  <div className="d-flex align-items-center my-2">
                    <Icon
                      name={
                        nftBridgeTransaction?.network?.source?.chainIcon
                          ? nftBridgeTransaction.network.source.chainIcon
                          : 'blank'
                      }
                      size={16}
                      className="mr-2"
                    />
                    <span className="text-main text-bold">
                      {nftBridgeTransaction?.network?.source?.chainName}
                    </span>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <Icon
                      name={
                        nftBridgeTransaction?.network?.target?.chainIcon
                          ? nftBridgeTransaction.network.target.chainIcon
                          : 'blank'
                      }
                      size={16}
                      className="mr-2"
                    />
                    <span className="text-main text-bold">
                      {nftBridgeTransaction?.network?.target?.chainName}
                    </span>
                  </div>
                </div>
                <Icon className="d-none d-md-block" name="arrow-target-left-long" size={32} />
              </div>
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftTransferPage:stepReview.recipientField.title')}
              </div>
              <InfoTooltip
                text={t('nftTransferPage:stepReview.recipientField.tooltip')}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="wallet" size={16} color="ui-main" className="mr-2" />
                <span className="text-main">
                  {t('nftTransferPage:stepReview.recipientField.subtitle')}
                </span>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center mt-2 mt-sm-0">
                <BlockExplorerBadge
                  hash={nftBridgeTransaction.recipient}
                  walletType={nftBridgeTransaction?.network?.target?.walletType}
                  blockExplorer={nftBridgeTransaction?.network?.target?.blockExplorerUrl}
                  queryParams={nftBridgeTransaction?.network?.target?.blockExplorerQueryParams}
                  type="address"
                  shorten
                />
              </div>
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftTransferPage:stepReview.transactionFeesField.title')}
              </div>
              <InfoTooltip
                text={t('nftTransferPage:stepReview.transactionFeesField.tooltip')}
                size={18}
              />
            </div>{' '}
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="pass-through" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesText')}
                  </span>
                </div>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
              <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
                <span className="text-main text-bold mr-4">{bridgeFee}</span>
                <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
                <div className="d-flex flex-column flex-md-row">
                  <div className="d-flex">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">{bridgeFeeCurrency}</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('nftTransferPage:stepReview.transactionFeesField.estimationFeesText')}
                  </span>
                  <span className="text-main text-bold ml-2">
                    {nftTokensStatus !== NftTokenStatus.Checking &&
                      (nftTokensStatus === NftTokenStatus.Approve
                        ? `[${t('nftTransferPage:buttons.approveButton')}]`
                        : `[${t('nftTransferPage:buttons.transferButton')}]`)}
                  </span>
                </div>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                <span className="text-main text-bold mr-2 mr-sm-4">
                  {nftBridgeTransaction?.network?.target ? txFeeEstimation : ''}
                </span>
                <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex mt-3 mt-md-0">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">
                    {nftBridgeTransaction?.network?.target ? txFeeEstimationCurrency : ''}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </StepPanel>
      </Step>
    </div>
  );
};

export default NftStepReview;
