import React from 'react';

import './base.scss';

interface SkeletonQuareProps {
  size?: number;
  height?: number;
  width?: number;
  fullWidth?: boolean;
  rounded?: boolean;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const SkeletonQuare = ({
  className,
  size,
  height,
  width,
  fullWidth,
  rounded,
  children,
}: SkeletonQuareProps) => {
  return (
    <div
      className={`skeleton ${rounded ? 'rounded' : ''} ${className ?? ''}`}
      style={{
        width: `${fullWidth ? '100%' : `${width ?? size}px`}`,
        height: `${height ?? size}px`,
      }}
    >
      {children}
    </div>
  );
};

export default SkeletonQuare;
