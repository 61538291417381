import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { utils } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Icon } from '@stichting-allianceblock-foundation/components';

import { NftClaimButton } from '../../NftClaimButton';

import './NftListItem.scss';

type NftListItemProps = RouteComponentProps & {
  claim: FormattedNftClaims;
};

const NftListItem: React.FC<NftListItemProps> = ({ claim }: { claim: FormattedNftClaims }) => {
  const { t } = useTranslation();
  const defaultNFT = '../images/default-nft.png';

  const sessionButtonStatus = sessionStorage
    .getItem('nft-claimed-claims')
    ?.includes(claim.items[0].transmissionId)
    ? t('nftClaimPage:buttonStatus.claimed')
    : claim.delivered[0]
    ? t('nftClaimPage:buttonStatus.claimed')
    : t('nftClaimPage:buttonStatus.notClaimed');
  const isClaimPreviewLinkActive = sessionButtonStatus === t('nftClaimPage:buttonStatus.claimed');

  return (
    <div className="d-flex justify-content-between align-items-center mt-5 mb-2 mx-4">
      <Link
        style={{
          textDecoration: 'none',
          pointerEvents: isClaimPreviewLinkActive ? 'auto' : 'none',
        }}
        to={
          isClaimPreviewLinkActive
            ? {
                pathname: `/bridge-nft/claim/preview/${claim?.items[0]?.transmissionId}`,
                state: { claim },
              }
            : '#'
        }
        className="flex-nft-images d-flex align-items-center mr-2"
      >
        <div className="flex-nft-images d-flex align-items-center mr-2">
          {claim.items?.map((item, index) =>
            index < 2 ? (
              <div className="d-flex" key={index}>
                <img
                  className="nft-card-listing-image mr-4"
                  src={utils.uriToHttp(item?.image) || defaultNFT}
                />
                {claim.items.length < 2 && (
                  <div className="nft-dsc-wrapper">
                    <p className="text-main text-bold">
                      {item.name || `${item.collectionName}#${item.tokenId}`}
                    </p>
                    {item?.description && <p className="nft-dsc">{item.description}</p>}
                  </div>
                )}
              </div>
            ) : null,
          )}
          {claim.items.length > 2 ? (
            <div className="text-main text-bold">+{claim.items.length - 2}</div>
          ) : null}
        </div>
      </Link>
      <div className="d-flex align-items-center schema-nft">
        <p className="text-main ml-3">{claim.schema}</p>
      </div>
      <div className="networks-nft d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Icon name={claim.sourceNetwork.chainIcon} size={20} />
          <p className="text-main ml-3">{claim.sourceNetwork.chainName}</p>
        </div>
        <div className="d-flex target-network-column">
          <Icon className="mx-3" name="arrow-right-long" color="brand-secondary" size={18} />
          <div className="d-flex align-items-center">
            <Icon name={claim.targetNetwork.chainIcon} size={20} />
            <p className="text-main ml-3">{claim.targetNetwork.chainName}</p>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <p className="text-main">{claim.items?.length}</p>
      </div>
      <div className="nft-button d-flex flex-row-reverse ml-3">
        <NftClaimButton
          disabled={
            !claim.items[0].majorityReached ||
            (claim.items[0].transmissionType === 'ccip' && !claim.failed)
          }
          status={sessionButtonStatus}
          entry={claim}
        />
      </div>
    </div>
  );
};

export default withRouter(NftListItem);
