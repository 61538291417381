import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalContext } from 'hooks/useGlobalContext';
import { addAsset, getProvider } from 'utils/metamask';

import { CloseModalIcon, LinkIcon, SuccessIcon } from '../Icons';
import ModalClaim from './ModalClaim';
import OutherLink from './OutherLink';

// This modal is used to claim NFTs
// TODO unify it with ClaimSuccess.tsx
export const ModalClaimSuccess = () => {
  const { claimModal, setClaimModal } = useGlobalContext();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (claimModal.status) {
      setShowModal(true);
    }
  }, [claimModal.status]);

  const { recipient, transactionLink, tokenAmount, tokenName, tokenType, tokenOptions } =
    claimModal.claimData;

  async function addBridgedTokenToWallet(
    tokenType: MetamaskAssetParamsType,
    tokenOptions: MetamaskAssetParamsOptions,
    provider: WindowProvider,
  ) {
    try {
      await addAsset(tokenType, tokenOptions, provider);
    } catch (err) {
      console.error('An error occurred while trying to add the token.');
    }
  }

  const closeModal = () => {
    setClaimModal(prevState => {
      return { ...prevState, status: false };
    });
    setShowModal(false);
  };
  return (
    <div>
      <ModalClaim onClose={closeModal} show={showModal}>
        <div className="modal-success">
          <div className="close cursor-modifier" onClick={closeModal}>
            <CloseModalIcon color={'white'} />
          </div>
          <div className="heading d-flex flex-column align-items-center">
            <SuccessIcon />
            <h2>Tokens Claimed!</h2>
          </div>
          <div className="token-data d-flex align-items-center">
            <div className="total">
              <div className="label">Amount</div>
              <div className="value">{tokenAmount}</div>
            </div>
            <div className="token">
              <div className="label">Token</div>
              <div className="value">{tokenName}</div>
            </div>
          </div>
          <div className="recepient">
            <div className="label"></div>
            <div className="value">
              <a
                className="link"
                onClick={() => addBridgedTokenToWallet(tokenType!, tokenOptions!, getProvider())}
              >
                {
                  // TODO check for Phantom wallet like in ClaimSuccess.tsx
                  t('claimPage:successModal.addTokenToWallet')
                }{' '}
                <LinkIcon />
              </a>
            </div>
          </div>
          <div className="recepient">
            <div className="label">Recipient Wallet Address</div>
            <div className="value">{recipient}</div>
          </div>
          <div className="transaction-url">
            <div className="label">Transaction</div>
            <div className="value">
              <OutherLink link={transactionLink ?? ''} title={'Check transaction here'} />
            </div>
          </div>
        </div>
      </ModalClaim>
    </div>
  );
};

export default ModalClaimSuccess;
