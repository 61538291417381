import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { Portal } from 'components/Portal';
import { ModalContext } from 'context/ModalContext';
import { useDetectOutsideClick } from 'hooks/useDetectClickOutside';

import './Modal.scss';

const Modal = ({
  title,
  subtitle,
  button,
  children,
  triggered = false,
  className,
  onClose,
}: ModalProps) => {
  const modalRef: MutableRefObject<null | HTMLDivElement> = useRef(null);
  const { setIsActive } = useDetectOutsideClick(modalRef, true, () => {
    closeModal('User action');
  });

  useEffect(() => {
    return () => setIsActive(false);
  }, [setIsActive]);

  const [open, setOpen] = useState(false);
  const [processingModal, setProcessingModal] = useState(false);

  const closeModal = useCallback(
    (reason?: CloseReason) => {
      onClose?.(reason);
    },
    [onClose],
  );

  useEffect(() => {
    setOpen(triggered);
  }, [setOpen, triggered]);

  return (
    <ModalContext.Provider value={{ open, setOpen, closeModal, setProcessingModal }}>
      {button}
      <Portal>
        {open && (
          <div className={`modal modal-backdrop${open ? ' show' : ''}`}>
            <div ref={modalRef} className={`modal-dialog ${className ?? ''}`}>
              <div>
                {title && (
                  <div className="title-container">
                    <h2>{title}</h2>
                    <Icon
                      name="nav-cross"
                      size={20}
                      color="ui-main"
                      onClick={() => {
                        if (!processingModal) {
                          setOpen(false);
                        }
                      }}
                    />
                  </div>
                )}

                {subtitle && (
                  <div className="subtitle-container">
                    <p>{subtitle}</p>
                  </div>
                )}

                <div className="content-container">{children}</div>
              </div>
            </div>
          </div>
        )}
      </Portal>
    </ModalContext.Provider>
  );
};

export default Modal;
