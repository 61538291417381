import React from 'react';

import { LinkIcon } from '../Icons';

function OutherLink({ link, title }: { link: string; title: string }) {
  return (
    <a target="_blank" href={link}>
      {title} <LinkIcon />
    </a>
  );
}

export default OutherLink;
