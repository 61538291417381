import React from 'react';

import { breakpoints } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';

import { SkeletonBlock, SkeletonCircle, SkeletonSquare } from '../Base';

import './SkeletonNftSelectCard.scss';

interface SkeletonNftSelectCardProps {
  rows: number;
  className?: string;
}

const SkeletonNftSelectCard = ({ className, rows }: SkeletonNftSelectCardProps) => {
  const { greaterThan, width } = useBreakpoint();

  const getNftAmountPerBreakPoint = (width: number) => {
    if (width > 0 && width <= breakpoints.sm) {
      return 2;
    } else if (width > breakpoints.sm && width <= breakpoints.xl) {
      return 3;
    } else if (width > breakpoints.xl) {
      return 5;
    }
  };

  const renderNftListSkeleton = () => {
    return Array.from(Array(getNftAmountPerBreakPoint(width)).keys()).map((_, index: number) => (
      <div className="mx-2" key={index}>
        <SkeletonSquare className="skeleton-nft-card mb-4" fullWidth height={190} rounded>
          <SkeletonCircle className="skeleton-nft-icon" size={46} />
          <div className="skeleton-nft-image"></div>
          <div className="px-3 py-4">
            <SkeletonBlock size={greaterThan('md') ? 2 : 4} />
            <div className="d-flex align-items-center mt-2">
              <SkeletonSquare className="mr-3" size={20} />
              <SkeletonBlock size={greaterThan('md') ? 1 : 2} />
            </div>
          </div>
        </SkeletonSquare>
      </div>
    ));
  };

  return (
    <div className={`skeleton-nft-list mb-4 mt-3 ${className ?? ''}`}>
      {Array.from(Array(rows).keys()).map(() => renderNftListSkeleton())}
    </div>
  );
};

export default SkeletonNftSelectCard;
