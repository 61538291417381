import { createContext } from 'react';

export const ModalContext = createContext<ModalContext>({
  open: null,
  setOpen: () => {
    throw new Error('setOpen was not initialized');
  },
  closeModal: (_reason: CloseReason) => {
    throw new Error('closeModal was not initialized');
  },
  setProcessingModal: null,
});
