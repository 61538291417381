import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { NftDetailsCard } from 'components/Nft/NftDetailsCard';
import { InfoTooltip } from 'components/Tooltips';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';

import './NftClaimedPreview.scss';

function NftClaimedPreview(props: { location: { state: { claim: FormattedNftClaims } } }) {
  const nftClaim = props.location.state.claim as FormattedNftClaims;
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag();
  const { greaterThan } = useBreakpoint();
  const history = useHistory();

  return (
    <div className="mt-7 mb-5 my-md-7">
      <div className="data-wrapper">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mb-3">
              <Icon
                name="arrow-left-long"
                size={32}
                color="brand-primary"
                className="mr-4 d-none d-md-block"
                onClick={history.goBack}
              />
              <div className="go-back-line-vertical mr-md-2 d-none d-md-block"></div>
              <div className="text-medium text-bold text-main mr-3 mr-md-3 mx-0 mx-md-3">
                Claimed NFTs List
              </div>
            </div>
          </div>
          <Icon
            name="cross-big"
            size={24}
            color="ui-main"
            className="d-flex justify-content-end d-md-none mb-3"
            onClick={history.goBack}
          />
        </div>
        <p className="text-main"></p>
      </div>

      <div className="nft-image-container">
        <div className="nft-image-wrap mb-6 py-6 px-4 flex-column d-flex text-align-center flex-grow-1">
          <div className="d-flex align-items-center">
            <div className="text-small text-bold text-secondary mb-3 mr-3">Claimed NFTs</div>
            <InfoTooltip
              text={t('nftClaimDetails:nftTokensField.tooltip', {
                context: isEnabled('ERC1155') ? 'with_erc1155' : '',
              })}
              size={18}
            />
          </div>
          <div className="d-flex flex-wrap">
            {nftClaim?.items?.map((nftClaim_: NftClaims, index: number) => (
              <NftDetailsCard
                className="mr-0 mr-md-4"
                item={nftClaim_}
                key={index}
                onlyImage={nftClaim.items?.length > 1 && greaterThan('md')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NftClaimedPreview;
