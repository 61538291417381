import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as NftTransferEmptyDesktop } from 'assets/nft-transfer-empty-desktop.svg';
import { ReactComponent as NftTransferEmptyMobile } from 'assets/nft-transfer-empty-mobile.svg';
import Pagination from 'components/Pagination/Pagination';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { shortenHash } from 'utils';

import './NftTableContainer.scss';

function NftTableContainer({ addForTransfer, addedForTransfer }: NftTableContainer) {
  const { isEnabled } = useFeatureFlag();

  const { userAssets, currentNetwork, collectionCriteria, nftTransferPage, setNftTransferPage } =
    useGlobalContext();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  const PageSize = 20;

  const defaultNFT = '../images/default-nft.png';

  const allCollectionTransfers = collectionCriteria?.owned_asset_count ?? 0;

  const currentSlicedData = useMemo(() => {
    const firstPageIndex = (nftTransferPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return userAssets?.slice(firstPageIndex, lastPageIndex);
  }, [nftTransferPage, userAssets]);

  const currentTransferData = allCollectionTransfers ? userAssets : currentSlicedData;

  const listNfts = currentTransferData?.map((asset, index) => {
    return (
      <div
        className={`card ml-2 mr-2 mt-3 mb-3${
          addedForTransfer.some(asset_ => asset.token_id === asset_.token_id) ? ' active' : ''
        }`}
        key={index}
      >
        <div className="icon-container">
          <Icon
            className={
              !addedForTransfer.some(asset_ => asset.token_id === asset_.token_id)
                ? 'select-icon'
                : 'selected-icon'
            }
            name={
              !addedForTransfer.some(asset_ => asset.token_id === asset_.token_id)
                ? 'edit-plus'
                : 'check'
            }
            size={24}
            onClick={() => addForTransfer(asset)}
          />
        </div>
        <Link
          to={{
            pathname: `/bridge-nft/preview/${asset.asset_contract?.address}/${
              asset.token_id ?? ''
            }`,
            state: { asset },
          }}
        >
          <img className="nft-image" src={asset.image_preview_url || defaultNFT} alt="Avatar" />
        </Link>
        <div className="container px-3 py-4">
          {!asset.image_preview_url && (
            <div className="id-container d-flex align-items-center ml-2 pb-2">
              <p className="warn pt-1 pb-1 pl-3 pr-3">
                {t('nftSelectionPage:warnNftImageNotWorking')}
              </p>
            </div>
          )}
          <div className="asset-name pl-3 pt-1">{asset.name}</div>
          <div className="id-container d-flex align-items-center ml-2 pb-2">
            <p className="id pt-1 pb-1 pl-3 pr-3">ID</p>
            <p className="text-small ml-2">
              {asset?.token_id?.length < 18 ? asset.token_id : shortenHash(asset.token_id, 9)}
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="wrapper">
      <div className="nft-list mb-5">{listNfts}</div>
      {allCollectionTransfers >= PageSize || userAssets.length >= PageSize ? (
        <Pagination
          className="d-flex justify-content-center align-items-center v"
          pageSize={PageSize}
          currentPage={nftTransferPage}
          totalCount={allCollectionTransfers ? allCollectionTransfers : userAssets.length}
          onPageChange={(page: number) => {
            setNftTransferPage(page);
          }}
        ></Pagination>
      ) : null}
      {!listNfts.length && (
        <div>
          <div className="d-flex justify-content-center">
            {greaterThan('md') ? (
              <NftTransferEmptyDesktop className="max-width-100 height-100" />
            ) : (
              <NftTransferEmptyMobile className="max-width-100 height-100" />
            )}
          </div>
          <div className="mt-4 mb-5">
            <div className="text-medium text-bold text-main text-center mb-3">
              {t('nftSelectionPage:tokensContainer.table.emptyTokens.title')}
            </div>
            <div className="text-main text-center">
              <Trans
                i18nKey="nftSelectionPage:tokensContainer.table.emptyTokens.subtitle"
                components={{ span: <span /> }}
                context={isEnabled('ERC1155') ? 'with_erc1155' : ''}
                values={{
                  chainName: currentNetwork?.chainName,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NftTableContainer;
