import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ERC721RouterSDK, ERC1155RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Icon, Step, StepPanel, TextField } from '@stichting-allianceblock-foundation/components';
import { NftCard } from 'components/Nft/NftCard';
import { Select } from 'components/Select';
import { InfoTooltip } from 'components/Tooltips';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { fromWei, getModuleBySchemaName, hexlifyAddress, isAddress, NftTokenStatus } from 'utils';
import { getNetworkIndex } from 'utils/network';

interface NftStepDetailsProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  txFeeEstimation: string;
  txFeeEstimationCurrency: string;
  nftTokensStatus: NftTokenStatus;
  bridgeFee: string;
  bridgeFeeCurrency: string;
  schema?: string;
}

const NftStepDetails = ({
  activeStep,
  wasTransactionCleared,
  txFeeEstimation,
  txFeeEstimationCurrency,
  nftTokensStatus,
  bridgeFee,
  bridgeFeeCurrency,
  schema = '',
}: NftStepDetailsProps) => {
  const {
    sdk,
    currentNetwork,
    collectionCriteria,
    networkOptions,
    nftBridgeTransaction,
    setNftBridgeTransaction,
  } = useGlobalContext();
  const { t } = useTranslation();
  const [targetNetworkIndex, setTargetNetworkIndex] = useState(0);
  const [providerFee, setProviderFee] = useState(0);
  const [supportedChains, setSupportedChains] = useState<Network[]>([]);
  const [loadingSupportedChains, setLoadingSupportedChains] = useState<boolean>(false);

  const onChangeRecipient = (recipient: string) => {
    setNftBridgeTransaction({
      ...nftBridgeTransaction,
      recipient: recipient,
    });
  };

  const onChangeTargetNetwork = (targetNetwork: Network) => {
    setTargetNetworkIndex(getNetworkIndex(targetNetwork.chainId, supportedChains));
    setNftBridgeTransaction({
      ...nftBridgeTransaction,
      network: {
        source: nftBridgeTransaction.network.source,
        target: targetNetwork,
      },
    });
  };

  useEffect(() => {
    const loadTransactionNetworkSource = () => {
      setNftBridgeTransaction(prevNftBridgeTransaction => {
        return {
          ...prevNftBridgeTransaction,
          network: {
            source: currentNetwork,
            target: prevNftBridgeTransaction.network.target,
          },
        };
      });
      setTargetNetworkIndex(0);
    };

    loadTransactionNetworkSource();
  }, [currentNetwork, setNftBridgeTransaction]);

  useEffect(() => {
    const forceLoadTransactionNetworkSource = () => {
      if (
        !nftBridgeTransaction?.network?.source?.chainId ||
        nftBridgeTransaction?.network?.source?.chainId !== currentNetwork?.chainId
      ) {
        setNftBridgeTransaction(prevNftBridgeTransaction => {
          return {
            ...prevNftBridgeTransaction,
            network: {
              source: currentNetwork,
              target: prevNftBridgeTransaction.network.target,
            },
          };
        });
      }
    };

    forceLoadTransactionNetworkSource();
  }, [currentNetwork, nftBridgeTransaction, setNftBridgeTransaction]);

  useEffect(() => {
    const getFee = async () => {
      if (
        !(
          sdk &&
          nftBridgeTransaction.network.target.chainTargetId &&
          nftBridgeTransaction.nftTokens
        )
      ) {
        setProviderFee(0);
        return;
      }
      type RouterSDKType = typeof ERC721RouterSDK | typeof ERC1155RouterSDK;
      const module = getModuleBySchemaName(collectionCriteria?.schema);
      const recipient = hexlifyAddress(
        nftBridgeTransaction.recipient,
        nftBridgeTransaction.network.target.walletType,
      );

      try {
        const ccipFee = await sdk
          .dapp<InstanceType<RouterSDKType>>(module?.DAPP_NAME)
          .getProviderFee({
            targetChainId: nftBridgeTransaction.network.target.chainTargetId,
            assets: nftBridgeTransaction.nftTokens,
            userAddress: recipient,
          });

        const formattedFee = fromWei(ccipFee, currentNetwork.nativeCurrency.decimals);

        setProviderFee(Number(formattedFee));
      } catch (error) {
        console.error('Error getting provider fee', error);
        setProviderFee(0);
      }
    };

    getFee();
  }, [
    collectionCriteria?.schema,
    currentNetwork.nativeCurrency.decimals,
    nftBridgeTransaction.network.target.chainTargetId,
    nftBridgeTransaction.network.target.walletType,
    nftBridgeTransaction.nftTokens,
    nftBridgeTransaction.recipient,
    sdk,
  ]);

  useEffect(() => {
    const updateSupportedChains = async () => {
      if (sdk) {
        setLoadingSupportedChains(true);
        const supportedChainsIds = await sdk.getProviderSupportedChains();
        const supportedChains = networkOptions.filter((network: Network) => {
          return supportedChainsIds.includes(network.chainTargetId);
        });

        setSupportedChains(supportedChains);
        setLoadingSupportedChains(false);
      }
    };
    updateSupportedChains();
  }, [networkOptions, sdk]);

  return (
    <div className={activeStep !== 0 ? ' mb-5' : ''}>
      <Step
        activeStep={activeStep}
        stepNumber={0}
        title={t('nftTransferPage:stepDetails.title')}
        subtitle={t('nftTransferPage:stepDetails.subtitle')}
      >
        <StepPanel>
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
            <div className="width-100">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t('nftTransferPage:stepDetails.tokensField.title', { schema })}
                </div>
                <InfoTooltip
                  text={t('nftTransferPage:stepDetails.tokensField.tooltip', { schema })}
                  size={18}
                />
              </div>
              {nftBridgeTransaction.nftTokens.map((nftItem, index) => (
                <NftCard item={nftItem as OpenSeaMetadata} key={index} />
              ))}
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between pb-3">
            <div className="width-100">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t('nftTransferPage:stepDetails.networkField.sourceNetwork.title', { schema })}
                </div>
                <InfoTooltip
                  text={t('nftTransferPage:stepDetails.networkField.sourceNetwork.tooltip', {
                    schema,
                  })}
                  size={18}
                />
              </div>
              <Select
                optionsList={[currentNetwork]}
                displayFields={{
                  primary: 'chainName',
                  secondary: '',
                  icon: 'chainIcon',
                }}
                size="sm"
                selected={0}
                disabled={true}
              />
            </div>
            <div className="d-flex justify-content-center align-items-lg-end mt-6 mb-4 mt-lg-0 mb-lg-3 mx-lg-5">
              <Icon
                className="rotate-90 rotate-lg-0"
                name="bridge-token-transfer"
                size={24}
                color="ui-main"
              />
            </div>
            <div className="width-100 z-index-7">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t('nftTransferPage:stepDetails.networkField.targetNetwork.title', { schema })}
                </div>
                <InfoTooltip
                  text={t('nftTransferPage:stepDetails.networkField.targetNetwork.tooltip', {
                    schema,
                  })}
                  size={18}
                />
              </div>
              {loadingSupportedChains ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '45px',
                  }}
                >
                  <Icon name="spinner" size={24} />
                </div>
              ) : (
                <Select
                  className="target-network-dropdown-list"
                  optionsList={supportedChains}
                  displayFields={{
                    primary: 'chainName',
                    secondary: '',
                    icon: 'chainIcon',
                  }}
                  size="sm"
                  selected={targetNetworkIndex}
                  placeholder={{
                    text: t(
                      'nftTransferPage:stepDetails.networkField.targetNetwork.placeholderText',
                    ),
                    icon: 'network',
                    reset: wasTransactionCleared,
                    active:
                      nftBridgeTransaction?.network?.source?.chainId === currentNetwork?.chainId,
                  }}
                  onSelectOptionChange={onChangeTargetNetwork}
                />
              )}
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftTransferPage:stepDetails.recipientField.title')}
              </div>
              <InfoTooltip
                text={t('nftTransferPage:stepDetails.recipientField.tooltip')}
                size={18}
              />
            </div>
            <TextField
              icon={'wallet'}
              label={
                nftBridgeTransaction?.recipient
                  ? ''
                  : t('nftTransferPage:stepDetails.recipientField.title')
              }
              value={nftBridgeTransaction?.recipient}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeRecipient(e.target.value)
              }
            />
            {nftBridgeTransaction?.network?.target?.walletType &&
              !isAddress(
                nftBridgeTransaction?.recipient,
                nftBridgeTransaction?.network?.target?.walletType,
              ) && (
                <p className="text-danger">
                  {t('nftTransferPage:stepDetails.recipientField.invalidAddressText')}
                </p>
              )}
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftTransferPage:stepDetails.transactionFeesField.title')}
              </div>
              <InfoTooltip
                text={t('nftTransferPage:stepDetails.transactionFeesField.tooltip')}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="pass-through" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesText')}
                  </span>
                </div>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
              <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
                <span className="text-main text-bold mr-4">{bridgeFee}</span>
                <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
                <div className="d-flex flex-column flex-md-row">
                  <div className="d-flex">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">{bridgeFeeCurrency}</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('nftTransferPage:stepDetails.transactionFeesField.estimationFeesText')}
                    <span className="text-main text-bold ml-2">
                      {nftTokensStatus !== NftTokenStatus.Checking &&
                        (nftTokensStatus === NftTokenStatus.Approve
                          ? `[${t('nftTransferPage:buttons.approveButton')}]`
                          : `[${t('nftTransferPage:buttons.transferButton')}]`)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
              <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
                <span className="text-main text-bold mr-4">{txFeeEstimation}</span>
                <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
                <div className="d-flex flex-column flex-md-row">
                  <div className="d-flex">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">{txFeeEstimationCurrency}</span>
                </div>
              </div>
            </div>
            {providerFee.toString() !== '0' && (
              <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
                <div className="d-flex align-items-center">
                  <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                  {/* TODO: this should be dynamic based on the selected provider */}
                  <span className="text-main">CCIP provider fee</span>
                </div>
                <div className="d-none d-sm-flex line flex-one mx-3"></div>
                <div className="d-flex align-items-center">
                  <span className="text-main text-bold mr-2 mr-sm-4">{providerFee.toString()}</span>
                  <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="d-flex mt-3 mt-md-0">
                      <Icon
                        name={
                          nftBridgeTransaction?.network?.source?.chainIcon
                            ? nftBridgeTransaction?.network?.source?.chainIcon
                            : 'blank'
                        }
                        size={18}
                        className="mr-2"
                      />
                      <span className="text-uppercase text-secondary">
                        {nftBridgeTransaction?.network?.source?.nativeCurrency?.symbol}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </StepPanel>
      </Step>
    </div>
  );
};

export default NftStepDetails;
