import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@stichting-allianceblock-foundation/components';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltips';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';

import NftClaimFilterDropdown from './NftClaimFilterDropdown';

interface NftClaimFilterProps {
  claimsAccount: string | undefined;
  setClaimsAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function NftClaimFilter({ claimsAccount, setClaimsAccount }: NftClaimFilterProps) {
  const { currentNetwork, networkOptions, setFilterCriteria, sdk } = useGlobalContext();

  const { t } = useTranslation();

  const [nftClaimFilterIndex, setNftClaimFilterIndex] = useState(0);
  const [nftClaimFilterNetworkIndex, setNftClaimFilterNetworkIndex] = useState(0);

  const { isEnabled } = useFeatureFlag();

  const allNetworks: Network = {
    ...({} as Network),
    chainName: t('claimPage:filterOptions.network'),
    chainIcon: 'check',
    nativeCurrency: {
      name: '',
      symbol: t('claimPage:filterOptions.network'),
      decimals: 0,
    },
  };

  const FILTER_SELECT_CONSTANTS: (FilterStatus & { index: number })[] = [
    {
      name: t('nftClaimPage:filterOptions.constants.showAll'),
      icon: 'check',
      index: 0,
    },
    {
      name: t('nftClaimPage:filterOptions.constants.claimed'),
      icon: 'check',
      index: 1,
    },
    {
      name: t('nftClaimPage:filterOptions.constants.notClaimed'),
      icon: 'check',
      index: 2,
    },
  ];

  useEffect(() => {
    const defaultFilterOption: FilterCriteria = {
      network: t('nftClaimPage:filterOptions.network'),
      status: t('nftClaimPage:filterOptions.constants.showAll'),
    };

    setNftClaimFilterIndex(0);
    setNftClaimFilterNetworkIndex(0);
    setFilterCriteria(defaultFilterOption);
  }, [setFilterCriteria, t]);

  return (
    <div className="nft-claimed-option-wrapper mt-4 mb-4 width-sm-100 width-md-auto">
      {isEnabled('claim_on_behalf') && (
        <Tooltip className=" width-100" text={t('claimPage:filterOptions.claimOnBehalfOfTooltip')}>
          <TextField
            className=" width-100"
            icon="social-user"
            value={claimsAccount}
            error={
              (claimsAccount && sdk?.utils.isAddress(claimsAccount)) ||
              !claimsAccount ||
              claimsAccount === ''
                ? ''
                : t('claimPage:filterOptions.claimOnBehalfOfError')
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setClaimsAccount(
                event.target.value === '' || !sdk?.utils.isAddress(event.target.value)
                  ? undefined
                  : event.target.value,
              )
            }
            placeholder={t('claimPage:filterOptions.claimOnBehalfOf')}
          ></TextField>
        </Tooltip>
      )}
      <div className="dropdown-option-wrapper d-flex">
        <Select<Network>
          className="mr-3 mb-4 mb-md-0 dropdown z-index-6"
          optionsList={[allNetworks, ...networkOptions].filter(
            network => network.chainId !== currentNetwork?.chainId,
          )}
          displayFields={{
            primary: 'chainName',
            secondary: '',
            icon: 'chainIcon',
          }}
          size="sm"
          selected={nftClaimFilterNetworkIndex !== 0 ? nftClaimFilterNetworkIndex : 0}
          onSelectOptionChange={option => {
            setFilterCriteria(prevState => {
              return {
                ...prevState,
                network: option?.chainId ? String(option.chainId) : option.nativeCurrency.symbol,
              };
            });
            const index = [allNetworks, ...networkOptions]
              .filter(network => network.chainId !== currentNetwork?.chainId)
              .indexOf(option);
            setNftClaimFilterNetworkIndex(index);
          }}
        />
        <NftClaimFilterDropdown
          className="dropdown"
          optionsList={Object.values(FILTER_SELECT_CONSTANTS)?.map(item => item)}
          displayFields={{
            primary: 'name',
            secondary: '',
            icon: 'icon',
          }}
          size="sm"
          selected={nftClaimFilterIndex}
          onSelectOptionChange={option => {
            setFilterCriteria(prevState => {
              return {
                ...prevState,
                status: option.name,
              };
            });
            setNftClaimFilterIndex(option.index);
          }}
        />
      </div>
    </div>
  );
}

export default NftClaimFilter;
