import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { env } from 'environments';

interface MaintenanceModeProps {
  children: JSX.Element;
  enabled?: boolean;
}

const MaintenanceMode = ({ enabled, children }: MaintenanceModeProps) => {
  const { t } = useTranslation();
  const dangerHtml = env.MAINTENANCE_MODE_HTML;

  return enabled ? (
    // Why danger? https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
    dangerHtml ? (
      <div dangerouslySetInnerHTML={{ __html: dangerHtml }} />
    ) : (
      <div className="text-center py-9">
        <h2 className="text-medium">{t('layout:maintenance.title')}</h2>
        <p className="mt-3 text-normal">{t('layout:maintenance.subtitle')}</p>
        <p className="mt-3 text-normal">
          <Trans i18nKey="layout:maintenance.contact" components={{ a: <a /> }} />
        </p>
      </div>
    )
  ) : (
    <>{children}</>
  );
};

export default MaintenanceMode;
