import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Tooltips';

import './ClaimTitle.scss';

function ClaimTitle() {
  const { t } = useTranslation();

  return (
    <>
      <div className="claim-table-title d-flex align-items-center">
        <div className="text-medium text-bold text-main mb-3 mr-3">{t('claimPage:title')}</div>
        <InfoTooltip text={t('claimPage:titleTooltip')} size={20} />
      </div>
      <div className="text-main">
        <Trans i18nKey="claimPage:description" components={{ span: <span /> }} />
      </div>
    </>
  );
}

export default ClaimTitle;
