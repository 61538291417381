import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@stichting-allianceblock-foundation/components';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltips';
import { useDefaultFilterOptionData } from 'hooks/useDefaultFilterOption';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';

import ClaimFilterDropdown from './ClaimFilterDropdown';

interface ClaimFilterProps {
  claimsAccount: string | undefined;
  setClaimsAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function ClaimFilter({ claimsAccount, setClaimsAccount }: ClaimFilterProps) {
  const { currentNetwork, claims, networkOptions, setFilterCriteria, setIsClaimLoaded, sdk } =
    useGlobalContext();
  const { account } = useWallet();
  const { isEnabled } = useFeatureFlag();

  const { t } = useTranslation();
  const defaultFilterOption = useDefaultFilterOptionData();
  const [claimFilterIndex, setClaimFilterIndex] = useState(0);
  const [claimFilterNetworkIndex, setClaimFilterNetworkIndex] = useState(0);

  const allNetworks: Network = {
    ...({} as Network),
    chainName: t('claimPage:filterOptions.network'),
    chainIcon: 'check',
    nativeCurrency: {
      name: '',
      symbol: t('claimPage:filterOptions.network'),
      decimals: 0,
    },
  };

  const FILTER_SELECT_CONSTANTS = [
    {
      name: t('claimPage:filterOptions.constants.showAll'),
      icon: 'check',
      index: 0,
    },
    {
      name: t('claimPage:filterOptions.constants.claimed'),
      icon: 'check',
      index: 1,
    },
    {
      name: t('claimPage:filterOptions.constants.notClaimed'),
      icon: 'check',
      index: 2,
    },
  ];

  useEffect(() => {
    setClaimFilterIndex(0);
    setClaimFilterNetworkIndex(0);
    setFilterCriteria(defaultFilterOption);

    if (!claims.length) {
      setIsClaimLoaded(true);
    }
  }, [
    currentNetwork,
    account,
    setFilterCriteria,
    defaultFilterOption,
    claims.length,
    setIsClaimLoaded,
  ]);

  return (
    <>
      <div className="claimed-option-wrapper mt-4 width-sm-100 width-md-auto">
        {isEnabled('claim_on_behalf') && (
          <Tooltip
            className=" width-100"
            text={t('claimPage:filterOptions.claimOnBehalfOfTooltip')}
          >
            <TextField
              icon="social-user"
              className=" width-100"
              value={claimsAccount ?? ''}
              error={
                (claimsAccount && sdk?.utils.isAddress(claimsAccount)) ||
                !claimsAccount ||
                claimsAccount === ''
                  ? ''
                  : t('claimPage:filterOptions.claimOnBehalfOfError')
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setClaimsAccount(
                  event.target.value === '' || !sdk?.utils.isAddress(event.target.value)
                    ? undefined
                    : event.target.value,
                )
              }
              placeholder={t('claimPage:filterOptions.claimOnBehalfOf')}
            ></TextField>
          </Tooltip>
        )}
        <div className="d-flex dropdown-option-wrapper">
          <Select
            className="mr-3 mb-4 mb-md-0 dropdown z-index-6"
            optionsList={[allNetworks, ...networkOptions].filter(
              network => network.chainId !== currentNetwork?.chainId,
            )}
            displayFields={{
              primary: 'chainName',
              secondary: '',
              icon: 'chainIcon',
            }}
            size="sm"
            selected={claimFilterNetworkIndex !== 0 ? claimFilterNetworkIndex : 0}
            onSelectOptionChange={option => {
              setFilterCriteria(prevState => {
                return {
                  ...prevState,
                  network: option?.chainId ? String(option.chainId) : option.nativeCurrency.symbol,
                };
              });
              const index = [allNetworks, ...networkOptions]
                .filter(network => network.chainId !== currentNetwork?.chainId)
                .indexOf(option);
              setClaimFilterNetworkIndex(index);
            }}
          />
          <ClaimFilterDropdown
            className="dropdown"
            optionsList={Object.values(FILTER_SELECT_CONSTANTS)?.map(
              (item: FilterStatus & { index: number }) => item,
            )}
            displayFields={{
              primary: 'name',
              secondary: '',
              icon: 'icon',
            }}
            size="sm"
            selected={claimFilterIndex}
            onSelectOptionChange={option => {
              setFilterCriteria(prevState => {
                return {
                  ...prevState,
                  status: option.name,
                };
              });
              setClaimFilterIndex(option.index);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ClaimFilter;
