import React from 'react';

import { utils } from '@stichting-allianceblock-foundation/abridge-sdk';
import { useBreakpoint } from 'hooks/useBreakpoint';

import './NftDetailsCard.scss';

interface NftDetailsCardProps {
  item: NftClaims;
  onlyImage?: boolean;
  className?: string;
}

const NftDetailsCard = ({ item, onlyImage, className }: NftDetailsCardProps) => {
  const { greaterThan } = useBreakpoint();
  const defaultNFT = '../images/default-nft.png';

  const shortenNftDescription = (description: string, offset: number): string => {
    return `${description?.substring(0, offset)} ...`;
  };

  return (
    <div className={`nft-details-card d-flex mt-3 mb-5 ${className ?? ''}`}>
      <div className="d-flex justify-content-center justify-content-md-start align-items-md-center mb-4 mb-md-0">
        <img
          className="nft-details-card-image"
          src={utils.uriToHttp(item?.image) || defaultNFT}
          alt={item.image}
        />
      </div>
      {!onlyImage && (
        <div className="d-flex flex-column justify-content-center mx-4">
          <p className={`text-main text-bold${greaterThan('md') ? '' : ' text-center'}`}>
            {item.name}
          </p>
          {item?.description && (
            <p className={`text-main${greaterThan('md') ? '' : ' text-center'}`}>
              {greaterThan('md') ? item.description : shortenNftDescription(item.description, 50)}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default NftDetailsCard;
