import { useContext } from 'react';

import { ModalContext } from 'context/ModalContext';

export function useModalContext() {
  const modalContext = useContext(ModalContext);

  return {
    ...modalContext,
  };
}
