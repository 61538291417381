import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClaimEmptySvgImage } from 'assets/claim-empty.svg';
import { ReactComponent as ClaimEmptyMobileSvgImage } from 'assets/claim-empty-mobile.svg';
import { ReactComponent as ClaimEmptyLoading } from 'assets/claim-loading.svg';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';

import EmptyClaimsFooter from '../../../../Claim/ClaimWindow/ClaimListing/EmptyClaimsFooter/EmptyClaimsFooter';
import Pagination from '../../../../Pagination/Pagination';
import NftAttention from './NftAttentionMessage/NftAttention';
import NftClaimHeader from './NftClaimHeader/NftClaimHeader';
import NftListItem from './NftListItem/NftListItem';
import NftListItemMobile from './NftListItemMobile/NftListItemMobile';

import './NftClaimListing.scss';

const NftClaimListing = () => {
  const { nftClaims, filterCriteria, isNftsLoaded } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { greaterThan } = useBreakpoint();
  const isMobile: boolean = !greaterThan('sm');

  const { t } = useTranslation();

  const PageSize = 10;

  const { status: statusFilter, network: networkFilter } = filterCriteria;

  const currentNftData = useMemo(() => {
    const filterNftClaimListingByCriteria = (entry: FormattedNftClaims) => {
      const status: { [value: string]: string } = {
        true: t('nftClaimPage:filterOptions.constants.claimed'),
        false: t('nftClaimPage:filterOptions.constants.notClaimed'),
      };
      return (
        (status[String(entry.delivered?.every((v: boolean) => v))] === statusFilter ||
          statusFilter === t('nftClaimPage:filterOptions.constants.showAll')) &&
        (String(entry.sourceNetwork.chainId) === networkFilter ||
          networkFilter === t('nftClaimPage:filterOptions.network'))
      );
    };

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const claims = nftClaims
      ?.slice(firstPageIndex, lastPageIndex)
      .sort((x, y) => {
        return x.delivered[0] === y.delivered[0] ? 0 : x.delivered[0] ? -1 : 1;
      })
      .filter(entry => {
        return filterCriteria.status !== t('nftClaimPage:filterOptions.constants.showAll')
          ? filterCriteria.status === t('nftClaimPage:filterOptions.constants.claimed')
            ? entry.items[0].delivered
            : !entry.items[0].delivered
          : entry;
      });

    return statusFilter === t('nftClaimPage:filterOptions.constants.showAll') &&
      networkFilter === t('nftClaimPage:filterOptions.network')
      ? claims
      : claims.filter(filterNftClaimListingByCriteria);
  }, [currentPage, filterCriteria.status, networkFilter, nftClaims, statusFilter, t]);

  return (
    <div className="nft-claim-listing mt-4">
      {!isMobile ? <NftClaimHeader /> : null}
      <div className="nft-claim-wrappers">
        <div className="nft-claim-table align-items-center justify-content-center">
          {!isNftsLoaded && currentNftData.length ? (
            currentNftData.map((claim, index) =>
              !isMobile ? (
                <NftListItem claim={claim} key={claim?.items[0].transmissionId + index} />
              ) : (
                <NftListItemMobile claim={claim} key={claim?.items[0].transmissionId + index} />
              ),
            )
          ) : isNftsLoaded ? (
            <ClaimEmptyLoading />
          ) : (
            <div className="no-claims-wrapper d-flex justify-content-center">
              {isMobile ? <ClaimEmptyMobileSvgImage /> : <ClaimEmptySvgImage />}
            </div>
          )}
        </div>
        {!currentNftData?.length ? null : <NftAttention />}
        {nftClaims?.length > PageSize && (
          <Pagination
            className="d-flex justify-content-center align-items-center m-1"
            pageSize={PageSize}
            currentPage={currentPage}
            totalCount={nftClaims?.length}
            onPageChange={(page: number) => {
              setCurrentPage(page);
            }}
          ></Pagination>
        )}
      </div>
      {!currentNftData?.length && !isNftsLoaded ? <EmptyClaimsFooter /> : null}
    </div>
  );
};

export default NftClaimListing;
