import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalContext } from 'hooks/useGlobalContext';

import { formatTransferList } from '../../../../../helpers/Erc20Claims';
import useWallet from '../../../../../hooks/useWallet';
import { ClaimListing } from '..';

function ClaimWindowContainer() {
  const { sdk, claims, currentNetwork, filterCriteria, networkOptions } = useGlobalContext();

  const { t } = useTranslation();

  const { account } = useWallet();

  const [formattedListedData, setFormattedListedData] = useState<FormattedTransfer[]>([]);

  const [filteredClaimList, setFilteredClaimList] = useState<FormattedTransfer[]>([]);

  const { status: statusFilter, network: networkFilter } = filterCriteria;

  useEffect(() => {
    const filterClaimListingByCriteria = (entry: FormattedTransfer) => {
      const status: { [value: string]: string } = {
        true: t('claimPage:filterOptions.constants.claimed'),
        false: t('claimPage:filterOptions.constants.notClaimed'),
      };
      return (
        (status[entry.status as string] === statusFilter ||
          statusFilter === t('claimPage:filterOptions.constants.showAll')) &&
        (String(entry.sourceNetwork.chainId) === networkFilter ||
          networkFilter === t('claimPage:filterOptions.network'))
      );
    };

    const listFiltered =
      statusFilter === t('claimPage:filterOptions.constants.showAll') &&
      networkFilter === t('claimPage:filterOptions.network')
        ? formattedListedData
        : formattedListedData.filter(filterClaimListingByCriteria);

    setFilteredClaimList(listFiltered);
  }, [formattedListedData, networkFilter, statusFilter, t]);

  useEffect(() => {
    const updateTransfers = async () => {
      if (sdk && account) {
        let transfers = await formatTransferList(
          sdk,
          currentNetwork.blockExplorerUrl,
          claims,
          networkOptions,
          account,
        );

        transfers = transfers.sort((x: FormattedTransfer, y: FormattedTransfer) => {
          const xIsClaimed = x.status === 'true' ? 1 : 0;
          const yIsClaimed = y.status === 'true' ? 1 : 0;
          return xIsClaimed - yIsClaimed;
        });

        setFormattedListedData(transfers);
      }
    };

    updateTransfers();
  }, [account, claims, currentNetwork.blockExplorerUrl, networkOptions, sdk]);
  return filteredClaimList ? <ClaimListing claimData={filteredClaimList}></ClaimListing> : null;
}

export default ClaimWindowContainer;
