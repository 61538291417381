import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { InfoTooltip } from 'components/Tooltips';

const NftTitle = ({ schema }: { schema?: string }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center mb-3">
            <Icon
              name="arrow-left-long"
              size={32}
              color="brand-primary"
              className="mr-4 d-none d-md-block"
              onClick={history.goBack}
            />
            <div className="go-back-line-vertical mr-md-2 d-none d-md-block"></div>
            <div className="text-medium text-bold text-main mr-3 mr-md-3 mx-0 mx-md-3">
              {t('nftTransferPage:title', { schema })}
            </div>
          </div>
          <InfoTooltip text={t('nftTransferPage:titleTooltip', { schema })} size={20} />
        </div>
        <Icon
          name="cross-big"
          size={24}
          color="ui-main"
          className="d-flex justify-content-end d-md-none mb-3"
          onClick={history.goBack}
        />
      </div>
      <div className="text-main">
        <Trans
          i18nKey="nftTransferPage:description"
          components={{ span: <span /> }}
          values={{ schema }}
        />
      </div>
    </>
  );
};

export default NftTitle;
