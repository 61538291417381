import { useEffect, useState } from 'react';

import { localStorageKeys } from 'configs/constants';
import { getLocalStorage, setLocalStorage } from 'utils/localstorage';

export const useWalletConnection = (initialState: WalletConnected) => {
  const [isWalletConnected, setIsWalletConnected] = useState<WalletConnected>(initialState);

  const _isWalletConnected = (): WalletConnected => {
    const walletConnectedValue = getLocalStorage(localStorageKeys.walletConnection);
    try {
      return JSON.parse(walletConnectedValue);
    } catch {
      return {
        metamask: false,
        phantom: false,
      };
    }
  };

  const _setIsWalletConnected = (walletConnected: WalletConnected) =>
    setLocalStorage(localStorageKeys.walletConnection, JSON.stringify(walletConnected));

  useEffect(() => {
    setIsWalletConnected(_isWalletConnected());
  }, []);

  useEffect(() => {
    _setIsWalletConnected(isWalletConnected);
  }, [isWalletConnected]);

  return { isWalletConnected, setIsWalletConnected };
};
