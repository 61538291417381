import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Indicator } from 'components/Indicator';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';

function ClaimedCounter() {
  const { bridgeStatuses, filterCriteria, claims } = useGlobalContext();
  const { t } = useTranslation();
  const { lessThan } = useBreakpoint();

  const [claimButtonHeader, setClaimButtonHeader] = useState<string>(
    t('claimPage:counter.toBeClaimed'),
  );

  useEffect(() => {
    if (filterCriteria.status !== t('claimPage:filterOptions.constants.showAll')) {
      setClaimButtonHeader(filterCriteria.status);
    } else {
      setClaimButtonHeader(t('claimPage:counter.toBeClaimed'));
    }
  }, [filterCriteria.status, t]);

  const activeClaims = bridgeStatuses.bridge.activeClaims;
  const inActiveClaims = bridgeStatuses.bridge.inActiveClaims;
  return (
    <div className="d-flex">
      <Indicator
        type="primary"
        title={claimButtonHeader}
        value={
          claimButtonHeader === t('claimPage:filterOptions.constants.claimed')
            ? inActiveClaims
            : activeClaims
        }
        className="mr-2 mr-sm-0 mb-sm-2"
      />
      {lessThan('sm') && (
        <Indicator
          type="secondary"
          title={t('claimPage:counter.total')}
          value={claims.length}
          className="ml-2 ml-sm-0 mt-sm-2"
        />
      )}
    </div>
  );
}

export default ClaimedCounter;
