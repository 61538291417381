import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Button, Icon, LabelButton } from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import { getBlockExplorerNameByChainId } from 'utils';

import './ClaimButton.scss';

function ClaimButton({
  disabled,
  status,
  clicked,
  entry,
}: {
  disabled: boolean;
  status: string;
  clicked?: Dispatch<SetStateAction<string | undefined>>;
  entry: FormattedTransfer;
}) {
  const { sdk, currentNetwork, setClaimModal } = useGlobalContext();
  const { t } = useTranslation();

  const [buttonStatus, setButtonStatus] = useState<string>(status);
  const [transactionHash, setTransactionHash] = useState<string>('');

  const { type } = useWallet();

  const badgeLabelName: string = getBlockExplorerNameByChainId(currentNetwork.chainId);

  useEffect(() => {
    const parseClaimsAndCheckStatus = async () => {
      let claimsInProcess = sessionStorage.getItem('claims-in-process');
      if (typeof claimsInProcess === 'string') {
        claimsInProcess = await JSON.parse(claimsInProcess);
      }
      if (claimsInProcess?.includes(entry.sourceTx)) {
        setButtonStatus(t('claimPage:buttonStatus.claiming'));
      }
    };

    parseClaimsAndCheckStatus();
  }, [entry.sourceTx, t]);

  useEffect(() => {
    setButtonStatus(status);
  }, [status]);

  const handleCollapse = async () => {
    setButtonStatus(t('claimPage:buttonStatus.claiming'));
    clicked ? clicked(t('claimPage:buttonStatus.claiming')) : null;

    let claimsInProcess: string[] = [];
    const claimsInProcessData = sessionStorage.getItem('claims-in-process');

    if (claimsInProcessData) {
      const parsedClaims = JSON.parse(claimsInProcessData);
      claimsInProcess = parsedClaims?.filter((sourceTx: string) => sourceTx !== entry.sourceTx);
    }
    sessionStorage.setItem('claims-in-process', JSON.stringify(claimsInProcess));
  };

  function clearClaimFromSession(claimsInProcess: string[]) {
    const index = claimsInProcess.indexOf(entry.sourceTx, 0);
    if (index > -1) {
      claimsInProcess.splice(index, 1);
      sessionStorage.setItem('claims-in-process', JSON.stringify(claimsInProcess));
    }
  }

  const onClaimClick = async () => {
    if (entry.transmission.transmissionType === 'ccip') {
      window.open(`https://ccip.chain.link/msg/${entry.transmissionId}`, '_blank');
      // TODO: add possibility to claim ccip with our sdk (not supported yet)
      return;
    }

    await handleCollapse();
    const claimsFromStorage = sessionStorage.getItem('claims-in-process');

    if (!(sdk && typeof claimsFromStorage === 'string')) {
      return;
    }

    const claimsInProcess: string[] = JSON.parse(claimsFromStorage) || [];
    claimsInProcess.push(entry.sourceTx);
    sessionStorage.setItem('claims-in-process', JSON.stringify(claimsInProcess));

    try {
      let claimSuccessful = false;
      const payableCall = await sdk.deliver(entry.transmission);
      let txHash = '';
      try {
        const contractTransaction = await payableCall.sendTransaction();
        txHash = contractTransaction.hash;
        setTransactionHash(txHash);
        await contractTransaction.wait();
        claimSuccessful = true;
      } catch (err) {
        console.error(err);
      }

      if (claimSuccessful) {
        setButtonStatus(t('claimPage:buttonStatus.claimed'));
        clicked ? clicked(t('claimPage:buttonStatus.claimed')) : null;

        const tokenType = 'ERC20';
        ERC20RouterSDK.DAPP_NAME;
        const tokenAddress = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .getWrappedForNativeToken(entry.nativeChainId, entry.nativeToken);

        setClaimModal(prevState => {
          return {
            ...prevState,
            claimData: {
              token: {
                ...entry.bridgedToken,
                address: tokenAddress,
              },
              type: tokenType,
              recipient: entry.recipient,
              transactionHash: txHash,
              walletType: type,
            },
            status: true,
          };
        });
        setButtonStatus(t('claimPage:buttonStatus.claimed'));
        clearClaimFromSession(claimsInProcess);
        return;
      }
      clearClaimFromSession(claimsInProcess);

      setButtonStatus(t('claimPage:buttonStatus.notClaimed'));
      clicked ? clicked(t('claimPage:buttonStatus.notClaimed')) : null;
    } catch (err) {
      console.error(err);
      clearClaimFromSession(claimsInProcess);
      setButtonStatus(t('claimPage:buttonStatus.notClaimed'));
      clicked ? clicked(t('claimPage:buttonStatus.notClaimed')) : null;
    }
  };

  const claimButton = (
    <Button
      disabled={disabled || buttonStatus === t('claimPage:buttonStatus.claimed')}
      type="secondary"
      size="sm"
      onClick={onClaimClick}
      className="edit-button"
    >
      <div className="d-flex mr-2">
        <Icon
          name={
            buttonStatus === t('claimPage:buttonStatus.claimed') ? 'edit-finalize' : 'edit-claim'
          }
          color={'brand-primary'}
          size={16}
        />
      </div>

      <div className="claim-text">
        {buttonStatus === t('claimPage:buttonStatus.claimed')
          ? buttonStatus
          : t('claimPage:buttonStatus.claim')}
      </div>
    </Button>
  );

  const labelClaimingButton = (
    <LabelButton
      type="primary"
      size="sm"
      label={
        transactionHash ? (
          <BlockExplorerBadge
            title={badgeLabelName}
            hash={transactionHash}
            walletType={currentNetwork.walletType}
            blockExplorer={currentNetwork.blockExplorerUrl}
            queryParams={currentNetwork.blockExplorerQueryParams}
            type="tx"
          />
        ) : (
          <p className="text-secondary">{badgeLabelName}</p>
        )
      }
      loading={true}
      loadingText={<span className="processing-span">{t('labelButton:processing')}</span>}
    >
      Claim
    </LabelButton>
  );

  const autoClaiming = (
    <div className="autoclaiming d-flex align-items-center">
      <LabelButton
        className="mr-2"
        label=""
        size="sm"
        type="primary"
        loading={true}
        loadingText="Autoclaiming"
      />
      <Button
        disabled={disabled || buttonStatus === t('claimPage:buttonStatus.claimed')}
        type="secondary"
        size="sm"
        onClick={onClaimClick}
        className="edit-button"
      >
        <div className="d-flex mr-2">
          <Icon
            name={
              buttonStatus === t('claimPage:buttonStatus.claimed') ? 'edit-finalize' : 'edit-claim'
            }
            color={'brand-primary'}
            size={16}
          />
        </div>
      </Button>
    </div>
  );

  switch (buttonStatus) {
    case t('claimPage:buttonStatus.claiming'):
      return labelClaimingButton;
    case t('claimPage:buttonStatus.notClaimed'):
      if (entry.autoClaiming && !entry.failed) {
        return autoClaiming;
      }
      return claimButton;
    case t('claimPage:buttonStatus.claimed'):
      return claimButton;
    default:
      return null;
  }
}

export default ClaimButton;
