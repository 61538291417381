import React from 'react';
import { useEffect, useState } from 'react';

export const useDetectOutsideClick = (
  el: React.MutableRefObject<HTMLDivElement | null>,
  initialState: boolean,
  onClickOutside?: () => void,
) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const handleClickEvent = (e: MouseEvent) => {
      const target = e.target as Node;

      // If the active element exists and is clicked outside of
      if (el?.current !== null && !el?.current?.contains(target)) {
        setIsActive(!isActive);
        onClickOutside?.();
      }
    };

    if (isActive) {
      window.addEventListener('mousedown', handleClickEvent);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickEvent);
    };
  }, [isActive, el, onClickOutside]);

  return { isActive, setIsActive };
};
