// This file is used to define the environment variables that are used in the application.
// If you want to add a new environment variable, please add it to the .env.example too.
import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

const optionalUrl = z.string().url().optional().or(z.literal(''));
const validatedEnv = (env: string) =>
  z
    .string()
    .toLowerCase()
    .refine(s => s === 'prod' || s === 'staging' || s === 'develop')
    .transform(s => s === env);

export const env = createEnv({
  clientPrefix: 'REACT_APP_',
  server: {},
  shared: {
    NODE_ENV: z.enum(['development', 'production']),
    IS_PRODUCTION: validatedEnv('prod'),
    IS_STAGING: validatedEnv('staging'),
    MAINTENANCE_MODE: z
      .string()
      .toLowerCase()
      .optional()
      .default('false')
      .refine(s => s === 'true' || s === 'false')
      .transform(s => s === 'true'),
    MAINTENANCE_MODE_HTML: z.string().optional(),
    BUILD_VERSION: z.string().optional(),
    OPENSEA_ID: z.string().optional(),

    VALIDATOR_ALLOWED_VERSION: z.string().optional(),
    VALIDATORS: z.string().transform(v =>
      v
        ? v
            .split(',')
            .map(item => item.trim())
            .filter(item => item)
        : undefined,
    ),

    DISABLE_BRIDGE_OPERATION: z
      .string()
      .toLowerCase()
      .optional()
      .default('false')
      .refine(s => s === 'true' || s === 'false')
      .transform(s => s === 'true'),
    FORCE_ENABLE_BRIDGE_OPERATION: z.string().optional(),

    ABRIDGE_BACKEND_URL: optionalUrl,
    BASE_URL: optionalUrl,
    IP_API_URL: z.string().url(),
    INFURA_ID: z.string().optional(),

    SOLANA_NETWORK: z.string().url().or(z.literal('')),
    // RPC Mainnet
    RPC_MAINNET_ETHEREUM: z.string().url(),
    RPC_MAINNET_BSC: z.string().url(),
    RPC_MAINNET_AVAX: z.string().url(),
    RPC_MAINNET_ARBITRUM: z.string().url(),
    RPC_MAINNET_POLYGON: z.string().url(),
    RPC_MAINNET_OPTIMISM: z.string().url(),
    RPC_MAINNET_BASE: z.string().url(),
    // RPC Testnet
    RPC_TESTNET_ETHEREUM: z.string().url(),
    RPC_TESTNET_BSC: z.string().url(),
    RPC_TESTNET_AVAX: z.string().url(),
    RPC_TESTNET_ARBITRUM_SEPOLIA: z.string().url(),
    RPC_TESTNET_POLYGON_AMOY: z.string().url(),
    RPC_TESTNET_OPTIMISM_SEPOLIA: z.string().url(),
    // Block Explorer Mainnet
    BLOCK_EXPLORER_MAINNET_ETHEREUM: z.string().url(),
    BLOCK_EXPLORER_MAINNET_BSC: z.string().url(),
    BLOCK_EXPLORER_MAINNET_AVAX: z.string().url(),
    BLOCK_EXPLORER_MAINNET_ARBITRUM: z.string().url(),
    BLOCK_EXPLORER_MAINNET_POLYGON: z.string().url(),
    BLOCK_EXPLORER_MAINNET_OPTIMISM: z.string().url(),
    BLOCK_EXPLORER_MAINNET_BASE: z.string().url(),
    // Block Explorer Testnet
    BLOCK_EXPLORER_TESTNET_ETHEREUM: z.string().url(),
    BLOCK_EXPLORER_TESTNET_BSC: z.string().url(),
    BLOCK_EXPLORER_TESTNET_AVAX: z.string().url(),
    BLOCK_EXPLORER_TESTNET_ARBITRUM_SEPOLIA: z.string().url(),
    BLOCK_EXPLORER_TESTNET_POLYGON_AMOY: z.string().url(),
    BLOCK_EXPLORER_TESTNET_OPTIMISM_SEPOLIA: z.string().url(),
  },
  client: {},
  runtimeEnvStrict: {
    NODE_ENV: process.env.NODE_ENV,
    IS_PRODUCTION: process.env.REACT_APP_NETWORK_CONFIG || 'develop',
    IS_STAGING: process.env.REACT_APP_NETWORK_CONFIG || 'develop',
    MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE,
    MAINTENANCE_MODE_HTML: process.env.REACT_APP_MAINTENANCE_MODE_HTML,
    BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION,
    OPENSEA_ID: process.env.REACT_APP_OPENSEA_ID || '',

    VALIDATOR_ALLOWED_VERSION: process.env.REACT_APP_VALIDATOR_ALLOWED_VERSION,
    VALIDATORS: process.env.REACT_APP_VALIDATORS || '',

    DISABLE_BRIDGE_OPERATION: process.env.REACT_APP_DISABLE_BRIDGE_OPERATION,
    FORCE_ENABLE_BRIDGE_OPERATION: process.env.REACT_APP_FORCE_ENABLE_BRIDGE_OPERATION,

    ABRIDGE_BACKEND_URL: process.env.REACT_APP_ABRIDGE_BACKEND_URL,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    IP_API_URL: process.env.REACT_APP_IP_API_URL ?? 'https://api.ipify.org?format=json',
    INFURA_ID: process.env.REACT_APP_INFURA_ID,

    SOLANA_NETWORK: process.env.REACT_APP_SOLANA_NETWORK || 'https://api.devnet.solana.com',

    // RPC Mainnet
    RPC_MAINNET_ETHEREUM: process.env.ETHEREUM_NODE_URL || 'https://ethereum-rpc.publicnode.com',
    RPC_MAINNET_BSC: process.env.BSC_NODE_URL || 'https://bsc-dataseed.binance.org',
    RPC_MAINNET_AVAX: process.env.AVALANCHE_NODE_URL || 'https://api.avax.network/ext/bc/C/rpc',
    RPC_MAINNET_ARBITRUM: process.env.ARBITRUM_NODE_URL || 'https://arb1.arbitrum.io/rpc',
    RPC_MAINNET_POLYGON: process.env.POLYGON_NODE_URL || 'https://polygon-pokt.nodies.app',
    RPC_MAINNET_OPTIMISM: process.env.OPTIMISM_NODE_URL || 'https://mainnet.optimism.io',
    RPC_MAINNET_BASE: process.env.BASE_NODE_URL || 'https://mainnet.base.org',

    // RPC Testnet
    RPC_TESTNET_ETHEREUM:
      process.env.ETHEREUM_TESTNET_NODE_URL || 'https://ethereum-sepolia-rpc.publicnode.com',
    RPC_TESTNET_BSC:
      process.env.BSC_TESTNET_NODE_URL || 'https://data-seed-prebsc-1-s1.binance.org:8545',
    RPC_TESTNET_AVAX:
      process.env.AVALANCHE_TESTNET_NODE_URL || 'https://api.avax-test.network/ext/bc/C/rpc',
    RPC_TESTNET_ARBITRUM_SEPOLIA:
      process.env.ARBITRUM_TESTNET_NODE_URL || 'https://sepolia-rollup.arbitrum.io/rpc',
    RPC_TESTNET_POLYGON_AMOY:
      process.env.POLYGON_TESTNET_NODE_URL || 'https://rpc-amoy.polygon.technology/',
    RPC_TESTNET_OPTIMISM_SEPOLIA:
      process.env.OPTIMISM_TESTNET_NODE_URL || 'https://sepolia.optimism.io',

    // Block Explorer Mainnet
    BLOCK_EXPLORER_MAINNET_ETHEREUM: process.env.ETH_BLOCK_EXPLORER_URL || 'https://etherscan.io',
    BLOCK_EXPLORER_MAINNET_BSC: process.env.BSC_BLOCK_EXPLORER_URL || 'https://bscscan.com',
    BLOCK_EXPLORER_MAINNET_AVAX:
      process.env.AVAX_BLOCK_EXPLORER_URL || 'https://avascan.info/blockchain/c/',
    BLOCK_EXPLORER_MAINNET_ARBITRUM:
      process.env.ARBITRUM_BLOCK_EXPLORER_URL || 'https://arbiscan.io',
    BLOCK_EXPLORER_MAINNET_POLYGON:
      process.env.POLYGON_BLOCK_EXPLORER_URL || 'https://polygonscan.com',
    BLOCK_EXPLORER_MAINNET_OPTIMISM:
      process.env.OPTIMISM_BLOCK_EXPLORER_URL || 'https://optimistic.etherscan.io',
    BLOCK_EXPLORER_MAINNET_BASE: process.env.BASE_BLOCK_EXPLORER_URL || 'https://basescan.org',

    // Block Explorer Testnet
    BLOCK_EXPLORER_TESTNET_ETHEREUM:
      process.env.ETHEREUM_TESTNET_EXPLORER_URL || 'https://sepolia.etherscan.io',
    BLOCK_EXPLORER_TESTNET_BSC:
      process.env.BSC_TESTNET_EXPLORER_URL || 'https://testnet.bscscan.com',
    BLOCK_EXPLORER_TESTNET_AVAX:
      process.env.AVALANCHE_TESTNET_EXPLORER_URL || 'https://testnet.avascan.info/blockchain/c/',
    BLOCK_EXPLORER_TESTNET_ARBITRUM_SEPOLIA:
      process.env.ARBITRUM_TESTNET_EXPLORER_URL || 'https://sepolia.arbiscan.io',
    BLOCK_EXPLORER_TESTNET_POLYGON_AMOY:
      process.env.POLYGON_TESTNET_EXPLORER_URL || 'https://amoy.polygonscan.com/',
    BLOCK_EXPLORER_TESTNET_OPTIMISM_SEPOLIA:
      process.env.OPTIMISM_TESTNET_BLOCK_EXPLORER_URL || 'https://optimism-sepolia.blockscout.com/',
  },
});
