import type { Connector } from '@web3-react/types';
import { useEffect } from 'react';

import { JsonRpcSigner } from '@ethersproject/providers';
import { AnchorProvider } from '@project-serum/anchor';
import ethers from 'ethers';

import { WALLET_TYPE } from '../../configs/constants';
import { useGlobalContext } from '../useGlobalContext';
import useAdapterMetamaskWallet from './useAdapterMetamaskWallet';
// import useAdapterPhantomWallet from './useAdapterPhantomWallet';

export interface WalletFacade {
  activate: () => Promise<void>;
  deactivate: () => void;
  changeNetwork: (network: Network) => void;
  isWalletInstalled: boolean;
  account: string | undefined | null;
  isActive: boolean;
  chainId: number | undefined;
  library?: ethers.providers.Web3Provider;
  error?: Error | undefined;
  connector?: Connector | undefined;
  signer?: JsonRpcSigner;
  provider?: AnchorProvider;
  type: string;
  connectedWallet: {
    // isSolana: boolean;
    isEvm: boolean;
    isDefault: boolean;
  };
  solanaNetwork?: string;
}

interface Wallets {
  [key: string]: Omit<WalletFacade, 'type' | 'connectedWallet'>;
}

// This hook is used to connect to the different wallets available
// Abstracts the logic of connecting to different wallets using the facade pattern
export const useWallet = (props?: { type: string }): WalletFacade => {
  const {
    currentNetwork: { walletType },
    isWalletConnected,
    setTriedConnecting,
    setIsWalletConnected,
    setSDK,
    setClaims,
    setNftClaims,
  } = useGlobalContext();

  // Supported wallets and their adapters to the facade pattern (WalletFacade)
  const wallets: Wallets = {
    metamask: useAdapterMetamaskWallet(),
    // phantom: useAdapterPhantomWallet(),
  };

  // Get the facade of the current wallet selected by the user
  const {
    signer,
    solanaNetwork,
    activate,
    deactivate,
    isWalletInstalled,
    account,
    provider,
    library,
    chainId,
    isActive,
    error,
    connector,
  } = wallets[props?.type || walletType];

  const onActivate = async () => {
    setTriedConnecting(true);
    await activate();
    setIsWalletConnected({ ...isWalletConnected, [walletType]: true });
  };

  const onDeactivate = () => {
    deactivate();
    setIsWalletConnected({ ...isWalletConnected, [walletType]: false });
    setTriedConnecting(false);
  };

  const onChangeNetwork = async (network: Network) => {
    if (chainId === network.chainId) return;
    setClaims([]);
    setNftClaims([]);
    setSDK(null);

    // Get changeNetwork function from the facade of the current wallet
    const { walletType: currentWalletType } = network;
    wallets[currentWalletType].changeNetwork(network);
  };

  useEffect(() => {
    async function connectWallet() {
      try {
        if (
          !isActive &&
          connector?.connectEagerly &&
          isWalletInstalled &&
          isWalletConnected[WALLET_TYPE.METAMASK]
        ) {
          await connector.connectEagerly();
        }
      } catch (e) {
        console.error('[Error trying to connect to the wallet]:', e);
      }
    }

    connectWallet();
  }, [connector, isActive, isWalletConnected, isWalletInstalled]);

  return {
    connectedWallet: {
      // isSolana: walletType === WALLET_TYPE.PHANTOM,
      isEvm: walletType === WALLET_TYPE.METAMASK,
      isDefault: walletType === WALLET_TYPE.DEFAULT,
    },
    type: walletType,
    account,
    provider,
    library,
    chainId,
    isActive,
    error,
    connector,
    signer,
    solanaNetwork,
    isWalletInstalled,
    activate: onActivate,
    deactivate: onDeactivate,
    changeNetwork: onChangeNetwork,
  };
};

export default useWallet;
