import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as NftTransferSuccessDesktopLogo } from 'assets/nft-transfer-success-desktop.svg';
import { ReactComponent as NftTransferSuccessMobileLogo } from 'assets/nft-transfer-success-mobile.svg';
import { NftStepper, NftTitle } from 'components/Nft/NftTransfer';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';

const NftTransfer = () => {
  const history = useHistory();
  const { collectionCriteria, nftBridgeTransaction, setNftBridgeTransaction } = useGlobalContext();
  const { account } = useWallet();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  const [nftBridgeSteps, setNftBridgeSteps] = useState<NFTBridgeSteps>({
    next: true,
    approve: false,
    transfer: false,
    processing: false,
  });
  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const [wasTransactionCleared, setWasTransactionCleared] = useState<boolean>(false);
  const [wasTransferSuccessful, setWasTransferSuccessful] = useState<boolean>(false);
  const [targetNetwork, setTargetNetwork] = useState<string>('');
  const [firstRendered, setFirstRendered] = useState<boolean>(false);

  const clearBridgeTransaction = async () => {
    setNftBridgeTransaction({
      ...nftBridgeTransaction,
      recipient: account as string,
    });
    setWasTransactionCleared(true);
  };

  useEffect(() => {
    const goBackToSelectionPageOnAccountChange = () => {
      if (firstRendered) {
        history.push('/bridge-nft');
      }
    };

    goBackToSelectionPageOnAccountChange();
    setFirstRendered(true);
    // FIXME: This is a hack to avoid the warning of missing dependencies
    // We need to find a better way to do this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (nftBridgeTransaction?.network?.target?.chainName) {
      setTargetNetwork(nftBridgeTransaction?.network?.target?.chainName);
    }
  }, [nftBridgeTransaction?.network?.target]);

  useEffect(() => {
    const goBackToBridgeNftPageIfNftTokensEmpty = () => {
      if (nftBridgeTransaction?.nftTokens?.length < 1) {
        history.push('/bridge-nft');
      }
    };

    goBackToBridgeNftPageIfNftTokensEmpty();
  }, [history, nftBridgeTransaction?.nftTokens?.length]);

  useEffect(() => {
    const goToTheTopOnChangeStep = () => {
      window.scrollTo(0, 0);
    };

    goToTheTopOnChangeStep();
  }, [activeStep]);

  const renderNftTransferPage = () => {
    return (
      <div className="mt-7 mb-5 my-md-7">
        <NftTitle schema={collectionCriteria?.schema} />
        <NftStepper
          activeStep={activeStep}
          wasTransactionCleared={wasTransactionCleared}
          nftBridgeSteps={nftBridgeSteps}
          updateActiveStep={setActiveStep}
          updateNftBridgeSteps={setNftBridgeSteps}
          updateWasTransactionClear={setWasTransactionCleared}
          updateWasTransferSuccessful={setWasTransferSuccessful}
          clearBridgeTransaction={clearBridgeTransaction}
        />
      </div>
    );
  };

  const renderNftTransferSuccessPage = () => {
    return (
      <div className="my-7 mt-md-0 mb-md-7">
        <div className="d-flex justify-content-center mt-8 mt-md-7 mb-5">
          {greaterThan('md') ? (
            <NftTransferSuccessDesktopLogo className="max-width-100 height-100" />
          ) : (
            <NftTransferSuccessMobileLogo className="max-width-100 height-100" />
          )}
        </div>

        <div className="mt-4 mt-md-0 mb-5">
          <div className="text-medium text-bold text-main text-center mb-3 mr-5">
            {t('nftTransferPage:transferSuccess.title')}
          </div>
          <div className="text-center text-normal">
            <Trans
              i18nKey="transferPage:transferSuccess.subtitle"
              components={{ span: <span /> }}
              values={{
                chainName: targetNetwork,
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          <Button
            type="secondary"
            className="my-2 my-md-0 mx-md-2 order-last order-md-first"
            onClick={() => {
              history.push('/bridge-nft/');
              clearBridgeTransaction();
              setWasTransferSuccessful(false);
              setNftBridgeTransaction({
                ...nftBridgeTransaction,
                nftTokens: [],
              });
            }}
          >
            <Icon name="check" size={18} color="ui-border" />
            <span className="ml-2">{t('transferPage:transferSuccess.closeButton')}</span>
          </Button>
          <Button
            type="primary"
            className="my-2 my-md-0 mx-md-2 order-first order-md-last"
            onClick={() => {
              const list = document.querySelectorAll('.accordion-item');

              for (const item in list) {
                if (typeof list[item] === 'object') {
                  if (list[item].classList.contains('active')) {
                    list[item].classList.remove('active');
                  }
                }
              }

              history.push('/bridge-nft/claim');

              clearBridgeTransaction();
              setNftBridgeTransaction({
                ...nftBridgeTransaction,
                nftTokens: [],
              });
            }}
          >
            <span>{t('transferPage:transferSuccess.goToClaimButton')}</span>
            <Icon name="arrow-right-long" size={18} color="ui-border" className="ml-3" />
          </Button>
        </div>
      </div>
    );
  };

  return !wasTransferSuccessful ? renderNftTransferPage() : renderNftTransferSuccessPage();
};

export default NftTransfer;
