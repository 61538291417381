import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Tooltips';

import './NftContainerHeader.scss';

function NftContainerHeader({ schema }: { schema?: string }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center mt-4">
      <div className="text-medium text-bold text-main mb-3 mr-3">
        {t('nftSelectionPage:tokensContainer.title', { schema })}
      </div>
      <InfoTooltip text={t('nftSelectionPage:tokensContainer.tooltip')} size={20} />
    </div>
  );
}

export default NftContainerHeader;
