import React, { useEffect } from 'react';

import { ModalClaimSuccess } from 'components/Modals/ModalClaimSuccess';
import NftClaimedCounter from 'components/Nft/NftClaim/NftClaimedCounter/NftClaimedCounter';
import NftClaimFilter from 'components/Nft/NftClaim/NftClaimFilter/NftClaimFilter';
import NftClaimTitle from 'components/Nft/NftClaim/NftClaimTitle/NftClaimTitle';
import { NftClaimListing } from 'components/Nft/NftClaim/NftClaimWindow/NftClaimListing';
import { getFormattedSdkNftClaims } from 'helpers/NftClaims';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';

import './NftClaim.scss';

const NftClaim = () => {
  const { sdk, currentNetwork, networkOptions, setNftClaims, setIsNftsLoaded } = useGlobalContext();
  const { account } = useWallet();
  const { isEnabled } = useFeatureFlag();
  const [claimsAccount, setClaimsAccount] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const loadNftClaims = async () => {
      if (sdk && (claimsAccount || account)) {
        try {
          const formattedNftClaims = await getFormattedSdkNftClaims(
            sdk,
            (claimsAccount ?? account) as string,
            currentNetwork.chainTargetId,
            networkOptions,
            isEnabled('ERC1155'),
          );
          setNftClaims(Object.values(formattedNftClaims));
          setIsNftsLoaded(false);
        } catch (error) {
          setIsNftsLoaded(false);
          setNftClaims([]);
          console.error('LOAD NFT CLAIMS ERROR', error);
        }
      }
    };
    const interval = setInterval(loadNftClaims, currentNetwork.refreshClaimTimestamp ?? 15000);
    if (sdk) {
      loadNftClaims();
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    account,
    claimsAccount,
    currentNetwork.chainTargetId,
    currentNetwork.refreshClaimTimestamp,
    isEnabled,
    networkOptions,
    sdk,
    setIsNftsLoaded,
    setNftClaims,
  ]);

  return (
    <div className="nft-claim-page-container">
      <ModalClaimSuccess />
      <div className="data-wrapper my-5 my-md-7">
        <NftClaimTitle />
        <div className="nft-claimed-option-wrapper my-4 pb-4 d-flex align-items-sm-end align-items-md-center justify-content-between">
          <NftClaimFilter setClaimsAccount={setClaimsAccount} claimsAccount={claimsAccount} />
          <div className="line mx-5 mb-6 mt-4 d-sm-none"></div>
          <NftClaimedCounter />
          <div className="line mx-5 mt-6 d-sm-none"></div>
        </div>
        <NftClaimListing />
      </div>
    </div>
  );
};

export default NftClaim;
