export const getNetworkIndex = (chainId: number, options: Network[]): number => {
  let networkIndex = options.length - 1;
  for (let index = 0; index < options.length; index++) {
    if (options[index]?.chainId === chainId) {
      networkIndex = index;
      break;
    }
  }
  return networkIndex;
};

export const getNetworkByChainTargetId = (chainTargetId: number, options: Network[]): Network => {
  for (let index = 0; index < options.length; index++) {
    if (options[index]?.chainTargetId === chainTargetId) {
      return options[index];
    }
  }
  return {} as Network;
};
