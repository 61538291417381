import React from 'react';
import { useTranslation } from 'react-i18next';

import './ClaimHeader.scss';
function ClaimHeader() {
  const { t } = useTranslation();

  return (
    <div className="container-claim-header d-flex justify-content-between my-2">
      <div className="source-chain d-flex  flex-direction-reverse">
        <p className="text-tiny text-bold text-border">{t('claimPage:header.sourceChain')}</p>
      </div>
      <div className="d-flex target-chain d-flex flex-grow-1">
        <p className="text-tiny text-bold text-border">{t('claimPage:header.targetChain')}</p>
      </div>
      <div className="d-flex amount-h-wrapper justify-content-between mr-7">
        <div className="d-flex amount">
          <p className="text-tiny text-bold text-border">{t('claimPage:header.amount')}</p>
        </div>
        <div>
          <p className="text-tiny text-bold text-border">{t('claimPage:header.token')}</p>
        </div>
      </div>
      <div className="status-header d-flex">
        <p className="text-tiny text-bold text-border">{t('claimPage:header.status')}</p>
      </div>
    </div>
  );
}

export default ClaimHeader;
