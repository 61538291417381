import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@stichting-allianceblock-foundation/components';
import { InfoTooltip } from 'components/Tooltips';
import { useDefaultFilterOptionData } from 'hooks/useDefaultFilterOption';
import { useGlobalContext } from 'hooks/useGlobalContext';

import './NftFilter.scss';

function NftFilter({ onTokenInputChange, value }: NftFilter) {
  const { currentNetwork, setFilterCriteria } = useGlobalContext();
  const defaultFilterOption = useDefaultFilterOptionData();
  const { t } = useTranslation();

  useEffect(() => {
    setFilterCriteria(defaultFilterOption);
  }, [currentNetwork, defaultFilterOption, setFilterCriteria]);

  return (
    <div className="claimed-option-wrapper d-flex justify-content-between">
      <div className="dropdown-option-wrapper d-flex flex-column">
        <div className="d-flex align-items-center">
          <div className="text-small text-bold text-secondary mb-3 mr-3">
            {t('nftSelectionPage:nftFilterField.title')}
          </div>
          <InfoTooltip text={t('nftSelectionPage:nftFilterField.tooltip')} size={18} />
        </div>
        <TextField
          className="mr-0 mr-md-3 search-input"
          icon={'nav-search'}
          value={value}
          placeholder={t('nftSelectionPage:nftFilterField.placeholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onTokenInputChange(event.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default NftFilter;
