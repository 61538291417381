import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Icon } from '@stichting-allianceblock-foundation/components';
import NftClaimCardButton from 'components/Nft/NftClaimCardButton/NftClaimCardButton';
import { NftDetailsCard } from 'components/Nft/NftDetailsCard';
import { InfoTooltip } from 'components/Tooltips';
import { TOKEN_FEE_DECIMALS } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import { formatCurrency, fromWei } from 'utils';
import { currencySymbols, getCoingeckooIds, getTokenPrice } from 'utils/coingecko';

import NftClaimSuccess from '../NftClaimSucess/NftClaimSuccess';

import './NftClaimCard.scss';

interface NftClaimCardProps {
  location: { state: { entry: FormattedNftClaims } };
}

function NftClaimCard(props: NftClaimCardProps) {
  const { isEnabled } = useFeatureFlag();
  const nftClaim = props.location.state.entry as FormattedNftClaims;
  const { account, chainId } = useWallet();
  const { t } = useTranslation();
  const { sdk, currentNetwork, config } = useGlobalContext();
  const { greaterThan } = useBreakpoint();
  const history = useHistory();

  const isMobile: boolean = greaterThan('sm');
  const [txFeeEstimation, setTxFeeEstimation] = useState<string>('0.0');
  const [txFeeEstimationCurrency, setTxFeeEstimationCurrency] = useState<string>('($ 0.00)');
  const [firstRendered, setFirstRendered] = useState<boolean>(false);
  const [wasClaimSuccessful, setWasClaimSuccessful] = useState<boolean>(false);

  useEffect(() => {
    const loadTxFee = async () => {
      if (sdk) {
        const payableCall = await sdk.deliver(nftClaim?.items[0]);
        const gasEstimation = await payableCall.estimateGas();
        setTxFeeEstimation(fromWei(gasEstimation, currentNetwork?.nativeCurrency?.decimals));
      }
    };
    loadTxFee();
  }, [currentNetwork?.nativeCurrency?.decimals, nftClaim?.items, sdk]);

  useEffect(() => {
    const goBackToNftClaimPageOnAccountChange = () => {
      if (firstRendered) {
        history.goBack();
      }
    };

    goBackToNftClaimPageOnAccountChange();
    setFirstRendered(true);
    // FIXME: This is a hack to avoid the warning of missing dependencies
    // We need to find a better way to do this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, account]);

  useEffect(() => {
    const loadTxFeeEstimationCurrency = async () => {
      if (!['0', '0.0']?.includes(txFeeEstimation)) {
        const coingeckoId = getCoingeckooIds(
          currentNetwork?.nativeCurrency?.symbol?.toLowerCase(),
        )?.id;
        let formattedPrice: string = `(${currencySymbols[config.serviceFeeCurrency]} N/A)`;

        try {
          const price = await getTokenPrice(coingeckoId, config.serviceFeeCurrency);

          formattedPrice = formatCurrency(
            Number(txFeeEstimation) * price,
            TOKEN_FEE_DECIMALS,
            currencySymbols[config.serviceFeeCurrency],
          );
        } catch (err) {
          console.error('loadTxFeeEstimationCurrency Error', err);
        }

        setTxFeeEstimationCurrency(formattedPrice);
      }
    };

    loadTxFeeEstimationCurrency();
  }, [config.serviceFeeCurrency, currentNetwork?.nativeCurrency?.symbol, txFeeEstimation]);

  useEffect(() => {
    const clearStepperAfterNetworkChange = () => {
      if (chainId !== currentNetwork.chainId) {
        history.goBack();
      }
    };

    clearStepperAfterNetworkChange();
  }, [chainId, currentNetwork.chainId, history]);

  const renderNftClaimDetailsPage = () => {
    return (
      <div className="mt-7 mb-5 my-md-7">
        <div className="data-wrapper mb-4">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mb-3">
                <Icon
                  name="arrow-left-long"
                  size={32}
                  color="brand-primary"
                  className="mr-4 d-none d-md-block"
                  onClick={history.goBack}
                />
                <div className="go-back-line-vertical mr-md-2 d-none d-md-block"></div>
                <div className="text-medium text-bold text-main mr-3 mr-md-3 mx-0 mx-md-3">
                  {t('nftClaimDetails:title')}
                </div>
              </div>
              <InfoTooltip
                text={t('nftClaimDetails:tooltip', {
                  context: isEnabled('ERC1155') ? 'with_erc1155' : '',
                })}
                size={20}
              />
            </div>
            <Icon
              name="cross-big"
              size={24}
              color="ui-main"
              className="d-flex justify-content-end d-md-none mb-3"
              onClick={history.goBack}
            />
          </div>
          <p className="text-main">{t('nftClaimDetails:description')}</p>
        </div>

        <div className="nft-image-container">
          <div className="nft-image-wrap mb-6 py-6 px-4 flex-column d-flex text-align-center flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftClaimDetails:nftTokensField.title')}
              </div>
              <InfoTooltip
                text={t('nftClaimDetails:nftTokensField.tooltip', {
                  context: isEnabled('ERC1155') ? 'with_erc1155' : '',
                })}
                size={18}
              />
            </div>
            <div className="d-flex flex-wrap">
              {nftClaim?.items?.map((nftClaim_: NftClaims, index: number) => (
                <NftDetailsCard
                  className="mr-0 mr-md-4"
                  item={nftClaim_}
                  key={index}
                  onlyImage={nftClaim.items?.length > 1 && greaterThan('md')}
                />
              ))}
            </div>
          </div>

          <div className="est-transaction py-6 px-4">
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('nftClaimDetails:transactionFeesField.title')}
              </div>
              <InfoTooltip text={t('nftClaimDetails:transactionFeesField.tooltip')} size={18} />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mt-2 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('nftClaimDetails:transactionFeesField.estimationFeesText')}
                  </span>
                </div>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                <span className="text-main text-bold mr-2 mr-sm-4">{txFeeEstimation}</span>
                <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex mt-3 mt-md-0">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">{txFeeEstimationCurrency}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2 mt-7 mb-8" style={isMobile ? { float: 'right' } : undefined}>
            <NftClaimCardButton
              disabled={false}
              status={''}
              entry={nftClaim}
              updateClaimSuccess={setWasClaimSuccessful}
            />
          </div>
        </div>
      </div>
    );
  };

  return !wasClaimSuccessful ? renderNftClaimDetailsPage() : <NftClaimSuccess />;
}

export default NftClaimCard;
