import React from 'react';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { Select } from 'components/Select';
import { WalletButton } from 'components/WalletButton';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useNetworkSelected } from 'hooks/useNetworkSelect';
import useWallet from 'hooks/useWallet';

import './Header.scss';

const Header = () => {
  const { bridgeOperationDisabled, setIsSideMenuOpen } = useGlobalContext();

  const { changeNetwork } = useWallet();
  const { options, networkIndex } = useNetworkSelected();
  const hiddenOption = (option: Network | UnsupportedNetwork) => option.chainId !== -1;

  return (
    <div className="container-header">
      <div className="d-flex justify-content-end">
        <a className="link-primary d-lg-none mr-auto d-flex justify-content-center align-items-center cursor-pointer">
          <Icon name="menu" size={32} color="ui-main" onClick={() => setIsSideMenuOpen(true)} />
        </a>
        <Select
          disabled={bridgeOperationDisabled}
          className="network-select-component ml-4 mr-3"
          optionsList={options}
          displayFields={{
            primary: 'chainName',
            secondary: '',
            icon: 'chainIcon',
          }}
          size="sm"
          selected={networkIndex}
          onSelectOptionChange={network => changeNetwork(network)}
          hiddenOption={hiddenOption}
        />
        <WalletButton />
      </div>
    </div>
  );
};

export default Header;
