import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '@stichting-allianceblock-foundation/components';

import './Accordion.scss';

const Accordion = ({ title, children, icon, expanded }: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(expanded ?? false);

  const contentRef = useRef<HTMLUListElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleClickOutside(event: Event) {
    let itemContainsLayout: boolean = false;
    let itemContainsContainerAccordion: boolean = false;
    let itemContainsAccordion: boolean = false;

    event.composedPath().forEach(node => {
      if ((node as HTMLElement).classList !== undefined) {
        if ((node as HTMLElement).classList.contains('layout-content')) {
          itemContainsLayout = true;
        }

        if (
          (node as HTMLElement).classList.contains('sidemenu-content') ||
          (node as HTMLElement).classList.contains('sidebar-container')
        ) {
          itemContainsContainerAccordion = true;
        }

        if ((node as HTMLElement).classList.contains('accordion-section')) {
          itemContainsAccordion = true;
        }
      }
    });

    if (
      !containerRef.current?.contains(event.target as Node) &&
      !itemContainsLayout! &&
      itemContainsContainerAccordion! &&
      itemContainsAccordion!
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = open ? `${contentRef.current.scrollHeight}px` : '0px';
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div ref={containerRef} className="accordion-section">
      <a className="accordion-title justify-content-between" onClick={() => setOpen(!open)}>
        {icon && <Icon name={icon} size={24} color="ui-main" className="mr-3" />}
        <span className="accordion-title__item">{title}</span>
        <Icon
          className={`${open ? 'arrow' : 'arrow rotated'} mx-3`}
          name="chevron-down"
          size={16}
          color="ui-secondary"
        />
      </a>
      <ul ref={contentRef} className="accordion-content">
        {children}
      </ul>
    </div>
  );
};

export default React.memo(Accordion);
