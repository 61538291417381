import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ERC721RouterSDK, ERC1155RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Stepper } from '@stichting-allianceblock-foundation/components';
import { TOKEN_FEE_DECIMALS } from 'configs/constants';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import {
  formatCurrency,
  fromWei,
  getBlockExplorerNameByChainId,
  getModuleBySchemaName,
  hexlifyAddress,
  NftTokenStatus,
  TxStatus,
} from 'utils';
import { currencySymbols, getCoingeckooIds, getTokenPrice } from 'utils/coingecko';

import { NftStepButtons, NftStepDetails, NftStepReview } from './NftSteps';

interface StepperBridgeProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  nftBridgeSteps: NFTBridgeSteps;
  updateActiveStep: React.Dispatch<React.SetStateAction<0 | 1>>;
  updateNftBridgeSteps: React.Dispatch<React.SetStateAction<NFTBridgeSteps>>;
  updateWasTransactionClear: React.Dispatch<React.SetStateAction<boolean>>;
  updateWasTransferSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
  clearBridgeTransaction: () => Promise<void>;
}

const StepperBridge = ({
  activeStep,
  wasTransactionCleared,
  nftBridgeSteps,
  updateActiveStep,
  updateNftBridgeSteps,
  updateWasTransactionClear,
  updateWasTransferSuccessful,
  clearBridgeTransaction,
}: StepperBridgeProps) => {
  const history = useHistory();
  const {
    collectionCriteria,
    nftBridgeTransaction,
    serviceFeeOptions,
    currentNetwork,
    sdk,
    config,
  } = useGlobalContext();
  const { account, chainId, library } = useWallet();

  const [bridgeFeeEstimation, setBridgeFeeEstimation] = useState<string>('0.0');
  const [bridgeFeeEstimationCurrency, setBridgeFeeEstimationCurrency] =
    useState<string>('($ 0.00)');

  const [txFeeEstimation, setTxFeeEstimation] = useState<string>('0.0');
  const [txFeeEstimationCurrency, setTxFeeEstimationCurrency] = useState<string>('($ 0.00)');
  const [nftTokensStatus, setNftTokensStatus] = useState<NftTokenStatus>(NftTokenStatus.Checking);

  type RouterSDKType = typeof ERC721RouterSDK | typeof ERC1155RouterSDK;
  const module = getModuleBySchemaName(collectionCriteria?.schema);

  useEffect(() => {
    const resetTransactionCleared = () => {
      if (
        nftBridgeTransaction?.network?.target?.chainId ||
        nftBridgeTransaction?.recipient !== account ||
        serviceFeeOptions[0]?.address
      ) {
        setTxFeeEstimation('0.0');
        setTxFeeEstimationCurrency('($ 0.00)');
        updateWasTransactionClear(false);
      }
    };

    resetTransactionCleared();
  }, [
    account,
    nftBridgeTransaction?.network?.target?.chainId,
    nftBridgeTransaction?.recipient,
    serviceFeeOptions,
    updateWasTransactionClear,
  ]);

  useEffect(() => {
    const clearStepperAndGoBackOnNetworkChange = () => {
      if (chainId !== currentNetwork.chainId) {
        updateActiveStep(0);
        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            next: true,
          };
        });
        clearBridgeTransaction();
        history.goBack();
      }
    };

    clearStepperAndGoBackOnNetworkChange();
  }, [
    chainId,
    clearBridgeTransaction,
    currentNetwork.chainId,
    history,
    updateActiveStep,
    updateNftBridgeSteps,
  ]);

  useEffect(() => {
    const loadTxFeeEstimationCurrency = async () => {
      if (!['0', '0.0']?.includes(txFeeEstimation)) {
        const coingeckoId = getCoingeckooIds(
          currentNetwork?.nativeCurrency?.symbol?.toLowerCase(),
        )?.id;

        let formattedPrice: string = `(${currencySymbols[config.serviceFeeCurrency]} N/A)`;

        try {
          const price = await getTokenPrice(coingeckoId, config.serviceFeeCurrency);

          formattedPrice = formatCurrency(
            Number(txFeeEstimation) * price,
            TOKEN_FEE_DECIMALS,
            currencySymbols[config.serviceFeeCurrency],
          );
        } catch (err) {
          console.error(err);
        }

        setTxFeeEstimationCurrency(formattedPrice);
      }
    };

    loadTxFeeEstimationCurrency();
  }, [config.serviceFeeCurrency, currentNetwork?.nativeCurrency?.symbol, txFeeEstimation]);

  useEffect(() => {
    const estimateGasFee = async () => {
      if (sdk && account && module) {
        const gasPrice = await library?.getGasPrice();
        const fee = await sdk.dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME).getServiceFee({
          targetChainId: nftBridgeTransaction.network.target.chainTargetId,
          assets: nftBridgeTransaction.nftTokens,
          userAddress: hexlifyAddress(
            nftBridgeTransaction.recipient,
            nftBridgeTransaction.network.target.walletType,
          ),
        });
        const isApproved = await sdk
          .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
          .isNftsApproved(account, nftBridgeTransaction.nftTokens[0].asset_contract.address);
        setNftTokensStatus(isApproved ? NftTokenStatus.Transfer : NftTokenStatus.Approve);
        setBridgeFeeEstimation(fromWei(fee));
        setBridgeFeeEstimationCurrency(
          formatCurrency(
            Number(bridgeFeeEstimation),
            TOKEN_FEE_DECIMALS,
            currencySymbols[config.serviceFeeCurrency],
          ),
        );

        if (!isApproved) {
          const payableCall = await sdk
            .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
            .approveNfts(nftBridgeTransaction.nftTokens[0].asset_contract.address);
          const estimatedGas = await payableCall.estimateGas();

          const result = estimatedGas.mul(gasPrice);
          setTxFeeEstimation(fromWei(result, currentNetwork?.nativeCurrency?.decimals));
        } else if (nftBridgeTransaction.recipient) {
          const recipient = hexlifyAddress(
            nftBridgeTransaction.recipient,
            nftBridgeTransaction.network.target.walletType,
          );

          const payableCall = await sdk
            .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
            .sendNftTokens({
              targetChainId: nftBridgeTransaction.network.target.chainTargetId,
              assets: nftBridgeTransaction.nftTokens,
              userAddress: recipient,
              fee,
            });
          const estimatedGas = await payableCall.estimateGas();
          const result = estimatedGas.mul(gasPrice);

          setTxFeeEstimation(fromWei(result, currentNetwork?.nativeCurrency?.decimals));
        }
      }
    };

    if (sdk && account && nftBridgeTransaction.network.target.chainTargetId) {
      setNftTokensStatus(NftTokenStatus.Checking);
      estimateGasFee();
    }
  }, [
    account,
    bridgeFeeEstimation,
    config.serviceFeeCurrency,
    currentNetwork?.nativeCurrency?.decimals,
    library,
    module,
    nftBridgeTransaction.network.target.chainTargetId,
    nftBridgeTransaction.network.target.walletType,
    nftBridgeTransaction.nftTokens,
    nftBridgeTransaction.recipient,
    sdk,
  ]);

  const approveNFTsTokens = async () => {
    if (sdk) {
      try {
        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            approve: false,
            processing: true,
          };
        });

        const payableCall = await sdk
          .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
          .approveNfts(nftBridgeTransaction.nftTokens[0].asset_contract.address);

        const contractTransaction = await payableCall.sendTransaction();

        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            approve: false,
            processing: true,
            blockExplorer: {
              name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
              hash: contractTransaction.hash,
              blockExplorerUrl: currentNetwork?.blockExplorerUrl,
              queryParams: currentNetwork?.blockExplorerQueryParams,
            },
          };
        });

        const receiptTxWait = await contractTransaction.wait();
        if (receiptTxWait.status === TxStatus.READY) {
          setNftTokensStatus(NftTokenStatus.Transfer);
          updateNftBridgeSteps(prevNftBridgeSteps => {
            return {
              ...prevNftBridgeSteps,
              approve: false,
              transfer: true,
              processing: false,
            };
          });
        } else {
          updateNftBridgeSteps(prevNftBridgeSteps => {
            return {
              ...prevNftBridgeSteps,
              approve: true,
              processing: false,
            };
          });
        }
      } catch (e) {
        console.error(e);
        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            approve: true,
            processing: false,
          };
        });
      }
    }
  };

  const sendNFTToken = async () => {
    if (sdk) {
      try {
        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            transfer: false,
            processing: true,
          };
        });

        const recipient = hexlifyAddress(
          nftBridgeTransaction.recipient,
          nftBridgeTransaction.network.target.walletType,
        );

        const fee = await sdk.dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME).getServiceFee({
          targetChainId: nftBridgeTransaction.network.target.chainTargetId,
          assets: nftBridgeTransaction.nftTokens,
          userAddress: recipient,
        });

        const payableCall = await sdk
          .dapp<InstanceType<RouterSDKType>>(module.DAPP_NAME)
          .sendNftTokens({
            targetChainId: nftBridgeTransaction.network.target.chainTargetId,
            assets: nftBridgeTransaction.nftTokens,
            userAddress: recipient,
            fee,
          });

        const contractTransaction = await payableCall.sendTransaction();

        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            transfer: false,
            processing: true,
            blockExplorer: {
              name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
              hash: contractTransaction.hash,
              blockExplorerUrl: currentNetwork?.blockExplorerUrl,
              queryParams: currentNetwork?.blockExplorerQueryParams,
            },
          };
        });

        const receiptTxWait = await contractTransaction.wait();

        if (receiptTxWait.status === TxStatus.READY) {
          updateWasTransferSuccessful(true);
          updateNftBridgeSteps(prevNftBridgeSteps => {
            return {
              ...prevNftBridgeSteps,
              transfer: false,
              processing: false,
            };
          });
        } else {
          updateNftBridgeSteps(prevNftBridgeSteps => {
            return {
              ...prevNftBridgeSteps,
              transfer: true,
              processing: false,
            };
          });
        }
      } catch (err) {
        console.error(err);
        updateNftBridgeSteps(prevNftBridgeSteps => {
          return {
            ...prevNftBridgeSteps,
            transfer: true,
            processing: false,
          };
        });
      }
    }
  };
  return (
    <div className="my-4">
      <Stepper>
        <NftStepDetails
          activeStep={activeStep}
          wasTransactionCleared={wasTransactionCleared}
          txFeeEstimation={txFeeEstimation}
          txFeeEstimationCurrency={txFeeEstimationCurrency}
          nftTokensStatus={nftTokensStatus}
          bridgeFee={bridgeFeeEstimation}
          bridgeFeeCurrency={bridgeFeeEstimationCurrency}
          schema={collectionCriteria?.schema}
        />
        <NftStepReview
          activeStep={activeStep}
          txFeeEstimation={txFeeEstimation}
          txFeeEstimationCurrency={txFeeEstimationCurrency}
          nftTokensStatus={nftTokensStatus}
          bridgeFee={bridgeFeeEstimation}
          bridgeFeeCurrency={bridgeFeeEstimationCurrency}
          schema={collectionCriteria?.schema}
        />
        <NftStepButtons
          nftBridgeSteps={nftBridgeSteps}
          nftTokensStatus={nftTokensStatus}
          updateNftBridgeSteps={updateNftBridgeSteps}
          updateActiveStep={updateActiveStep}
          clearBridgeTransaction={clearBridgeTransaction}
          approveNFTsTokens={approveNFTsTokens}
          sendNFTToken={sendNFTToken}
          schema={collectionCriteria?.schema}
        />
      </Stepper>
    </div>
  );
};

export default StepperBridge;
