import { PublicKey } from '@solana/web3.js';
import { ERC721RouterSDK, ERC1155RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { NETWORK_CONFIG } from 'configs';
import { TOKEN_SLIDER_PERCENTAGE_DECIMALS, WALLET_TYPE } from 'configs/constants';
import { ethers } from 'ethers';

import { isSolanaAddress } from './solana';

export const toHex = (number: number): string => {
  return ethers.utils.hexStripZeros(ethers.utils.hexlify(number));
};

export const isAddress = (
  address: string,
  walletType: string = WALLET_TYPE.METAMASK,
  limitOnCurve: boolean = false,
): boolean => {
  if (address === '' || !address) {
    return false;
  }
  if (walletType === WALLET_TYPE.METAMASK) {
    return ethers.utils.isAddress(address);
  } else if (walletType === WALLET_TYPE.PHANTOM) {
    return isSolanaAddress(address, limitOnCurve);
  }
  return false;
};

const defaultAddressMap = {
  [WALLET_TYPE.METAMASK]: ethers.constants.AddressZero,
  [WALLET_TYPE.PHANTOM]: PublicKey.default.toString(),
};

export const getDefaultAddress = (walletType: string = WALLET_TYPE.METAMASK) =>
  defaultAddressMap[walletType];

const defaultAddressCheckMap = {
  [WALLET_TYPE.METAMASK]: (address: string) => address === ethers.constants.AddressZero,
  [WALLET_TYPE.PHANTOM]: (address: string) =>
    ethers.utils.isHexString(address)
      ? address === ethers.utils.hexlify(PublicKey.default.toBytes())
      : address === PublicKey.default.toString(),
};

export const isDefaultAddressInSomeNetwork = (address: string) =>
  Object.values(defaultAddressCheckMap).some(defaultAddressCheck => defaultAddressCheck(address));

export const hexlifyAddress = (
  address: string,
  walletType: string = WALLET_TYPE.METAMASK,
): string => {
  if (walletType === WALLET_TYPE.METAMASK) {
    return ethers.utils.hexlify(address);
  } else if (walletType === WALLET_TYPE.PHANTOM) {
    return ethers.utils.hexlify(new PublicKey(address).toBytes());
  }
  throw new Error('Wallet type not implemented yet');
};

export const toWei = (value: string, decimals?: number): ethers.BigNumber =>
  ethers.utils.parseUnits(value, decimals);

export const fromWei = (value: ethers.BigNumberish, decimals?: number): string =>
  ethers.utils.formatUnits(value, decimals);

export const formatBalance = (value: string): string => {
  const floatValue = parseFloat(value);
  const locale = window.navigator.language;
  if (floatValue === 0) {
    return '0';
  } else if (floatValue < 0.0001) {
    const numberFormat = new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
    });
    return `< ${numberFormat.format(0.0001)}`;
  } else if (floatValue < 10) {
    const numberFormat = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
      maximumSignificantDigits: 4,
      notation: 'compact',
      compactDisplay: 'short',
    });
    return numberFormat.format(floatValue);
  } else {
    const numberFormat = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      notation: 'compact',
      compactDisplay: 'short',
    });
    return numberFormat.format(floatValue);
  }
};

export const toBN = (value: string): ethers.BigNumber => ethers.BigNumber.from(value);

export const calculateTokenAmountByPercentage = (
  percentage: string,
  totalAmount: string,
  decimals: number,
): string => {
  const amount = toWei(percentage, decimals)
    .mul(toWei(totalAmount, decimals))
    .div(toWei('1', decimals + TOKEN_SLIDER_PERCENTAGE_DECIMALS));

  const amountResult = fromWei(amount.toString(), decimals);

  return amountResult;
};

export const calculateTokenPercentageByAmount = (
  amount: string,
  totalAmount: string,
  decimals: number,
): number => {
  if (Number(totalAmount) <= 0) {
    return 0;
  }

  let amountBN;
  try {
    amountBN = toWei(amount, decimals);
  } catch (err) {
    amountBN = toBN('0');
  }

  let percentage: ethers.BigNumber;
  if (amountBN.gte(0) && amountBN.lte(toWei(totalAmount, decimals))) {
    percentage = amountBN.mul(toWei('100', decimals)).div(toWei(totalAmount, decimals));
  } else {
    percentage = toBN('0');
  }

  return Number(Number(fromWei(percentage, decimals)).toFixed(TOKEN_SLIDER_PERCENTAGE_DECIMALS));
};

export const getTokenIcon = (
  tokenDetails: Pick<TokenDetails, 'name' | 'symbol'> & { decimals?: number },
  prefix: string = 'coin-',
): string => {
  if (!tokenDetails?.name?.includes('Wrapped') && !tokenDetails?.name?.includes('ABTest')) {
    return `${prefix}${tokenDetails?.symbol?.toLowerCase()}`;
  }

  const symbol = tokenDetails?.symbol?.toLowerCase()?.replace(/^w/, '');
  return `${prefix}${symbol}`;
};

export const svgUrlExists = (url: string): boolean => {
  const http = new XMLHttpRequest();
  http.open('GET', url, false);
  http.send();
  return http.responseText?.startsWith('<svg');
};

export const formatCurrency = (value: number, decimals: number, symbol: string): string => {
  if (value < 1 / 10 ** decimals) {
    return '(<$ 0.01)';
  }

  return `(${symbol} ${value.toFixed(decimals)})`;
};

export const formatTokenInput = (value: string, decimals?: number): string => {
  if (value.match(/^(0[1-9]+)/) || value.match(/^0{2,}[1-9]+/)) {
    // to remove any leading zero after a number
    return value.replace(/^[0.]+/, '');
  } else if (value.match(/^0{2,}[\.,]/)) {
    // to remove leading zeroes when has decimal point
    return value.replace(/^0+/, '0');
  } else if (value.match(/^0{2}/)) {
    // to remove zeroes in the beginning
    return value.replace(/^[0.]+/, '0');
  }

  const [integerPart, decimalPart] = value.split('.');
  if (decimals && decimalPart && decimalPart.length > decimals) {
    // If the decimal part exists and its length is greater than the allowed decimals,
    // truncate the decimal part to the allowed length
    return `${integerPart}.${decimalPart.slice(0, decimals)}`;
  }

  // if any check is met, returns the value as is
  return value;
};

export const shortenHash = (hash: string, offset: number): string => {
  const len: number = hash?.length;
  return `${hash?.substring(0, offset)} ... ${hash?.substring(len - offset, len)}`;
};

export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content);
};

export enum TxStatus {
  READY = 1,
  NOT_READY = 0,
}

export const getBlockExplorerNameByChainId = (chainId: number | undefined): string => {
  switch (chainId) {
    case 1:
    case 4:
      return 'Etherscan';
    case 56:
    case 97:
      return 'BSCscan';
    case 137:
    case 80001:
      return 'Polygonscan';
    case 43113:
    case 43114:
      return 'Snowtrace';
    case 246:
    case 73799:
      return 'EnergyWeb Explorer';
    case 42161:
    case 421611:
      return 'Arbiscan';
    case 69:
      return 'Optimistic Etherscan';
    case 1287:
      return 'Moonscan';
    case 1313161555:
      return 'Aurora Explorer';
    case 4002:
      return 'FTMScan';
    case -1:
      return 'Solana Explorer';
    default:
      return 'Etherscan';
  }
};

export enum NftTokenStatus {
  Checking,
  Approve,
  Transfer,
}

export const open = (url: string) => {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
};

export const getModuleBySchemaName = (
  schemaName?: string,
): typeof ERC721RouterSDK | typeof ERC1155RouterSDK => {
  const modules = {
    ERC721: ERC721RouterSDK,
    ERC1155: ERC1155RouterSDK,
  };

  return modules[schemaName as keyof typeof modules];
};

export const getProtocolsByNetwork = (chainId: number, asset: string): DEXProtocol[] | null => {
  switch (chainId) {
    case 1:
      return [
        {
          name: 'Uniswap',
          icon: 'uniswap',
          url: `https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=${asset}`,
        },
        {
          name: 'Balancer',
          icon: 'balancer',
          url: 'https://app.balancer.fi/#/trade',
        },
      ];
    case 56:
      return [
        {
          name: 'PancakeSwap',
          icon: 'pancakeswap',
          url: `https://pancakeswap.finance/swap?outputCurrency=${asset}`,
        },
      ];
    case 43114:
      return [
        {
          name: 'Pangolin',
          icon: 'pangolin',
          url: `https://app.pangolin.exchange/#/swap?outputCurrency=${asset}`,
        },
      ];
    case 246:
      return [
        {
          name: 'AllianceBlock DEX',
          icon: 'albt',
          url: 'https://abdex.io/swap',
        },
      ];
    default:
      return null;
  }
};

export const isProtocolAvailable = (chainId: number): boolean => {
  return getProtocolsByNetwork(chainId, '') !== null;
};

export const getTokenAddressesByChainId = (chainId: number): Map<string, TokenConfiguration> => {
  const tokenAddresses = Object.values(NETWORK_CONFIG)?.filter(
    (item: NetworkConfigValues) => item?.network?.chainId === chainId,
  )[0].tokenAddresses;
  return new Map<string, TokenConfiguration>(
    Object.values(tokenAddresses).map(obj =>
      typeof obj === 'string' ? [obj, { address: obj }] : [obj.address, obj],
    ),
  );
};

export const getNetworkByChainId = (chainId: number): Network => {
  return Object.values(NETWORK_CONFIG)?.filter(
    (item: NetworkConfigValues) => item?.network?.chainId === chainId,
  )[0].network;
};

export const getBlackListByChainId = (
  chainId: number,
): {
  chainId: number;
  tokenAddress: string;
}[] => {
  const blackList =
    Object.values(NETWORK_CONFIG)?.find(
      (item: NetworkConfigValues) => item?.network?.chainId === chainId,
    )?.tokensBlacklist || [];

  return blackList.map(tokenAddress => ({
    chainId: chainId,
    tokenAddress,
  }));
};

// Using 'any' type here as an exception due to the nature of the function.
/* eslint-disable @typescript-eslint/no-explicit-any */
export const overrideJson = (
  baseJson: { [key: string]: any },
  override: { [key: string]: any },
): { [key: string]: any } => {
  // Check if the baseJson is not null or undefined
  if (!baseJson) {
    return override;
  }

  // Check if the overrideJson is not null or undefined
  if (!override) {
    return baseJson;
  }

  // Recursively merge the objects
  for (const key in override) {
    if (typeof override[key] === 'object') {
      baseJson[key] = overrideJson(baseJson[key], override[key]);
    } else {
      baseJson[key] = override[key];
    }
  }

  return baseJson;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
