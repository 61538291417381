import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Icon, LabelButton } from '@stichting-allianceblock-foundation/components';

import './NftClaimButton.scss';

function NftClaimButton({
  disabled,
  status,
  entry,
}: {
  disabled: boolean;
  status: string;
  entry: FormattedNftClaims;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [buttonStatus, setButtonStatus] = useState<string>(status);

  useEffect(() => {
    const parseNFTsAndCheckStatus = async () => {
      let nftClaimedTokens = sessionStorage.getItem('nft-claims-in-process');

      if (typeof nftClaimedTokens === 'string') {
        nftClaimedTokens = JSON.parse(nftClaimedTokens);
      }
      if (nftClaimedTokens?.includes(entry.items[0].transmissionId)) {
        setButtonStatus(t('nftClaimPage:buttonStatus.claimed'));
      } else {
        setButtonStatus(status);
      }
    };

    parseNFTsAndCheckStatus();
  }, [entry.items, status, t]);

  const handleClick = () => {
    if (entry.items[0].transmissionType === 'ccip') {
      window.open(`https://ccip.chain.link/msg/${entry.items[0].transmissionId}`, '_blank');
    } else {
      history.push({
        pathname: `/bridge-nft/claim-nft-card/${entry.items[0].transmissionId}`,
        state: { entry },
      });
    }
  };

  const claimButton = (
    <Button
      disabled={disabled || status === t('nftClaimPage:buttonStatus.claimed')}
      type="secondary"
      size="sm"
      className="edit-button"
      onClick={handleClick}
    >
      <div className="d-flex mr-2">
        <Icon
          name={
            buttonStatus === t('nftClaimPage:buttonStatus.claimed') ? 'edit-finalize' : 'edit-claim'
          }
          color={'brand-primary'}
          size={16}
        />
      </div>

      <div className="claim-text">
        {buttonStatus === t('claimPage:buttonStatus.claimed')
          ? buttonStatus
          : t('claimPage:buttonStatus.claim')}
      </div>
    </Button>
  );

  const autoClaiming = (
    <div className="autoclaiming d-flex align-items-center">
      <LabelButton
        className="mr-2"
        label=""
        size="sm"
        type="primary"
        loading={true}
        loadingText="Autoclaiming"
      />
      <Button
        disabled={disabled || buttonStatus === t('claimPage:buttonStatus.claimed')}
        type="secondary"
        size="sm"
        className="edit-button"
      >
        <div className="d-flex mr-2">
          <Icon
            name={
              buttonStatus === t('claimPage:buttonStatus.claimed') ? 'edit-finalize' : 'edit-claim'
            }
            color={'brand-primary'}
            size={16}
          />
        </div>
      </Button>
    </div>
  );

  if (entry.items[0].transmissionType === 'ccip' && !entry.delivered[0] && !entry.failed) {
    return autoClaiming;
  }
  return claimButton;
}

export default NftClaimButton;
