import React, { useMemo } from 'react';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { ClipboardTooltip } from 'components/Tooltips/ClipboardTooltip';
import { WALLET_TYPE } from 'configs/constants';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { shortenHash } from 'utils';
import { toSolanaAddress } from 'utils/solana';

import './BlockExplorerBadge.scss';

interface BlockExplorerBadgeProps {
  title?: string | JSX.Element;
  hash: string;
  blockExplorer: string;
  type: 'address' | 'tx';
  shorten?: boolean;
  queryParams?: string;
  walletType?: string;
}

const BlockExplorerBadge = ({
  title,
  hash,
  blockExplorer,
  type,
  shorten,
  queryParams,
  walletType,
}: BlockExplorerBadgeProps) => {
  const { lessThan } = useBreakpoint();
  const ADDRESS_OFFSET: number = 5;

  const formattedHash = useMemo(() => {
    return walletType === WALLET_TYPE.PHANTOM && type === 'address' ? toSolanaAddress(hash) : hash;
  }, [walletType, type, hash]);

  const generateBlockExplorerLink = (): string => {
    return `${blockExplorer}/${type}/${formattedHash}${queryParams || ''}`;
  };

  const generateTitle = (): string | JSX.Element => {
    const formatedTitle = title
      ? title
      : shorten
      ? shortenHash(formattedHash, lessThan('sm') ? ADDRESS_OFFSET * 2 : ADDRESS_OFFSET)
      : formattedHash;
    return formatedTitle;
  };

  return (
    <div className="d-flex">
      <span className="text-secondary mx-2">{generateTitle()}</span>
      <ClipboardTooltip
        className="d-flex align-items-center mx-2 cursor-pointer"
        content={generateBlockExplorerLink()}
      >
        <Icon size={lessThan('sm') ? 20 : 14} name="edit-copy" color="ui-border" />
      </ClipboardTooltip>
      <a
        className="d-flex align-items-center ml-1"
        href={generateBlockExplorerLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon size={lessThan('sm') ? 20 : 14} name="link-external-link" color="ui-border" />
      </a>
    </div>
  );
};

export default BlockExplorerBadge;
