import { useCallback, useState } from 'react';

export const useFeatureFlag = () => {
  // Leave this variable here so consumers are ready in case we need some asynchronous logic in the future
  const [loading] = useState(false);

  const isEnabled = useCallback((feature: string): boolean => {
    // Get the feature flag from environment variable
    const env_feature_flag = process.env['REACT_APP_FEATURE_FLAG_' + feature.toUpperCase()];

    if (!env_feature_flag) return true;
    return env_feature_flag === 'true';
  }, []);

  return { isEnabled, loading };
};
