import React from 'react';

import './base.scss';

interface SkeletonCircleProps {
  size: number;
  className?: string;
}

const SkeletonCircle = ({ size, className }: SkeletonCircleProps) => {
  return (
    <div
      className={`skeleton circle ${className ?? ''}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    ></div>
  );
};

export default SkeletonCircle;
