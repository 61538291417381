import { AnchorProvider } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { toBN } from 'utils';

export const getSolBalance = async (provider: AnchorProvider) => {
  return toBN((await provider.connection.getBalance(provider.publicKey)).toString());
};

export const isSolanaAddress = (address: string, limitOnCurve = false) => {
  try {
    const pubkey = new PublicKey(address);
    const isSolana = limitOnCurve ? PublicKey.isOnCurve(pubkey.toBuffer()) : true;
    return isSolana;
  } catch (err) {
    // If an error occurred is because the address was not valid
    return false;
  }
};

export const toSolanaAddress = (address: string) => {
  if (address.startsWith('0x')) {
    // Buffer needs the string without 0x
    const hexWithoutZero = address.startsWith('0x') ? address.slice(2) : address;

    if (hexWithoutZero.length === 64) {
      // 32 bytes hex string
      const buff = Buffer.from(hexWithoutZero, 'hex');
      const pubkey = new PublicKey(buff);
      // convert it to Solana format
      return pubkey.toBase58();
    }
    // If not a hex 32
    console.warn('Unable to convert hex string to solana address', address);
    return address;
  }

  // Not a hex string
  return address;
};
