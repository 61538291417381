import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  ERC721RouterSDK,
  ERC1155RouterSDK,
  utils,
} from '@stichting-allianceblock-foundation/abridge-sdk';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { SkeletonBlock, SkeletonSquare } from 'components/Skeletons';
import { SkeletonNftPreview } from 'components/Skeletons/SkeletonNftPreview';
import { InfoTooltip } from 'components/Tooltips';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import { shortenHash } from 'utils';

import './NftPreview.scss';

function NftPreview() {
  const { isEnabled } = useFeatureFlag();
  const history = useHistory();
  const { account, type } = useWallet();
  const { address, tokenId } = useParams<NftPreviewParams>();
  const { currentNetwork, sdk } = useGlobalContext();
  const { lessThan, greaterThan, width } = useBreakpoint();
  const { t } = useTranslation();
  const [asset, setAsset] = useState<NftDetails>();
  const [showMetadata, setShowMetadata] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(true);
  const [loadingImage, setLoadingImage] = useState<boolean>(true);
  const defaultNFT = '../images/default-nft.png';

  const nftWrapSize: number = 391;
  useEffect(() => {
    const loadNFTCollections = async () => {
      if (address && account && sdk && tokenId) {
        type RouterSDKType = typeof ERC721RouterSDK | typeof ERC1155RouterSDK;
        const module: RouterSDKType = await sdk.getModuleByCollectionAddress(address);

        if (!isEnabled('ERC1155') && module?.DAPP_NAME === 'ERC1155') {
          setLoadingDetails(false);
          return;
        }

        const token = await sdk
          .dapp<InstanceType<RouterSDKType>>(module?.DAPP_NAME)
          .loadTokenByAddressAndId(address, tokenId, account);

        if (token) token.schema = module?.DAPP_NAME;
        setAsset(token);
        setLoadingDetails(false);
      }
    };

    loadNFTCollections();
  }, [sdk, account, address, tokenId, type, isEnabled]);

  useEffect(() => {
    if (greaterThan('md')) {
      setShowMetadata(false);
    }
  }, [greaterThan, width]);

  return (
    <div className="nft-page-container mt-7 mb-5 my-md-7">
      <div className="nft-data-wrapper">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mb-3">
              <Icon
                name="arrow-left-long"
                size={32}
                color="brand-primary"
                className="mr-4 d-none d-md-block"
                onClick={history.goBack}
              />
              <div className="go-back-line-vertical mr-md-2 d-none d-md-block"></div>
              <div className="text-medium text-bold text-main mr-3 mr-md-3 mx-0 mx-md-3">
                {t('nftPreviewPage:title', { schema: asset?.schema })}
              </div>
            </div>
            <InfoTooltip
              text={t('nftPreviewPage:titleTooltip', { schema: asset?.schema })}
              size={20}
            />
          </div>
          <Icon
            name="cross-big"
            size={24}
            color="ui-main"
            className="d-flex justify-content-end d-md-none mb-3"
            onClick={history.goBack}
          />
        </div>
        {greaterThan('md') && (
          <div className="text-main">
            <Trans
              i18nKey="nftPreviewPage:description"
              components={{ span: <span /> }}
              values={{ schema: asset?.schema }}
            />
          </div>
        )}
        <div className="preview-nft-container d-flex flex-column flex-md-row justify-content-md-between mt-4 mt-md-6 py-0 py-md-5 px-md-4">
          {!showMetadata && (
            <div className="nft-info-container">
              {!loadingDetails ? (
                <>
                  <div className="image-container d-flex justify-content-center">
                    {loadingImage && (
                      <SkeletonSquare className="mb-4" size={nftWrapSize} fullWidth rounded />
                    )}
                    <img
                      src={utils.uriToHttp(asset?.image) || defaultNFT}
                      alt={asset?.name}
                      style={{
                        maxWidth: `100%`,
                        maxHeight: `241px`,
                      }}
                      onLoad={() => setLoadingImage(false)}
                    />
                  </div>
                  <div className="info-container">
                    <div className="name mt-5 mb-5">{asset?.name}</div>
                    {!asset?.image && (
                      <div className="id-contract">
                        <div className="id-container d-flex align-items-center ml-2 pb-2">
                          <p className="warn pt-1 pb-1 pl-3 pr-3">
                            {t('nftSelectionPage:warnNftImageNotWorking')}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="id-contract d-flex justify-content-between align-items-center mt-3 mb-5">
                      <div className="id-container d-flex align-items-center">
                        <p className="id">ID</p>
                        <p className="text-medium text-bold ml-2">{asset?.id}</p>
                      </div>
                      <div className="d-flex flex-column flex-md-row justify-content-between justify-content-md-start">
                        <span className="text-secondary text-center mb-3 mb-md-0">
                          {t('nftPreviewPage:nftInfo.contractAddress')}
                        </span>
                        <BlockExplorerBadge
                          title={
                            <span className="text-main">
                              {shortenHash(
                                asset?.contractAddress || asset?.collectionAddress || '',
                                8,
                              )}
                            </span>
                          }
                          hash={asset?.contractAddress || asset?.collectionAddress || ''}
                          walletType={currentNetwork?.walletType}
                          blockExplorer={currentNetwork?.blockExplorerUrl}
                          queryParams={currentNetwork?.blockExplorerQueryParams}
                          type="address"
                          shorten
                        />
                      </div>
                    </div>
                    {asset?.description && (
                      <>
                        <div className="line mb-5"></div>
                        <p className="text-main">{asset.description}</p>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <SkeletonNftPreview />
              )}
            </div>
          )}
          <div className="line-vertical mx-md-4"></div>
          {((showMetadata && lessThan('md')) || (!showMetadata && greaterThan('md'))) && (
            <div className="nft-metadata-container">
              <div className="d-flex align-items-center mb-2">
                <div className="text-bold text-secondary mb-2 mr-2">
                  {t('nftPreviewPage:nftMetadata.title')}
                </div>
              </div>
              {!loadingDetails ? (
                <div>
                  <pre className="text-secondary metadata-text mr-4">
                    {JSON.stringify(asset, null, 2)}
                  </pre>
                </div>
              ) : (
                <div className="mr-4">
                  <SkeletonBlock className="mb-2" size={4} type="small" />
                  <SkeletonBlock className="mb-2" size={4} type="small" />
                  <SkeletonBlock className="mb-2" size={4} type="small" />
                  <SkeletonBlock className="mb-2" size={4} type="small" />
                  <SkeletonBlock size={2} type="small" />
                </div>
              )}
            </div>
          )}
          {lessThan('md') && (
            <div className="d-flex justify-content-end mt-7">
              <Button
                disabled={loadingDetails}
                type="primary"
                onClick={() => setShowMetadata(!showMetadata)}
              >
                {!showMetadata ? (
                  <>
                    <span>{t('nftPreviewPage:buttons.showMetadata')}</span>
                    <Icon name="chevron-double-right" size={20} color="uiElementSecondary" />
                  </>
                ) : (
                  <>
                    <Icon name="chevron-double-left" size={20} color="uiElementSecondary" />
                    <span>{t('nftPreviewPage:buttons.hideMetadata')}</span>
                  </>
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NftPreview;
