import {
  ERC721RouterSDK,
  ERC1155RouterSDK,
  TeleportSDK,
} from '@stichting-allianceblock-foundation/abridge-sdk';
import { getNetworkByChainTargetId } from 'utils/network';

async function getFormattedSdkNftClaims(
  sdk: TeleportSDK,
  account: string,
  chainId: number,
  networkOptions: Network[],
  isEnableErc1155: boolean,
): Promise<{ [transmissionId: string]: FormattedNftClaims }> {
  const [erc721claims, erc1155claims] = await Promise.all([
    sdk.dapp<ERC721RouterSDK>(ERC721RouterSDK.DAPP_NAME).getNftClaims(chainId, account),
    isEnableErc1155
      ? sdk.dapp<ERC1155RouterSDK>(ERC1155RouterSDK.DAPP_NAME).getNftClaims(chainId, account)
      : Promise.resolve([]),
  ]);

  const formattedNftClaims: { [transmissionId: string]: FormattedNftClaims } = {};

  for (const claim of [...erc721claims, ...erc1155claims]) {
    claim.signatures = claim.signatures?.map((signature: string) => '0x' + signature);
    if (!formattedNftClaims[claim.transmissionId]) {
      formattedNftClaims[claim.transmissionId] = {
        sourceNetwork: getNetworkByChainTargetId(claim.sourceChainId, networkOptions),
        targetNetwork: getNetworkByChainTargetId(claim.targetChainId, networkOptions),
        items: [claim],
        majorityReached: [claim.majorityReached],
        delivered: [claim.delivered],
        schema: claim.schema,
      };
      if (claim.transmissionType === 'ccip') {
        formattedNftClaims[claim.transmissionId].autoClaiming = true;
        formattedNftClaims[claim.transmissionId].failed = claim.delivery?.status === 'FAILURE';
        formattedNftClaims[claim.transmissionId].delivered = [claim.delivery?.status === 'SUCCESS'];
      }
    } else {
      formattedNftClaims[claim.transmissionId].items.push(claim);
      // to check if majority was reached
      // formattedNftClaims[claim.transmissionId].majorityReached.every((e: boolean) => e)
      formattedNftClaims[claim.transmissionId].majorityReached.push(claim.majorityReached);
      formattedNftClaims[claim.transmissionId].delivered.push(claim.delivered);
    }
  }
  return formattedNftClaims;
}

function getNftClaimCounters(nftClaims: FormattedNftClaims[]) {
  let counterClaimed = 0;
  let counterNotClaimed = 0;

  if (nftClaims) {
    nftClaims.map(NftClaim => {
      const isDelivered: boolean = NftClaim?.delivered[0];
      const isMajorityReached: boolean = NftClaim?.majorityReached[0];
      if (isDelivered && isMajorityReached) {
        counterClaimed++;
      } else {
        counterNotClaimed++;
      }
    });
  }
  return { counterClaimed, counterNotClaimed };
}

export { getFormattedSdkNftClaims, getNftClaimCounters };
