import { env } from 'environments';

export const localStorageKeys = {
  walletConnection: 'abridge:connected',
  currentNetwork: 'abridge:currentNetwork',
};

// Breakpoints taken from the components library
// https://github.com/Stichting-AllianceBlock-Foundation/components/blob/develop/src/styles/_variables.scss
export const breakpoints: { [breakpoint: string]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 991,
  xl: 1400,
};

export const TOKEN_SLIDER_PERCENTAGE_DECIMALS: number = 2;
export const TOKEN_FEE_DECIMALS: number = 2;
export const DEFAULT_FEE_CURRENCY: string = 'usd';

export const openSeaApiKey = env.OPENSEA_ID;

export const REACT_APP_BASE_URL: string = env.BASE_URL ?? '';

export const WALLET_TYPE = {
  DEFAULT: 'default',
  METAMASK: 'metamask',
  PHANTOM: 'phantom',
};

export const SUPPORTED_WALLETS: string[] = [
  'metamask',
  'rabby',
  // 'phantom',
];

export type ChainType = {
  [key: string]: string;
};

export const CHAINS_TYPES: ChainType = {
  metamask: 'EVM',
  phantom: 'SOLANA',
};

export const bridgeOperationDisabled: boolean = env.DISABLE_BRIDGE_OPERATION;
