import { ERC20RouterSDK, TeleportSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { ethers } from 'ethers';
import { fromWei, getTokenIcon, isDefaultAddressInSomeNetwork, svgUrlExists } from 'utils';
import { getNetworkByChainTargetId } from 'utils/network';
import { TransmissionActionOperation } from 'utils/sdk';

async function formatTransferList(
  sdk: TeleportSDK,
  etherscanBaseURL: string,
  claims: Erc20Claim[],
  networkOptions: Network[],
  account: string,
) {
  const transfers = claims.map(async claim => {
    const { transfer, isClaimed } = claim;

    let value: string = '';

    let amount: string = '0';

    let bridgedToken: Token = {
      details: {
        name: '',
        symbol: '',
        decimals: 0,
        icon: 'assets',
      },
      amount: '',
      address: '',
    };

    if (TransmissionActionOperation.BurnUnlock !== transfer.transmissionActionOperation) {
      amount = ethers.utils.formatUnits(transfer?.amount, transfer?.wrappedTokenDecimals);
      value = `${amount} ${transfer.wrappedTokenSymbol}`;
      const bridgedTokenIcon = getTokenIcon({
        decimals: transfer.wrappedTokenDecimals,
        symbol: transfer.wrappedTokenSymbol,
        name: transfer.wrappedTokenName,
      });
      bridgedToken = {
        details: {
          decimals: transfer.wrappedTokenDecimals,
          symbol: transfer.wrappedTokenSymbol,
          name: transfer.wrappedTokenName,
          icon: svgUrlExists(`/icons/${bridgedTokenIcon}.svg`) ? bridgedTokenIcon : 'assets',
        },
        amount: amount,
        address: '',
      };
    } else {
      // TODO: Maybe we can check for specific case chain default
      if (isDefaultAddressInSomeNetwork(claim.transfer.nativeToken) === false) {
        const tokenDetailsMulticall = (
          await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetailsMulticall([claim.transfer.nativeToken], account)
        )[0];

        const amount = fromWei(claim.transfer.amount, tokenDetailsMulticall.decimals);
        const bridgedTokenIcon = getTokenIcon({
          decimals: tokenDetailsMulticall.decimals,
          symbol: tokenDetailsMulticall.symbol ?? '',
          name: tokenDetailsMulticall.name ?? '',
        });
        bridgedToken = {
          details: {
            decimals: tokenDetailsMulticall.decimals,
            symbol: tokenDetailsMulticall.symbol ?? '',
            name: tokenDetailsMulticall.name ?? '',
            icon: svgUrlExists(`/icons/${bridgedTokenIcon}.svg`) ? bridgedTokenIcon : 'assets',
          },
          amount: amount,
          address: '',
        };

        value = `${amount} ${tokenDetailsMulticall.symbol}`;
      } else {
        const nativeNetwork = getNetworkByChainTargetId(transfer.targetChainId, networkOptions);
        const amount = fromWei(claim.transfer.amount, nativeNetwork.nativeCurrency.decimals);
        bridgedToken = {
          details: {
            decimals: nativeNetwork.nativeCurrency.decimals,
            symbol: nativeNetwork.nativeCurrency.symbol,
            name: nativeNetwork.nativeCurrency.name,
            icon: nativeNetwork.chainIcon,
          },
          amount: amount,
          address: '',
        };

        value = `${amount} ${nativeNetwork.nativeCurrency.symbol}`;
      }
    }

    const result: FormattedTransfer = {
      sourceNetwork: getNetworkByChainTargetId(transfer.sourceChainId, networkOptions),
      targetNetwork: getNetworkByChainTargetId(transfer.targetChainId, networkOptions),
      status: isClaimed.toString(),
      transactionURL: etherscanBaseURL,
      autoClaiming: transfer.autoClaiming,
      majorityReached: transfer.majorityReached,
      sourceTx: transfer.sourceTx,
      nativeChainId: transfer.nativeChainId,
      nativeToken: transfer.nativeToken,
      recipient: transfer.recipient,
      transmissionId: transfer.transmissionId,
      transmission: {
        transmissionId: transfer.transmissionId,
        transmissionType: transfer.transmissionType,
        sourceChainId: transfer.sourceChainId,
        transmissionSender: transfer.transmissionSender,
        transmissionReceiver: transfer.transmissionReceiver,
        dAppId: transfer.dAppId,
        payload: transfer.payload,
        signatures: transfer.signatures,
      },
      value,
      bridgedToken,
      failed: transfer.failed,
    };

    return result;
  });

  return Promise.all(transfers);
}
async function getFormattedSdkClaims(
  sdk: TeleportSDK,
  account: string,
  chainId: number,
): Promise<Erc20Claim[]> {
  const latestClaims = await sdk
    .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
    .getErc20Claims(chainId, account);

  const formattedClaims = await formatMulticallClaims(latestClaims);
  return formattedClaims;
}

function getClaimCounters(claims: Erc20Claim[]) {
  let counterClaimed = 0;
  let counterNotClaimed = 0;
  if (claims) {
    claims.map(claim => {
      if (!claim.isClaimed && claim.transfer.majorityReached) {
        counterClaimed++;
      } else {
        counterNotClaimed++;
      }
    });
  }

  return { counterClaimed, counterNotClaimed };
}

async function formatMulticallClaims(claims: Erc20ClaimSDK[]): Promise<Erc20Claim[]> {
  const result = [];
  try {
    for (let index = 0; index < claims.length; index++) {
      const claim = claims[index];
      const transfer: Transfer = {
        amount: claim.decoded.action.data.amount,
        dAppId: claim.transmissionDetails.dAppId,
        transmissionId: claim.transmissionDetails.transmissionId,
        transmissionType: claim.transmissionDetails.transmissionType,
        transmissionSender: claim.transmissionDetails.transmissionSender,
        transmissionReceiver: claim.transmissionDetails.transmissionReceiver,
        transmissionActionOperation: claim.decoded.action.operation,
        payload: claim.transmissionDetails.payload,
        sourceTx: claim.transmissionDetails.transmissionTxHash,
        autoClaiming:
          claim.decoded.deliveryFee.gt(0) || claim.transmissionDetails.transmissionType === 'ccip',
        majorityReached: claim.transmissionDetails.majorityReached,
        nativeChainId: 0,
        nativeToken: claim.decoded.action.token,
        targetChainId: claim.transmissionDetails.targetChainId,
        recipient: claim.decoded.receiver,
        signatures: claim.transmissionDetails.signatures ?? [],
        sourceChainId: claim.transmissionDetails.sourceChainId,
        wrappedTokenDecimals: 0,
        wrappedTokenName: '',
        wrappedTokenSymbol: '',
        failed: claim.transmissionDetails.delivery?.status === 'FAILURE',
      };

      let isClaimed = claim.transmissionDetails.delivery !== null;
      if (transfer.transmissionType === 'ccip') {
        isClaimed = claim.transmissionDetails.delivery?.status === 'SUCCESS';
      }

      if (
        [TransmissionActionOperation.BurnMint, TransmissionActionOperation.LockMint].includes(
          claim.decoded.action.operation,
        )
      ) {
        transfer.nativeChainId = claim.decoded.action.data.nativeChainId;
        transfer.wrappedTokenDecimals = claim.decoded.action.data.decimals;
        transfer.wrappedTokenName = claim.decoded.action.data.name;
        transfer.wrappedTokenSymbol = claim.decoded.action.data.symbol;
      }

      result.push({
        transfer,
        isClaimed,
      });
    }
    return result;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export { formatTransferList, getClaimCounters, getFormattedSdkClaims };
