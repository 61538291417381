import React, { useEffect } from 'react';

import ClaimedCounter from 'components/Claim/ClaimedCounter/ClaimedCounter';
import ClaimFilter from 'components/Claim/ClaimFilter/ClaimFilter';
import ClaimTitle from 'components/Claim/ClaimTitle/ClaimTitle';
import ClaimWindowContainer from 'components/Claim/ClaimWindow/ClaimListing/ClaimWindowContainer/ClaimWindowContainer';
import { ClaimSuccess } from 'components/Modals/ClaimSuccess';
import { Modal } from 'components/Modals/Modal';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import { isDefaultAddressInSomeNetwork } from 'utils/';
import { addAsset, getProvider, isMetamask } from 'utils/metamask';

import { getClaimCounters, getFormattedSdkClaims } from '../../../helpers/Erc20Claims';

import './ClaimPage.scss';
const ClaimPage = () => {
  const { sdk, currentNetwork, claimModal, setClaims, setBridgeStatuses, setClaimModal } =
    useGlobalContext();
  const { account } = useWallet();
  const [claimsAccount, setClaimsAccount] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    function getToBeClaimedCounter(claims: Erc20Claim[]) {
      const countersObject = getClaimCounters(claims);
      setBridgeStatuses(prevBridgeStatuses => {
        return {
          bridge: {
            ...prevBridgeStatuses.bridge,
            activeClaims: countersObject.counterClaimed,
            inActiveClaims: countersObject.counterNotClaimed,
          },
        };
      });
    }

    const updateClaims = async () => {
      if (sdk && (claimsAccount || account)) {
        try {
          const formattedClaims = await getFormattedSdkClaims(
            sdk,
            (claimsAccount ?? account) as string,
            currentNetwork.chainTargetId as number,
          );
          setClaims(formattedClaims);
          getToBeClaimedCounter(formattedClaims);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const interval = setInterval(updateClaims, currentNetwork.refreshClaimTimestamp ?? 15000);

    updateClaims();

    return () => {
      clearInterval(interval);
    };
  }, [
    account,
    claimsAccount,
    currentNetwork.chainTargetId,
    currentNetwork.refreshClaimTimestamp,
    sdk,
    setBridgeStatuses,
    setClaims,
  ]);

  const addTokenToWallet = async (
    type: MetamaskAssetParamsType,
    options: MetamaskAssetParamsOptions,
  ) => {
    try {
      const provider = getProvider();
      await addAsset(type, options, provider);
    } catch (err) {
      console.error('An error occurred while trying to add the token.');
    }
  };

  const isNotDefaultAddress = !isDefaultAddressInSomeNetwork(claimModal.claimData?.token?.address);
  return (
    <div className="claim-page-container">
      <Modal
        triggered={Boolean(claimModal.status && isNotDefaultAddress)}
        onClose={(reason?: CloseReason) => {
          if (reason !== 'Component unmount') {
            // Rerenders cause unmount on close to be triggered
            setClaimModal({
              status: false,
              claimData: {
                token: {
                  details: {
                    name: '',
                    symbol: '',
                    decimals: 0,
                    icon: '',
                  },
                  amount: '',
                  address: '',
                },
                type: '',
                recipient: '',
                transactionHash: '',
                walletType: '',
              },
            });
          }
        }}
        className="p-0 pb-4"
      >
        <ClaimSuccess
          claim={claimModal.claimData}
          addTokenToWallet={addTokenToWallet}
          isMetamask={isMetamask()}
        />
      </Modal>
      <div className="data-wrapper mt-7 mb-5 my-md-7">
        <ClaimTitle />
        <div className="claimed-option-wrapper my-4 pb-4 d-flex align-items-sm-end justify-content-between">
          <ClaimFilter setClaimsAccount={setClaimsAccount} claimsAccount={claimsAccount} />
          <div className="line mx-5 mb-6 mt-4 d-sm-none"></div>
          <ClaimedCounter />
          <div className="line mx-5 mt-6 d-sm-none"></div>
        </div>
        <ClaimWindowContainer />
      </div>
    </div>
  );
};

export default ClaimPage;
