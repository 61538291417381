import type { Web3Provider } from '@ethersproject/providers';
import { toHex } from 'utils';

export enum MetamaskErrorCodes {
  /*
   * This error code indicates that the network we're going to switch it doesn't
   * exists in our Metamask Wallet
   * More info here: https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain
   */
  NotNetworkFound = 4902,
  /*
   * This error code indicates that we have rejected the Metamask request
   * More info here: https://docs.metamask.io/guide/rpc-api.html#example
   */
  UserRejectedRequest = 4001,
}

export enum RabbyErrorCodes {
  /** Rabby doesn't have any documentation, I got this error code via console.log */
  NotNetworkFound = -32603,
}

export const isMetamask = (): boolean | undefined => {
  return getProvider()?.isMetaMask;
};

export const getWeb3ClientVersion = async () => {
  return window.ethereum?.request
    ? await window.ethereum.request({
        method: 'web3_clientVersion',
        params: [],
      })
    : undefined;
};

export const getWalletByClientVersion = () => {
  const isMM = window.ethereum?.isMetaMask;
  if (isMM && window.ethereum?.isRabby) return 'rabby';
  else if (isMM) return 'metamask';
  return 'default';
};

export const isValidWeb3Client = async () => {
  const isMM = window.ethereum?.isMetaMask;
  return isMM || window.ethereum?.isRabby;
};

export const changeCurrentNetwork = async (chainId: number, provider: Web3Provider | undefined) => {
  if (provider?.send) {
    await provider.send('wallet_switchEthereumChain', [{ chainId: toHex(chainId) }]);
  }
};

export const addNetwork = async (
  params: MetamaskChainParams,
  provider: Web3Provider | undefined,
) => {
  if (provider?.send) {
    await provider.send('wallet_addEthereumChain', [params]);
  }
};

export const getMetamaskNetworkParams = (network: Network): MetamaskChainParams => {
  return {
    chainId: toHex(network.chainId),
    chainName: network.chainName,
    nativeCurrency: {
      name: network.nativeCurrency.name,
      symbol: network.nativeCurrency.symbol,
      decimals: network.nativeCurrency.decimals,
    },
    rpcUrls: [network.rpcUrl],
    blockExplorerUrls: [network.blockExplorerUrl],
  };
};

export const addAsset = async (
  type: MetamaskAssetParamsType,
  options: MetamaskAssetParamsOptions,
  provider: WindowProvider,
) => {
  if (provider.request) {
    await provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: type,
        options: options,
      },
    });
  }
};

export const getProvider = () => {
  if (!!window.ethereum) {
    return window.ethereum;
  } else if (!!window.web3) {
    return window.web3;
  } else {
    throw new Error('Provider Error: No Provider Found');
  }
};

export const isProviderAvailable = () => {
  const isProviderAvailable = !!window.ethereum || !!window.web3;
  return isProviderAvailable;
};

export const installWallet = () => {
  const redirectToUrl = () => {
    window.location.href = 'https://metamask.io/download/';
  };
  return redirectToUrl();
};
