import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
function Attention() {
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag();
  return (
    <div className="d-flex mx-4 mt-7 mb-5">
      <Icon name="info-circle" size={20} color="ui-main" className="mr-4" />
      <div>
        <p className="text-main text-bold">{t('claimPage:attentionField.title')}</p>
        <p className="text-main text-align-justify">
          <Trans
            i18nKey="claimPage:attentionField.subtitle"
            components={{ span: <span /> }}
            context={isEnabled('ERC1155') ? 'with_erc1155' : ''}
          />
        </p>
      </div>
    </div>
  );
}

export default Attention;
