import React from 'react';

import { useBreakpoint } from 'hooks/useBreakpoint';

import './Indicator.scss';

interface IndicatorProps {
  title: string;
  value: number;
  type: 'primary' | 'secondary';
  className?: string;
}

const Indicator = ({ className, title, value, type }: IndicatorProps) => {
  const { greaterThan } = useBreakpoint();

  return (
    <div
      className={`${
        greaterThan('sm')
          ? 'd-flex align-items-center'
          : `indicator bg-${type} box-shadow-${type} px-4 pt-4 pb-2`
      } ${className ?? ''}`}
    >
      <div
        className={
          greaterThan('sm')
            ? 'text-main mr-3'
            : `font-weight-700 ${type === 'primary' ? 'text-primary' : 'text-solid'}`
        }
      >
        {title}
      </div>
      <div
        className={
          greaterThan('sm')
            ? `indicator-value py-3 px-5 ${type === 'secondary' ? 'bg-secondary' : ''}`
            : `font-weight-700 text-right text-huge mt-2 ${
                type === 'primary' ? 'text-primary' : 'text-solid'
              }`
        }
      >
        {value}
      </div>
    </div>
  );
};

export default Indicator;
