import React from 'react';

import MetamaskIcon from 'assets/metamask.svg';
import PhantomIcon from 'assets/phantom.svg';
import RabbyIcon from 'assets/rabby.png';

interface WalletProps {
  icon: string;
  title: string;
}

interface Wallets {
  [key: string]: WalletProps;
}

export const WalletIcon = ({ wallet }: { wallet: string }) => {
  const walletProps: Wallets = {
    metamask: {
      icon: MetamaskIcon,
      title: 'Metamask',
    },
    rabby: {
      icon: RabbyIcon,
      title: 'Rabby',
    },
    phantom: {
      icon: PhantomIcon,
      title: 'Phantom',
    },
    default: {
      icon: '',
      title: 'Not wallet',
    },
  };

  const { title, icon } = walletProps[wallet];

  return (
    <>
      <img className="mr-3" src={icon} alt="Icon" />
      {title}
    </>
  );
};

export default WalletIcon;
