import { env } from 'environments';
import { overrideJson } from 'utils';

import en_dev from './en.json';
import es_dev from './es.json';
import en_prod from './production/en.json';
import es_prod from './production/es.json';

const mode = env.NODE_ENV;

const en = mode === 'development' ? en_dev : overrideJson(en_dev, en_prod);
const es = mode === 'development' ? es_dev : overrideJson(es_dev, es_prod);

export { en, es };
