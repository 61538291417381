import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';

import { JsonRpcSigner } from '@ethersproject/providers';
import { WALLET_TYPE } from 'configs/constants';
import { RabbyErrorCodes } from 'utils/metamask';
import {
  addNetwork,
  changeCurrentNetwork,
  getMetamaskNetworkParams,
  isValidWeb3Client,
  MetamaskErrorCodes,
} from 'utils/metamask';
import { metaMaskHooks } from 'utils/web3React';

import { useGlobalContext } from '../useGlobalContext';
import { WalletFacade } from './index';

export const useAdapterMetamaskWallet = (): Omit<WalletFacade, 'type' | 'connectedWallet'> => {
  const { account, chainId, isActive, connector } = useWeb3React();
  const { useProvider } = metaMaskHooks;
  const provider = useProvider();

  const signer = provider?.getSigner() as JsonRpcSigner;

  const { isWalletConnected, currentNetwork, setCurrentNetwork, setNetworkSwitchScreen } =
    useGlobalContext();

  const [isWalletInstalled, setIsWalletInstalled] = useState<boolean>(false);

  useEffect(() => {
    const loadIsWalletInstalled = async () => {
      setIsWalletInstalled(!!(await isValidWeb3Client()));
    };
    loadIsWalletInstalled();
  }, []);

  const changeNetwork = async (network: Network) => {
    const abortChangeNetwork = () => {
      setNetworkSwitchScreen(prevNetworkSwitchScreen => {
        return {
          ...prevNetworkSwitchScreen,
          connecting: false,
        };
      });
    };

    try {
      if (
        isWalletConnected[WALLET_TYPE.METAMASK] &&
        currentNetwork.walletType === WALLET_TYPE.METAMASK
      ) {
        setNetworkSwitchScreen(prevNetworkSwitchScreen => {
          return {
            ...prevNetworkSwitchScreen,
            network,
            connecting: true,
          };
        });
        await changeCurrentNetwork(network.chainId, provider);
      }
      setCurrentNetwork(network);
    } catch (err) {
      const errorCode = (err as CustomError).code;
      if (
        errorCode === MetamaskErrorCodes.NotNetworkFound ||
        errorCode === RabbyErrorCodes.NotNetworkFound
      ) {
        try {
          setNetworkSwitchScreen(prevNetworkSwitchScreen => {
            return {
              ...prevNetworkSwitchScreen,
              network,
              connecting: true,
            };
          });
          await addNetwork(getMetamaskNetworkParams(network), provider);
        } catch (err) {
          console.error(
            'MetaMask - Add Network: An error ocurred when trying to add a new network.',
            err,
          );
          abortChangeNetwork();
        }
      } else if ((err as CustomError).code === MetamaskErrorCodes.UserRejectedRequest) {
        abortChangeNetwork();
      }
    }
  };

  const onActivate = async () => {
    if (!(await isValidWeb3Client())) {
      setIsWalletInstalled(false);
      return;
    }
    return await connector.activate();
  };

  const onDeactivate = () => {
    if (connector?.deactivate) connector.deactivate();
    else connector.resetState();
  };

  return {
    account,
    library: provider,
    chainId,
    isActive,
    connector,
    signer,
    isWalletInstalled,
    activate: onActivate,
    deactivate: onDeactivate,
    changeNetwork,
  };
};

export default useAdapterMetamaskWallet;
