import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FilterOption = {
  network: string;
  status: string;
};

export const useDefaultFilterOptionData = () => {
  const { t } = useTranslation();
  const [defaultFilterOption] = useState<FilterOption>({
    network: t('claimPage:filterOptions.network'),
    status: t('claimPage:filterOptions.constants.showAll'),
  });

  return defaultFilterOption;
};
export default useDefaultFilterOptionData;
