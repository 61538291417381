import React from 'react';

import { Button, ButtonProps } from '@stichting-allianceblock-foundation/components';
import { useModalContext } from 'hooks/useModalContext';

const ModalButton = ({ children, ...props }: Omit<ButtonProps, 'onCLick'>) => {
  const { setOpen } = useModalContext();
  const open = () => {
    setOpen(true);
  };

  return (
    <Button {...props} onClick={open}>
      {children}
    </Button>
  );
};

export default ModalButton;
