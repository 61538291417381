import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import ResponsiveAddress from 'components/common/ResponsiveAddress';
import { WALLET_TYPE } from 'configs/constants';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useModalContext } from 'hooks/useModalContext';
import { getBlockExplorerNameByChainId } from 'utils';

import './ClaimSuccess.scss';

// This modal is used to claim ERC20
// TODO unify it with ModalClaimSuccess.tsx
interface ClaimSuccessProps {
  claim: ClaimData;
  addTokenToWallet: (
    type: MetamaskAssetParamsType,
    options: MetamaskAssetParamsOptions,
  ) => Promise<void>;
  isMetamask: boolean | undefined;
}

const ClaimSuccess = ({ claim, addTokenToWallet, isMetamask }: ClaimSuccessProps) => {
  const { currentNetwork } = useGlobalContext();
  const { closeModal } = useModalContext();
  const { t } = useTranslation();
  useEffect(() => {
    return () => closeModal('Component unmount');
  }, [closeModal]);

  return (
    <div className="claim-success-modal">
      <div className="claim-success-modal-screen d-flex flex-column pt-5 pb-7">
        <Icon
          className="align-self-end mr-5"
          name="nav-cross"
          size={20}
          color="ui-main-background"
          onClick={() => {
            closeModal('User action');
          }}
        />
        <Icon
          className="align-self-center success-icon"
          size={32}
          name="check-circle"
          color="ui-main-background"
        />
      </div>
      <div className="pt-5 px-5 pb-4">
        <h1 className="text-center mt-3 mb-4">{t('claimPage:successModal.title')}</h1>
        <div className="d-flex align-items-center">
          <div className="text-small text-bold text-secondary mb-3 mr-3">
            {t('claimPage:successModal.transactionDetails')}
          </div>
        </div>
        <div className="px-5">
          <div className="mb-5 mt-3">
            <div className="d-flex align-items-center ml-2">
              <div>
                <div className="text-secondary">
                  {t('claimPage:successModal.claimedTokens.amount')}
                </div>
                <div className="text-main text-bold mr-2">{claim.token.amount}</div>
              </div>
              <div className="ml-4">
                <div className="text-secondary">
                  {t('claimPage:successModal.claimedTokens.token')}
                </div>
                <div className="d-flex align-items-center">
                  {claim.token.details.icon && (
                    <Icon name={claim.token.details.icon} size={18} className="mr-2" />
                  )}
                  <span className="text-main">{claim.token.details.symbol}</span>
                </div>
              </div>
            </div>
            {claim.walletType === WALLET_TYPE.METAMASK && isMetamask && (
              <div
                className="d-flex align-items-center cursor-pointer my-4"
                onClick={async () =>
                  await addTokenToWallet(claim.type as unknown as MetamaskAssetParamsType, {
                    address: claim.token.address,
                    symbol: claim.token.details.symbol,
                    decimals: claim.token.details.decimals,
                  })
                }
              >
                <Icon name="collapsed" size={20} />
                <span className="link-primary mx-2">
                  {t('claimPage:successModal.addTokenToWallet')}
                </span>
                <Icon name="metamask" size={20} />
              </div>
            )}
          </div>
          <div className="mb-5 mt-3">
            <div className="d-flex align-items-center">
              <Icon name="wallet" size={20} color="ui-main" className="mr-2" />
              <span className="text-secondary">{t('claimPage:successModal.recipient')}</span>
            </div>
            <div className="d-flex align-items-center mt-2 mt-sm-0">
              <BlockExplorerBadge
                title={
                  <ResponsiveAddress address={claim.recipient} walletType={claim.walletType} />
                }
                hash={claim.recipient}
                walletType={claim.walletType}
                blockExplorer={currentNetwork.blockExplorerUrl}
                queryParams={currentNetwork.blockExplorerQueryParams}
                type="address"
              />
            </div>
          </div>
          <div className="mb-5 mt-3">
            <div className="d-flex align-items-center">
              <Icon name="pass-through" size={20} color="ui-main" className="mr-2" />
              <span className="text-secondary">{t('claimPage:successModal.transaction')}</span>
            </div>
            <div className="d-flex align-items-center mt-2 mt-sm-0">
              <p>{claim.transactionHash}</p>
              <BlockExplorerBadge
                title={
                  <Trans
                    i18nKey="claimPage:successModal.seeOnText"
                    components={{ span: <span /> }}
                    values={{
                      name: getBlockExplorerNameByChainId(currentNetwork.chainId),
                    }}
                  />
                }
                hash={claim.transactionHash}
                type="tx"
                blockExplorer={currentNetwork.blockExplorerUrl}
                queryParams={currentNetwork.blockExplorerQueryParams}
                shorten
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-sm-end mt-8">
          <Button
            type="primary"
            onClick={() => {
              closeModal('User action');
            }}
          >
            <Icon name="cross" size={18} color="ui-border" />
            <span className="mr-2">{t('claimPage:successModal.closeButton')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClaimSuccess;
