import React from 'react';

import './Checkbox.scss';

interface CheckboxProps {
  label: string | JSX.Element;
  checked: boolean;
  onCheck: () => void;
  className?: string;
}

const Checkbox = ({ label, checked, onCheck, className }: CheckboxProps) => {
  return (
    <div className={className}>
      <input
        className="input-checkbox"
        id="checkbox"
        type="checkbox"
        checked={checked}
        onChange={onCheck}
      />
      <label className="checkbox d-flex align-items-center" htmlFor="checkbox">
        <span>
          <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
