import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import {
  Icon,
  Notification,
  Slider,
  Step,
  StepPanel,
  Switch,
  TextField,
  TokenInput,
} from '@stichting-allianceblock-foundation/components';
import { Select } from 'components/Select';
import { TokenSelect } from 'components/TokenSelect';
import { InfoTooltip } from 'components/Tooltips';
import { NETWORK_CONFIG } from 'configs';
import { TOKEN_SLIDER_PERCENTAGE_DECIMALS } from 'configs/constants';
import { ethers } from 'ethers';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import {
  calculateTokenAmountByPercentage,
  calculateTokenPercentageByAmount,
  formatTokenInput,
  fromWei,
  getDefaultAddress,
  getTokenAddressesByChainId,
  getTokenIcon,
  hexlifyAddress,
  isAddress,
  svgUrlExists,
  toWei,
} from 'utils';
import { getNetworkIndex } from 'utils/network';

import StepBridgeNotifications from './StepBridgeNotifications';

interface StepBridgeDetailsProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  tokenSelectedBalance: string;
  tokenList: TokenDetails[];
  selectedToken: TokenDetails;
  sliderPercentage: number;
  isServiceTokenApproved: boolean;
  isBridgeTokenApproved: boolean;
  isAutoClaimActive: boolean;
  serviceFeeTokenIndex: number;
  txFeeEstimation: string;
  txFeeEstimationCurrency: string;
  autoClaimEstimation: AutoClaimEstimation;
  notEnoughBalanceForServiceFee: boolean;
  isBridgeTransactionTokenNativeToken: boolean;
  isTokenEWTB: boolean;
  currentAllowance: ethers.BigNumber | undefined;
  isTokenNonApprovable: boolean;
  tokenSelectedAmountInWei: ethers.BigNumber | undefined;
  autoClaimHasFunds: boolean | null;
  loadingProviderFee: boolean;
  providerFee: number;
  updateSliderPercentage: (percentage: number) => void;
  updateSelectedToken: (option: TokenDetails) => void;
  updateTokenSelectedBalance: (tokenBalance: string) => void;
  updateTokenList: (options: TokenDetails[]) => void;
  updateIsAutoClaimActive: (value: boolean) => void;
  onSelectedFeeTokenChange: (option: Option) => void;
  setLoadingProviderFee: (value: boolean) => void;
  setProviderFee: (value: number) => void;
}

const StepBridgeDetails = ({
  activeStep,
  wasTransactionCleared,
  tokenSelectedBalance,
  tokenList,
  selectedToken,
  sliderPercentage,
  isServiceTokenApproved,
  isBridgeTokenApproved,
  isAutoClaimActive,
  serviceFeeTokenIndex,
  txFeeEstimation,
  txFeeEstimationCurrency,
  autoClaimEstimation,
  notEnoughBalanceForServiceFee,
  isBridgeTransactionTokenNativeToken,
  isTokenEWTB,
  currentAllowance,
  isTokenNonApprovable,
  tokenSelectedAmountInWei,
  autoClaimHasFunds,
  loadingProviderFee,
  providerFee,
  updateSliderPercentage,
  updateSelectedToken,
  updateTokenSelectedBalance,
  updateTokenList,
  updateIsAutoClaimActive,
  onSelectedFeeTokenChange,
  setLoadingProviderFee,
  setProviderFee,
}: StepBridgeDetailsProps) => {
  const {
    sdk,
    currentNetwork,
    networkOptions,
    bridgeTransaction,
    serviceFeeOptions,
    isTokenAllowedToBridge,
    setIsTokenAllowedToBridge,
    setBridgeTransaction,
  } = useGlobalContext();
  const { isEnabled } = useFeatureFlag();
  const { account, type } = useWallet();
  const { t } = useTranslation();
  const [isLoadingTokens, setIsLoadingTokens] = useState<boolean>(false);
  const [targetNetworkIndex, setTargetNetworkIndex] = useState(0);
  const [nativeChain, setNativeChain] = useState<Network>({} as Network);
  const [tokenInputAmount, setTokenInputAmount] = useState('0');
  const [showInvalidAddressText, setShowInvalidAddressText] = useState(false);
  const [supportedChains, setSupportedChains] = useState<Network[]>([]);
  const [loadingSupportedChains, setLoadingSupportedChains] = useState<boolean>(false);
  // const [providerFee, setProviderFee] = useState(0);

  const maxAttempts: number = 10;

  const onChangeRecipient = (recipient: string) => {
    setBridgeTransaction({
      ...bridgeTransaction,
      recipient: recipient,
    });
  };

  const handleTokenInputAmount = (amount: string) => {
    if (amount) {
      const { decimals } = selectedToken;
      const newAmount = formatTokenInput(
        Number(amount) >= 0 && Number(amount) <= Number(tokenSelectedBalance)
          ? amount
          : tokenSelectedBalance,
        decimals,
      );

      const percentage = calculateTokenPercentageByAmount(
        newAmount,
        tokenSelectedBalance,
        bridgeTransaction.token.details.decimals,
      );

      updateSliderPercentage(percentage > 100 ? 100 : percentage < 0 ? 0 : percentage);
      setTokenInputAmount(newAmount);
      setBridgeTransaction({
        ...bridgeTransaction,
        token: {
          details: bridgeTransaction.token.details,
          address: bridgeTransaction.token.address,
          amount: newAmount,
        },
      });
    } else {
      setTokenInputAmount('0');
      updateSliderPercentage(0);
      setBridgeTransaction({
        ...bridgeTransaction,
        token: {
          details: bridgeTransaction.token.details,
          address: bridgeTransaction.token.address,
          amount: '0',
        },
      });
    }
  };

  const handleSliderPercentage = (percentage: string) => {
    const amount = calculateTokenAmountByPercentage(
      percentage,
      tokenSelectedBalance,
      bridgeTransaction.token.details.decimals,
    );

    updateSliderPercentage(Number(percentage));
    setTokenInputAmount(Number(amount) > 0 ? formatTokenInput(amount) : '0');
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: bridgeTransaction.token.details,
        address: bridgeTransaction.token.address,
        amount: Number(amount) > 0 ? formatTokenInput(amount) : '0',
      },
    });
  };

  const onChangeTargetNetwork = (targetNetwork: Option) => {
    setTargetNetworkIndex(getNetworkIndex(targetNetwork.chainId, supportedChains));
    setBridgeTransaction({
      ...bridgeTransaction,
      network: {
        source: bridgeTransaction.network.source,
        target: targetNetwork as Network,
      },
    });
  };

  const onChangeSelectToken = (option: TokenDetails) => {
    updateSelectedToken(option);
    setTokenInputAmount('0');
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: {
          name: option.name,
          symbol: option.symbol,
          decimals: option.decimals || 0,
          icon: option.icon || '',
        },
        amount: bridgeTransaction.token.amount,
        address: option.address || '',
      },
    });
  };

  const handleClearTokenSelected = () => {
    updateSelectedToken({} as TokenDetails);
    setTokenInputAmount('0');
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: {
          name: '',
          symbol: '',
          decimals: 0,
          icon: '',
        },
        amount: '0',
        address: '',
      },
    });
    updateTokenSelectedBalance('0');
    updateSliderPercentage(0);
  };

  useEffect(() => {
    const resetTokenInputAmount = () => {
      setTokenInputAmount('0');
      updateSliderPercentage(0);
      setBridgeTransaction(prevBridgeTransaction => {
        return {
          ...prevBridgeTransaction,
          token: {
            details: prevBridgeTransaction.token.details,
            address: prevBridgeTransaction.token.address,
            amount: '0',
          },
        };
      });
    };

    if (sdk) resetTokenInputAmount();
  }, [sdk, setBridgeTransaction, updateSliderPercentage]);

  useEffect(() => {
    const loadTransactionNetworkSource = () => {
      setBridgeTransaction(prevBridgeTransaction => {
        return {
          ...prevBridgeTransaction,
          network: {
            source: currentNetwork,
            target: prevBridgeTransaction.network.target,
          },
        };
      });
      setTargetNetworkIndex(0);
    };

    loadTransactionNetworkSource();
  }, [currentNetwork, setBridgeTransaction]);

  useEffect(() => {
    const loadTransactionNetworkSource = () => {
      const isValidAddress = isAddress(
        bridgeTransaction.recipient,
        bridgeTransaction.network.target.walletType,
      );
      setShowInvalidAddressText(!isValidAddress);
    };

    loadTransactionNetworkSource();
  }, [bridgeTransaction.recipient, bridgeTransaction.network.target.walletType]);

  useEffect(() => {
    const forceLoadTransactionNetworkSource = () => {
      if (
        !bridgeTransaction?.network?.source?.chainId ||
        bridgeTransaction?.network?.source?.chainId !== currentNetwork?.chainId
      ) {
        setBridgeTransaction(prevBridgeTransaction => {
          return {
            ...prevBridgeTransaction,
            network: {
              source: currentNetwork,
              target: prevBridgeTransaction.network.target,
            },
          };
        });
      }
    };

    forceLoadTransactionNetworkSource();

    const checkWhitelistedChains = (
      tokens: { [name: string]: string | TokenConfiguration },
      chainId: number,
    ) => {
      return !Object.values(tokens).some(
        token =>
          typeof token === 'object' &&
          token.address === bridgeTransaction.token?.address &&
          token.whitelistedChains &&
          !token.whitelistedChains.includes(chainId),
      );
    };

    const chainTargetId = bridgeTransaction.network.target.chainTargetId;
    if (chainTargetId && bridgeTransaction.token?.address) {
      const tokens = Object.values(NETWORK_CONFIG)?.filter(
        (item: NetworkConfigValues) => item?.network?.chainId === currentNetwork.chainId,
      )[0].tokenAddresses;
      setIsTokenAllowedToBridge(checkWhitelistedChains(tokens, chainTargetId));
    } else setIsTokenAllowedToBridge(true);
  }, [bridgeTransaction, currentNetwork, setBridgeTransaction, setIsTokenAllowedToBridge]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadPreselectedTokens = async () => {
      setIsLoadingTokens(true);
      try {
        if (sdk && account && currentNetwork?.chainId) {
          const feeTokensAddresses: string[] = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getFeeTokens();

          const tokenConfigurations = getTokenAddressesByChainId(currentNetwork.chainId);
          const tokenAddresses = Array.from(tokenConfigurations.keys());

          const preselectedTokens = [...tokenAddresses, ...feeTokensAddresses];

          const preselectedTokenList: TokenDetails[] = [];

          const preselectedTokensDetails = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetailsMulticall(preselectedTokens, account);

          if (preselectedTokens?.length > 0) {
            for (const preselectedToken of preselectedTokensDetails) {
              try {
                const tokenIcon = getTokenIcon(preselectedToken);
                if (preselectedToken.address) {
                  const tokenConfigOverride = tokenConfigurations.get(preselectedToken.address);
                  if (tokenConfigOverride) {
                    preselectedToken.name =
                      tokenConfigOverride.nameOverride ?? preselectedToken.name;
                  }
                }
                preselectedTokenList.push({
                  ...preselectedToken,
                  icon: svgUrlExists(`/icons/${tokenIcon}.svg`) ? tokenIcon : 'assets',
                });
              } catch (err) {
                console.error('loadPreselectedTokens', err);
                updateTokenList([]);
                // if network change, we need to end the function to avoid
                // sending unnecessary requests to sdk
                return;
              }
            }
          }
          const nativeToken: TokenDetails = {
            name: currentNetwork.nativeCurrency.name,
            symbol: currentNetwork.nativeCurrency.symbol,
            decimals: currentNetwork.nativeCurrency.decimals,
            icon: currentNetwork.chainIcon,
            address: getDefaultAddress(type),
          };

          preselectedTokenList.unshift(nativeToken);

          // Some tokens are blacklisted from the bridge
          // For example, ALBT V1 and their wrapped versions
          const filteredSupportedTokens: TokenDetails[] = (
            await Promise.all(
              preselectedTokenList.map(async token => {
                const isBlackListed = await sdk
                  .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
                  .checkTokenBlackListed(token.address);
                return isBlackListed ? null : token;
              }),
            )
          ).filter(token => token) as TokenDetails[];

          updateTokenList(filteredSupportedTokens);
        }
      } catch (e) {
        console.error(`loadPreselectedTokens: `, e);
      }
      setIsLoadingTokens(false);
    };

    const updateSupportedChains = async () => {
      try {
        if (sdk) {
          setLoadingSupportedChains(true);
          const supportedChainsIds = await sdk.getProviderSupportedChains();
          const supportedChains = networkOptions.filter((network: Network) => {
            return supportedChainsIds.includes(network.chainTargetId);
          });

          setSupportedChains(supportedChains);
          setLoadingSupportedChains(false);
        }
      } catch (e) {
        console.error(`updateSupportedChains`, e);
      }
    };
    try {
      updateSupportedChains();
      loadPreselectedTokens();
    } catch (error) {
      attemptCount++;
      console.error(`loadPreselectedTokens attempt:${attemptCount}}`, error);
      if (attemptCount < maxAttempts) {
        loadPreselectedTokens();
      }
    }

    return () => {
      setIsLoadingTokens(false);
      updateTokenList([]);
    };
  }, [
    account,
    currentNetwork.chainIcon,
    currentNetwork.chainId,
    currentNetwork.nativeCurrency.decimals,
    currentNetwork.nativeCurrency.name,
    currentNetwork.nativeCurrency.symbol,
    networkOptions,
    sdk,
    type,
    updateTokenList,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadBridgeTransactionTokenNativeChainNetwork = async () => {
      if (sdk && bridgeTransaction?.token?.address) {
        const nativeToken = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .getNativeTokenByWrappedAddress(bridgeTransaction.token.address);
        if (nativeToken?.chainId !== 0) {
          const nativeChainNetwork: Network = networkOptions.filter(
            network => network.chainTargetId === nativeToken.chainId,
          )[0];
          setNativeChain(nativeChainNetwork);
        } else {
          setNativeChain(bridgeTransaction?.network?.source);
        }
      }
    };

    const clearNativeChainIfNotSelectedToken = () => {
      if (!bridgeTransaction?.token?.address) {
        setNativeChain({} as Network);
      }
    };

    try {
      loadBridgeTransactionTokenNativeChainNetwork();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadBridgeTransactionTokenNativeChainNetwork();
      }
    }
    clearNativeChainIfNotSelectedToken();
  }, [bridgeTransaction?.network?.source, bridgeTransaction.token.address, networkOptions, sdk]);

  useEffect(() => {
    const getFee = async () => {
      const amount = toWei(
        bridgeTransaction?.token?.amount,
        bridgeTransaction?.token?.details?.decimals,
      );
      if (
        sdk &&
        bridgeTransaction.network.target.chainTargetId &&
        bridgeTransaction.token.address &&
        amount &&
        amount.gt(0)
      ) {
        try {
          setLoadingProviderFee(true);
          const ccipFee = await sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).getProviderFee({
            targetChainId: bridgeTransaction.network.target.chainTargetId,
            tokenAddress: bridgeTransaction.token.address,
            amount: amount,
            receiverAddress: hexlifyAddress(
              bridgeTransaction.recipient,
              bridgeTransaction.network.target.walletType,
            ),
          });

          const formattedFee = fromWei(ccipFee, currentNetwork.nativeCurrency.decimals);

          setProviderFee(Number(formattedFee));
        } catch (error) {
          console.error('Error getting provider fee', error);
          setProviderFee(0);
        }
      } else {
        setProviderFee(0);
      }
      setLoadingProviderFee(false);
    };

    getFee();
  }, [
    bridgeTransaction.network.target.chainTargetId,
    bridgeTransaction.network.target.walletType,
    bridgeTransaction.recipient,
    bridgeTransaction.token.address,
    bridgeTransaction.token?.amount,
    bridgeTransaction.token?.details,
    bridgeTransaction.token?.details?.decimals,
    currentNetwork.nativeCurrency.decimals,
    sdk,
    setLoadingProviderFee,
  ]);

  return (
    <div className={`step-details${activeStep !== 0 ? ' mb-5' : ''}`}>
      <Step
        activeStep={activeStep}
        stepNumber={0}
        title={t('transferPage:stepDetails.title')}
        subtitle={t('transferPage:stepDetails.subtitle')}
      >
        <StepPanel>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between flex-one">
              <div className="width-100">
                <div className="d-flex align-items-center">
                  <div className="text-small text-bold text-secondary mb-3 mr-3">
                    {t('transferPage:stepDetails.networkField.sourceNetwork.title')}
                  </div>
                  <InfoTooltip
                    text={t('transferPage:stepDetails.networkField.sourceNetwork.tooltip')}
                    size={18}
                  />
                </div>
                <Select
                  optionsList={[currentNetwork]}
                  displayFields={{
                    primary: 'chainName',
                    secondary: '',
                    icon: 'chainIcon',
                  }}
                  size="sm"
                  selected={0}
                  disabled={true}
                />
              </div>
              <div className="d-flex justify-content-center align-items-lg-end mt-6 mb-4 mt-lg-0 mb-lg-3 mx-lg-5">
                <Icon
                  className="rotate-90 rotate-lg-0"
                  name="bridge-token-transfer"
                  size={24}
                  color="ui-main"
                />
              </div>
              <div className="width-100 z-index-7">
                <div className="d-flex align-items-center">
                  <div className="text-small text-bold text-secondary mb-3 mr-3">
                    {t('transferPage:stepDetails.networkField.targetNetwork.title')}
                  </div>
                  <InfoTooltip
                    text={t('transferPage:stepDetails.networkField.targetNetwork.tooltip')}
                    size={18}
                  />
                </div>
                {loadingSupportedChains ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '45px',
                    }}
                  >
                    <Icon name="spinner" size={24} />
                  </div>
                ) : (
                  <Select
                    className="target-network-dropdown-list"
                    optionsList={supportedChains}
                    displayFields={{
                      primary: 'chainName',
                      secondary: '',
                      icon: 'chainIcon',
                    }}
                    size="sm"
                    selected={targetNetworkIndex}
                    placeholder={{
                      text: t(
                        'transferPage:stepDetails.networkField.targetNetwork.placeholderText',
                      ),
                      icon: 'network',
                      reset: wasTransactionCleared,
                      active:
                        bridgeTransaction?.network?.source?.chainId === currentNetwork?.chainId,
                    }}
                    onSelectOptionChange={onChangeTargetNetwork}
                  />
                )}
              </div>
            </div>
            {isEnabled('autoclaim') && (
              <div className="d-flex flex-column align-items-center ml-6 mb-2">
                <div className="d-flex align-items-center">
                  <div className="text-small text-bold text-secondary mb-3 mr-3">
                    {t('transferPage:stepDetails.networkField.autoclaim.title')}
                  </div>
                  <InfoTooltip
                    text={t('transferPage:stepDetails.networkField.autoclaim.tooltip')}
                    size={18}
                  />
                </div>
                <Switch onChange={updateIsAutoClaimActive} checked={isAutoClaimActive}></Switch>
              </div>
            )}
          </div>
        </StepPanel>
        <StepPanel>
          <div className="d-flex flex-column mb-4">
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('transferPage:stepDetails.tokenSelectField.title')}
              </div>
              <InfoTooltip
                text={t('transferPage:stepDetails.tokenSelectField.tooltip')}
                size={18}
              />
            </div>
            <TokenSelect
              className="z-index-6"
              tokenList={tokenList}
              tokenSelected={selectedToken}
              onChange={onChangeSelectToken}
              onTokenSelectedClick={handleClearTokenSelected}
              onClear={handleClearTokenSelected}
              loading={isLoadingTokens}
            />
          </div>

          <>
            {!isTokenAllowedToBridge && (
              <Notification
                className="mb-4"
                type="danger"
                text={t('transferPage:notificationMessages.tokenNotAllowedToBridge')}
                closable={false}
              />
            )}
          </>
          <div className={`fade-in-400 ${nativeChain?.chainId ? 'd-block' : 'd-none'}`}>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('transferPage:stepDetails.inputSliderField.title')}
              </div>
              <InfoTooltip
                text={t('transferPage:stepDetails.inputSliderField.tooltip')}
                size={18}
              />
            </div>
            <Select
              optionsList={[nativeChain]}
              displayFields={{
                primary: 'chainName',
                secondary: '',
                icon: 'chainIcon',
              }}
              size="sm"
              selected={0}
              disabled={true}
              placeholder={{
                text: 'Native Chain',
                icon: 'network',
                reset: wasTransactionCleared,
                active: !bridgeTransaction?.token?.address,
              }}
            />
          </div>
          <div className="mt-7">
            <TokenInput
              balance={tokenSelectedBalance}
              icon={`${location?.origin}/icons/${
                bridgeTransaction?.token?.details?.icon
                  ? bridgeTransaction.token.details.icon
                  : 'blank'
              }.svg`}
              token={bridgeTransaction?.token?.details?.symbol}
              label={
                bridgeTransaction?.token?.amount
                  ? t('transferPage:stepDetails.inputSliderField.labelAmount')
                  : ''
              }
              value={tokenInputAmount}
              onChange={handleTokenInputAmount}
              disabled={!(Number(tokenSelectedBalance) > 0) || !isTokenAllowedToBridge}
              error={
                !!selectedToken?.name && Number(tokenSelectedBalance) === 0
                  ? t('transferPage:stepDetails.inputSliderField.noBalance', {
                      tokenSymbol: selectedToken?.symbol,
                    })
                  : ''
              }
            />
            <Slider
              color="primary"
              step={1 / 10 ** TOKEN_SLIDER_PERCENTAGE_DECIMALS}
              value={sliderPercentage}
              onChange={handleSliderPercentage}
              disabled={!(Number(tokenSelectedBalance) > 0) || !isTokenAllowedToBridge}
            />
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('transferPage:stepDetails.recipientField.title')}
              </div>
              <InfoTooltip text={t('transferPage:stepDetails.recipientField.tooltip')} size={18} />
            </div>
            <TextField
              icon={'wallet'}
              label={
                bridgeTransaction?.recipient
                  ? ''
                  : t('transferPage:stepDetails.recipientField.title')
              }
              value={bridgeTransaction?.recipient ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeRecipient(e.target.value)
              }
            />
            {showInvalidAddressText && (
              <p className="text-danger">
                {t('transferPage:stepDetails.recipientField.invalidAddressText')}
              </p>
            )}
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t('transferPage:stepDetails.transactionFeesFieldStep.title')}
              </div>
              <InfoTooltip
                text={t('transferPage:stepDetails.transactionFeesFieldStep.tooltip')}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center mb-3 mb-sm-0">
                <Icon name="pass-through" size={18} color="ui-main" className="mr-2" />
                <span className="text-main">
                  {t('transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesText')}
                </span>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-3"></div>
              <div className="mb-5 mb-sm-0">
                {serviceFeeOptions.length > 0 ? (
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center width-100 width-sm-auto">
                    <span className="text-main text-bold text-center my-2 my-sm-0 mr-4">
                      {bridgeTransaction?.feeToken?.amount}
                    </span>
                    <Select
                      optionsList={serviceFeeOptions}
                      displayFields={{
                        primary: 'symbol',
                        secondary: 'amountInCurrency',
                        icon: 'icon',
                      }}
                      size="sm"
                      selected={serviceFeeTokenIndex}
                      onSelectOptionChange={onSelectedFeeTokenChange}
                    />
                  </div>
                ) : (
                  <div className="pulse d-flex justify-content-center justify-content-sm-start align-items-center mt-3 mt-sm-0">
                    <Icon name="spinner" size={18} className="mr-3" />
                    <span>
                      {t('transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesLoadingText')}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                <div>
                  <span className="text-main">
                    {t('transferPage:stepDetails.transactionFeesFieldStep.estimationFeesText')}
                  </span>
                  {bridgeTransaction?.network?.target?.chainId &&
                    bridgeTransaction?.token?.address &&
                    !['0', '0.0']?.includes(bridgeTransaction?.token?.amount) &&
                    bridgeTransaction?.recipient && (
                      <span className="text-main text-bold ml-2">
                        {!isServiceTokenApproved
                          ? `[${t('transferPage:approveButton', {
                              tokenSymbol: bridgeTransaction?.feeToken?.details?.symbol,
                            })}]`
                          : !isBridgeTokenApproved &&
                            bridgeTransaction?.feeToken?.address !==
                              bridgeTransaction?.token?.address
                          ? `[${t('transferPage:approveButton', {
                              tokenSymbol: bridgeTransaction?.token?.details?.symbol,
                            })}]`
                          : `[${t('transferPage:transferButton')}]`}
                      </span>
                    )}
                </div>
              </div>

              <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
              <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
                <span className="text-main text-bold mr-4">{txFeeEstimation}</span>
                <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
                <div className="d-flex flex-column flex-md-row">
                  <div className="d-flex">
                    <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">{txFeeEstimationCurrency}</span>
                </div>
              </div>
            </div>
            {isAutoClaimActive && (
              <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
                <div className="d-flex align-items-center">
                  <Icon name="bridge-auto-claim" size={16} color="ui-main" className="mr-2" />
                  <span className="text-main">
                    {t('transferPage:stepDetails.transactionFeesFieldStep.autoclaimFeesText')}
                  </span>
                </div>
                <div className="d-none d-sm-flex line flex-one mx-3"></div>
                {bridgeTransaction?.network?.source?.chainTargetId &&
                bridgeTransaction?.network?.target?.chainTargetId &&
                bridgeTransaction?.token?.address &&
                Number(bridgeTransaction?.token?.amount) > 0 &&
                account &&
                bridgeTransaction?.recipient &&
                Number(autoClaimEstimation.fee) === 0 ? (
                  <div className="pulse d-flex justify-content-center justify-content-sm-start align-items-center mt-3 mt-sm-0">
                    <Icon name="spinner" size={18} className="mr-3" />
                    <span>
                      {t(
                        'transferPage:stepDetails.transactionFeesFieldStep.autoclaimFeesLoadingText',
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <span className="text-main text-bold mr-2 mr-sm-4">
                      {Number(autoClaimEstimation.fee) > 0 ? autoClaimEstimation.fee : '0.0'}
                    </span>
                    <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                    <div className="d-flex flex-column flex-sm-row">
                      <div className="d-flex mt-3 mt-md-0">
                        <Icon
                          name={
                            bridgeTransaction?.network?.source?.chainIcon
                              ? bridgeTransaction?.network?.source?.chainIcon
                              : 'blank'
                          }
                          size={18}
                          className="mr-2"
                        />
                        <span className="text-uppercase text-secondary">
                          {bridgeTransaction?.network?.source?.nativeCurrency?.symbol}
                        </span>
                      </div>
                      <span className="text-secondary ml-2">
                        {!['($ 0.00)'].includes(autoClaimEstimation.feeInCurrency)
                          ? autoClaimEstimation.feeInCurrency
                          : '($ 0.00)'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {loadingProviderFee ? (
              <div className="pulse d-flex justify-content-center justify-content-sm-start align-items-center mt-3 mt-sm-0">
                <Icon name="spinner" size={18} className="mr-3" />
                <span>
                  {t('transferPage:stepDetails.transactionFeesFieldStep.providerFeesLoadingText')}
                </span>
              </div>
            ) : (
              providerFee.toString() !== '0' && (
                <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
                  <div className="d-flex align-items-center">
                    <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
                    {/* TODO: this should be dynamic based on the selected provider */}
                    <span className="text-main">CCIP provider fee</span>
                  </div>
                  <div className="d-none d-sm-flex line flex-one mx-3"></div>
                  <div className="d-flex align-items-center">
                    <span className="text-main text-bold mr-2 mr-sm-4">
                      {providerFee.toString()}
                    </span>
                    <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                    <div className="d-flex flex-column flex-sm-row">
                      <div className="d-flex mt-3 mt-md-0">
                        <Icon
                          name={
                            bridgeTransaction?.network?.source?.chainIcon
                              ? bridgeTransaction?.network?.source?.chainIcon
                              : 'blank'
                          }
                          size={18}
                          className="mr-2"
                        />
                        <span className="text-uppercase text-secondary">
                          {bridgeTransaction?.network?.source?.nativeCurrency?.symbol}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </StepPanel>
        <div>
          <StepPanel>
            <StepBridgeNotifications
              notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
              isBridgeTransactionTokenNativeToken={isBridgeTransactionTokenNativeToken}
              isTokenEWTB={isTokenEWTB}
              autoClaimHasFunds={autoClaimHasFunds}
              currentAllowance={currentAllowance}
              isTokenNonApprovable={isTokenNonApprovable}
              tokenSelectedAmountInWei={tokenSelectedAmountInWei}
            />
            <div className="d-flex mx-2 mt-5 mb-6">
              <Icon name="info-circle" size={20} color="ui-main" className="mr-4" />
              <div>
                <p className="text-bold">{t('transferPage:attentionField.title')}</p>
                <p className="text-align-justify">
                  <Trans
                    i18nKey="transferPage:attentionField.subtitle"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </div>
          </StepPanel>
        </div>
      </Step>
    </div>
  );
};

export default StepBridgeDetails;
