import React from 'react';

import './base.scss';

interface SkeletonBlockProps {
  size: 1 | 2 | 3 | 4;
  className?: string;
  type?: 'small' | 'medium' | 'large';
}

const SkeletonBlock = ({ size, className, type }: SkeletonBlockProps) => {
  const BASE_WIDTH = 25;
  const HEIGHT = {
    small: 15,
    medium: 18,
    large: 21,
  };

  return (
    <div
      className={`skeleton ${className ?? ''}`}
      style={{
        width: `${size * BASE_WIDTH}%`,
        height: `${HEIGHT[type ?? 'medium']}px`,
      }}
    ></div>
  );
};

export default SkeletonBlock;
