import { env } from 'environments';

import { config as developConfig } from './develop/networks';
import { config as prodConfig } from './prod/networks';
import { config as stagingConfig } from './staging/networks';

export const NETWORK_CONFIG: NetworkConfig = env.IS_PRODUCTION
  ? prodConfig
  : env.IS_STAGING
  ? stagingConfig
  : developConfig;
