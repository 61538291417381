import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Indicator } from 'components/Indicator';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useGlobalContext } from 'hooks/useGlobalContext';

function NftClaimedCounter() {
  const { nftBridgeStatuses, nftClaims } = useGlobalContext();
  const { t } = useTranslation();
  const { lessThan } = useBreakpoint();
  const [claimButtonHeader, setClaimButtonHeader] = useState<string>(
    t('nftClaimPage:counter.toBeClaimed'),
  );

  const { filterCriteria } = useGlobalContext();
  useEffect(() => {
    if (filterCriteria.status !== t('nftClaimPage:filterOptions.constants.showAll')) {
      setClaimButtonHeader(filterCriteria.status);
    } else {
      setClaimButtonHeader(t('nftClaimPage:counter.toBeClaimed'));
    }
  }, [filterCriteria.status, t]);

  const activeNftClaims = nftBridgeStatuses.bridge.activeNftClaims;
  const inActiveNftClaims = nftBridgeStatuses.bridge.inActiveNftClaims;

  return (
    <div className="d-flex align-items-center">
      <Indicator
        type="primary"
        title={claimButtonHeader}
        value={
          claimButtonHeader === t('claimPage:filterOptions.constants.claimed')
            ? activeNftClaims
            : inActiveNftClaims
        }
        className="mr-2 mr-sm-0 mb-sm-2"
      />
      {lessThan('sm') && (
        <Indicator
          type="secondary"
          title={t('claimPage:counter.total')}
          value={nftClaims.length}
          className="ml-2 ml-sm-0 mt-sm-2"
        />
      )}
    </div>
  );
}

export default NftClaimedCounter;
