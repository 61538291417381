import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Notification } from '@stichting-allianceblock-foundation/components';
import { ethers } from 'ethers';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useTargetToken } from 'hooks/useTargetToken';

interface StepBridgeNotificationsProps {
  notEnoughBalanceForServiceFee: boolean;
  isBridgeTransactionTokenNativeToken: boolean;
  isTokenEWTB: boolean;
  autoClaimHasFunds: boolean | null;
  currentAllowance: ethers.BigNumber | undefined;
  isTokenNonApprovable: boolean;
  tokenSelectedAmountInWei: ethers.BigNumber | undefined;
}

export const StepBridgeNotifications = ({
  notEnoughBalanceForServiceFee,
  isBridgeTransactionTokenNativeToken,
  isTokenEWTB,
  autoClaimHasFunds,
  currentAllowance,
  isTokenNonApprovable,
  tokenSelectedAmountInWei,
}: StepBridgeNotificationsProps) => {
  const { bridgeTransaction } = useGlobalContext();
  const { t } = useTranslation();

  const { deployment } = useTargetToken();

  return (
    <div className="mt-4">
      {bridgeTransaction?.token?.address &&
        !['0', '0.0'].includes(bridgeTransaction?.token?.amount) &&
        notEnoughBalanceForServiceFee && (
          <Notification
            className="mb-4"
            type="danger"
            text={
              <p>
                <Trans
                  i18nKey="transferPage:notificationMessages.serviceFeeNotEnoughBalanceText"
                  components={{ span: <span /> }}
                  values={{
                    tokenSymbol: bridgeTransaction.feeToken.details.symbol,
                  }}
                />
              </p>
            }
            closable={false}
          />
        )}
      {!isTokenNonApprovable &&
        currentAllowance?.gt(0) &&
        currentAllowance?.lt(ethers.BigNumber.from(tokenSelectedAmountInWei)) && (
          <Notification
            className="mb-4"
            type="info"
            text={
              <p>
                <Trans
                  i18nKey="transferPage:notificationMessages.allowanceReSetText"
                  components={{ span: <span /> }}
                />
              </p>
            }
            closable={false}
          />
        )}
      {isBridgeTransactionTokenNativeToken && (
        <Notification
          className="mb-4"
          type="warning"
          text={t('transferPage:notificationMessages.isBridgeTokenNativeTokenText', {
            tokenName: bridgeTransaction?.token?.details?.name,
            tokenSymbol: bridgeTransaction?.token?.details?.symbol,
          })}
          closable={false}
        />
      )}
      {autoClaimHasFunds === false && (
        <Notification
          className="mb-4"
          type="danger"
          text={t('transferPage:notificationMessages.autoClaimHasNotFunds')}
          closable={false}
        />
      )}
      {isTokenEWTB ? (
        <Notification
          className="mb-4"
          type="danger"
          text={
            <div className="d-flex flex-column">
              <p>
                <Trans
                  i18nKey="transferPage:notificationMessages.isEWTBText"
                  components={{ span: <span /> }}
                  values={{
                    tokenSymbol: bridgeTransaction.token.details.symbol,
                  }}
                />
              </p>
              <a
                className="link-primary"
                href="https://bridge.energyweb.org/"
                rel="noopener noreferrer"
              >
                POA Bridge UI
              </a>
            </div>
          }
          closable={false}
        />
      ) : (
        deployment !== null && (
          <Notification
            className="mb-4"
            type={deployment ? 'success' : 'warning'}
            text={
              deployment
                ? t('transferPage:notificationMessages.deployedWrappedTokenText')
                : deployment === false
                ? t('transferPage:notificationMessages.notDeployedWrappedTokenText')
                : t('transferPage:notificationMessages.undefinedDeployedWrappedTokenText')
            }
            closable={false}
          />
        )
      )}
    </div>
  );
};

export default StepBridgeNotifications;
