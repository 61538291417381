import { useEffect, useState } from 'react';

import { NETWORK_CONFIG } from 'configs';

import { useFeatureFlag } from './useFeaturesFlags';

export const useSupportedNetworks = () => {
  const { loading, isEnabled } = useFeatureFlag();

  const [supportedNetworks, setSupportedNetworks] = useState<NetworkConfig>({});

  useEffect(() => {
    if (loading) return;
    const supportedNetworks: NetworkConfig = {};

    for (const networkKey of Object.keys(NETWORK_CONFIG)) {
      if (isEnabled(networkKey + '_chain')) {
        supportedNetworks[networkKey] = NETWORK_CONFIG[networkKey];
      }
    }

    setSupportedNetworks(supportedNetworks);
  }, [loading, isEnabled]);

  return { loading, supportedNetworks };
};
